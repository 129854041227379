
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

const useStyles = makeStyles({
  slider: {
    position: 'absolute',
    borderRadius: '1em',
    width: '4em',
    height: '2em',
  },
  sliderOn: {
    backgroundColor: 'green',
    '&::before': {
      transition: 'all .3s ease-in-out',
      backgroundColor: 'white',
      display: 'block',
      borderRadius: '50%',
      position: 'absolute',
      margin: 'auto',
      height: '1.5em',
      left:'0.5em',
      width: '1.5em',
      content: '""',
      top: '0.5em',
      bottom: '0.5em',
    },
  },

  sliderOff: {
    backgroundColor: 'red',
    '&::before': {
      transition: 'all .3s ease-in-out',
      backgroundColor: 'white',
      display: 'block',
      borderRadius: '50%',
      position: 'absolute',
      margin: 'auto',
      height: '1.5em',
      width: '1.5em',
      content: '""',
      top: '0.5em',
      right:'0.5em',
      bottom: '0.5em',
    },
  },
  toggleWrapper: {
    position: 'absolute',
    padding: '2px',
    right: '5em',
    top: '1em',
    margin: 'auto',
  }
})


interface IToggle {
  onToggle: (boolean) => void
}
export const Toggle = ({onToggle}: IToggle) => {
  const [toggled, setToggled] = useState(false)
  const classes = useStyles();

  useEffect(() => {
    onToggle(toggled)
  }, [toggled])

  const handleChange = () => {
    setToggled(toggled => !toggled)
  }

  return <div
    onClick={handleChange}
    className={classes.toggleWrapper}
  >
    <div
      className={clsx(classes.slider, toggled? classes.sliderOn : classes.sliderOff)}
      style={{ backgroundColor: toggled ? 'red' : 'green' }}
    >
    </div>
  </div>
}
