import { PlatformClient, ResponseStream } from "../API/platform_pb_service";
import {
    GetStateRequest,
    GetTasksRequest,
    State,
    Task,
    ValidFlagSubmission,
    Profile,
    GetProfilesRequest,
    KothHistoryTick,
    GetProfilesResponse,
    GetNotificationsRequest,
    Notification,
    ScoreboardBatch,
} from "../API/platform_pb";
import * as React from "react";
import { Empty } from "google-protobuf/google/protobuf/empty_pb";

import { grpc } from "@improbable-eng/grpc-web";

let url = "https://api.cscg.live:444"

interface IAPIClient {
    children: React.ReactNode;
}

let retryCounter = 0
function retryTimeout(): number {
    retryCounter++;
    return retryCounter <= 10 ? 15000 : 45000
}

function apiFetchStream<T>(endpoint: string, fetchFunc: () => ResponseStream<T>, callback: (resp: T) => void) {
    let retryTimer = null
    let stream: ResponseStream<T> = null
    let doit = () => {
        if (retryTimer) { clearTimeout(retryTimer); retryTimer = null }
        if (stream)
            stream.cancel();
        stream = fetchFunc()
        stream.on('data', callback)
        stream.on('status', status => {
            console.log(endpoint + ' status:', status)
        })
        stream.on('end', status => {
            console.log(endpoint + ' ended:', status)
            stream = null
            if (status.code !== grpc.Code.PermissionDenied)
                retryTimer = setTimeout(doit, retryTimeout())
        })
    }
    doit()
    return () => {
        if (stream)
            stream.cancel();
    }
}

function apiFetchList<T>(endpoint: string, fetchFunc: () => ResponseStream<T>, callback: (resp: (list: Array<T>) => Array<T>) => void) {
    let retryTimer = null
    let stream: ResponseStream<T> = null
    let doit = () => {
        callback((oldVal: Array<T>) => {
            // make sure to preserve object identity in case the state was empty already
            if (oldVal.length) {
                return []
            } else {
                return oldVal
            }
        })

        if (retryTimer) { clearTimeout(retryTimer); retryTimer = null }
        if (stream)
            stream.cancel();
        stream = fetchFunc()
        stream.on('data', (resp) => callback(list => [...list, resp]))
        stream.on('status', status => {
            console.log(endpoint + ' status:', status)
        })
        stream.on('end', status => {
            console.log(endpoint + ' ended:', status)
            stream = null
            retryTimer = setTimeout(doit, retryTimeout())
        })
    }
    doit()
    return () => {
        if (stream)
            stream.cancel();
    }
}

const APIState = React.createContext<{
    tasks: Array<Task>
    platform: PlatformClient
    state: State
    profiles: Map<string, Profile>
    submissions: Array<ValidFlagSubmission>
    scoreboard: ScoreboardBatch | null
    kothTransactions: Array<KothHistoryTick>
    hasProfilesBeenQueried: boolean
    notifications: Array<Notification>
}>(null);

const APIClient: React.FC<IAPIClient> = (props) => {
    const [tasks, setTasks] = React.useState([]);
    const [platformState, setPlatformState] = React.useState<State>(null);
    const [platform, setPlatform] = React.useState(null);
    const [submissions, setSubmissions] = React.useState([]);
    const [scoreboard, setScoreboard] = React.useState(null);
    const [profiles, setProfiles] = React.useState(new Map<string, Profile>());
    const [hasProfilesBeenQueried, setHasProfilesBeenQueried] = React.useState(false);
    const [kothTransactions, setKothTransactions] = React.useState([]);
    const [notifications, setNotifications] = React.useState([]);

    React.useEffect(() => {
        console.log("API:", url)
        const meta = new grpc.Metadata({  });
        const platform = new PlatformClient(url, {
            debug: true,
            transport: undefined,
        })

        setPlatform(platform)

        const defer: Array<Function> = []

        const taskRequest = new GetTasksRequest()
        taskRequest.setStreaming(true)
        defer.push(apiFetchStream("tasksStream", () => platform.getTasks(taskRequest, meta), data => setTasks(data.getTasksList())))

        const stateRequest = new GetStateRequest()
        stateRequest.setStreaming(true)
        defer.push(apiFetchStream("getState", () => platform.getState(stateRequest, meta), setPlatformState))

        defer.push(apiFetchStream("getFlagSubmissionsBatched", () => platform.getFlagSubmissionsBatched(new Empty(), meta), data => {
            setSubmissions(list => [...list, ...data.getSubmissionsList()])
        }))

        defer.push(apiFetchStream("getScoreboardHistory", () => platform.getScoreboardHistory(new Empty(), meta), data => {
            setScoreboard((scoreboard: ScoreboardBatch | null) => {
                const prevTicks = (data.getClear() || scoreboard === null) ? [] : scoreboard.getTicksList()
                data.setTicksList([...prevTicks, ...data.getTicksList()])
                return data
            })
        }))
        // defer.push(apiFetchList("getKothHistory", () => platform.getKothHistory(new Empty(), meta), setKothTransactions))


        const profilesRequest = new GetProfilesRequest()
        profilesRequest.setStreaming(true)
        defer.push(apiFetchStream("getProfiles", () => platform.getProfiles(profilesRequest, meta), (response: GetProfilesResponse) => {
            const newProfiles = new Map<string, Profile>()
            for (const p of response.getProfilesList()) {
                p.setCountry("DE")
                newProfiles.set(p.getId(), p)
            }
            setProfiles(newProfiles)
            setHasProfilesBeenQueried(true)
        }))

        const notificationRequest = new GetNotificationsRequest()
        notificationRequest.setStreaming(true)
        defer.push(apiFetchStream("getNotifications", () => platform.getNotifications(notificationRequest, meta), response => setNotifications(response.getNotificationsList())))

        return () => {
            defer.forEach(value => {
                if (value)
                    value()
            })
        };
    }, [url]);

    return (
        <React.Fragment>
            <APIState.Provider value={{
                tasks,
                platform,
                state: platformState,
                profiles,
                hasProfilesBeenQueried,
                submissions,
                scoreboard,
                kothTransactions,
                notifications
            }}>
                {props.children}
            </APIState.Provider>
        </React.Fragment>
    )
}

export { APIClient, APIState }
