import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";
import { Scoreboard } from "./Scoreboard";
import { Tasks } from "./Tasks";
import { IoMdPeople } from "react-icons/io";
import { BsListTask } from "react-icons/bs";
import { GiVote } from "react-icons/gi";
import clsx from "clsx";
import { Stats } from "./Stats";

const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    overflow: "hidden",
    position: "relative",
  },
  page: {
    position: "absolute",
    width: "calc( 100% - 100px )",
    height: "100%",
  },
  page0: {
    backgroundColor: "red",
    left: "calc( -100% + 50px )",
  },
  page1: {
    left: "calc( 0% + 50px )",
  },
  page2: {
    backgroundColor: "white",
    left: "calc( 100% + 50px ) ",
  },
  btn: {
    width: "50px",
    height: "100%",
    position: "fixed",
    zIndex: 100,
    "& > *": {
      width: "100%",
      height: "100%",
    },
    "&:hover": {
      cursor: "pointer",
    },
  },
  searchBtn: {
    backgroundColor: "orange",
    top: "0px",
    left: "0px",
  },
  settingsBtn: {
    backgroundColor: "green",
    top: "0px",
    right: "0px",
  },
  pages: {
    width: "100%",
    height: "100%",
    transition: "transform 1s",
  },
});

export const Dashboard = () => {
  const [pageId, setPageId] = useState<number>(1);
  const classes = useStyles();

  const toggleTasks = () => {
    if (pageId !== 0) {
      setPageId(0);
    } else {
      setPageId(1);
    }
  };

  const toggleAuthors = () => {
    if (pageId !== 2) {
      setPageId(2);
    } else {
      setPageId(1);
    }
  };

  return (
    <div className={classes.root}>
      <div
        className={clsx(classes.btn, classes.searchBtn)}
        onClick={toggleTasks}
      >
        {pageId === 0 ? <IoMdPeople /> : <BsListTask />}
      </div>
      <div
        className={clsx(classes.btn, classes.settingsBtn)}
        onClick={toggleAuthors}
      >
        {pageId === 2 ? <IoMdPeople /> : <GiVote />}
      </div>
      <div
        className={classes.pages}
        style={{
          transform: `translateX(${(pageId - 1) * -100}%)`,
        }}
      >
        <div className={clsx(classes.page, classes.page0)}>
          <Tasks />
        </div>
        <div className={clsx(classes.page, classes.page1)}>
          <Scoreboard />
        </div>
        <div className={clsx(classes.page, classes.page2)}>
          <Stats />
        </div>
      </div>
    </div>
  );
};
