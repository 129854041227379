import {useContext, useEffect, useState} from "react";
import {APIState} from "../State/API";
import {ResponsiveTimeRange, TimeRangeDatum} from "@cloudbees/nivo-calendar"
import {ticks} from "d3-array";

const Calendar = () => {
    const api = useContext(APIState)

    const [filterOptions, setFilterOptions] = useState(['all'])
    const [filter, setFilter] = useState('all')
    const [maxData, setMaxData] = useState(500)
    const [data, setData] = useState<TimeRangeDatum[]>([{
        day: new Date(Date.now()).toLocaleDateString(),
        date: new Date(Date.now()),
        value: 1337
    }])

    useEffect(() => {
        if (api.tasks.length > 0) {
            setFilterOptions(['all', ...api.tasks.map(task => task.getName())])
        }
    }, [api.tasks])


    useEffect(() => {
        if (api.state && api.submissions && api.state.getContest()) {
            let dataMap = new Map<string, number>()
            let days = (api.state.getContest().getConfig().getEndtime().toDate().getTime() - api.state.getContest().getConfig().getStarttime().toDate().getTime()) / (1000 * 60 * 60 * 24)
            for (let i = 0; i <= days; i++) {
                let date = new Date(api.state?.getContest()?.getConfig().getStarttime().toDate().getTime() + (1000 * 60 * 60 * 24 * i))
                dataMap.set(date.toLocaleDateString(), 0)
            }

            api.submissions
                .filter(submission => filter === 'all' || submission.getTask() === filter)
                .forEach(submission => {
                    let date = submission.getTimestamp().toDate().toLocaleDateString()
                    dataMap.set(date, dataMap.get(date) + 1)
                })

            let data = Array.from(dataMap.entries()).map(([date, value], i) => ({
                day: `Solves on ${date}`,
                date: new Date(api.state.getContest().getConfig().getStarttime().toDate().getTime() + 1000 * 60 * 60 * 24 * i),
                value: value,
            }))

            setMaxData(Math.max(...data.map(x => x.value)))

            setData(data)
        }
    }, [api.submissions, api.state, filter])


    const scale = (x) => {
        if (x === 0) {
            return ["lightgrey"]
        }

        let m = maxData - 1
        x = x - 1
        x = (-Math.pow(5, -m / 100 * (x / m)) + 1) / (-Math.pow(5, -m / 100) + 1)

        return [`hsl(${120 - (x * 120)}, 100%, 50%)`]
    }

    scale.ticks = (count) => {
        return ticks(0, maxData, maxData + 1)
    }

    return <>
        <div style={{height: "calc( 100% - 40px )"}}>
            <ResponsiveTimeRange
                data={data}
                emptyColor="#ffffff"
                margin={{top: 40, right: 40, bottom: 40, left: 40}}
                dayBorderWidth={2}
                dayBorderColor="#ffffff"
                colors={[]}
                colorScale={scale}
                legends=
                    {
                        [
                            {
                                anchor: 'bottom-right',
                                direction: 'row',
                                translateY: 36,
                                itemCount: 4,
                                itemWidth: 42,
                                itemHeight: 36,
                                itemsSpacing: 14,
                                itemDirection: 'right-to-left'
                            }
                        ]
                    }
            />
        </div>
        <div style={{height: '40px'}}>
            <select
                value={filter}
                onChange={event => setFilter(event.target.value)}
            >
                {
                    filterOptions.map((t) => {
                        let name = 'All'
                        if (t !== 'all') {
                            name = api.tasks.find(task => task.getName() === t).getDisplayname()
                        }
                        return <option value={t}>{name
                        }</option>
                    })
                }
            </select>
        </div>
    </>

}

export const Stats = () => {


    return <>
        <Calendar/>
    </>
}