// source: platform.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var google_api_annotations_pb = require('./google/api/annotations_pb.js');
goog.object.extend(proto, google_api_annotations_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.platform.AddBadgeRequest', null, global);
goog.exportSymbol('proto.platform.AddBadgeResponse', null, global);
goog.exportSymbol('proto.platform.AddNotificationRequest', null, global);
goog.exportSymbol('proto.platform.AddNotificationResponse', null, global);
goog.exportSymbol('proto.platform.AuthenticateRequest', null, global);
goog.exportSymbol('proto.platform.AuthenticateRequest.Type', null, global);
goog.exportSymbol('proto.platform.AuthenticateResponse', null, global);
goog.exportSymbol('proto.platform.Badge', null, global);
goog.exportSymbol('proto.platform.Bracket', null, global);
goog.exportSymbol('proto.platform.BrokerSession', null, global);
goog.exportSymbol('proto.platform.Contest', null, global);
goog.exportSymbol('proto.platform.ContestConfiguration', null, global);
goog.exportSymbol('proto.platform.ContestState', null, global);
goog.exportSymbol('proto.platform.CreateProfileRequest', null, global);
goog.exportSymbol('proto.platform.CreateSessionRequest', null, global);
goog.exportSymbol('proto.platform.FetchWriteupRequest', null, global);
goog.exportSymbol('proto.platform.FetchWriteupResponse', null, global);
goog.exportSymbol('proto.platform.GetBadgesRequest', null, global);
goog.exportSymbol('proto.platform.GetBadgesResponse', null, global);
goog.exportSymbol('proto.platform.GetDifficultyRequest', null, global);
goog.exportSymbol('proto.platform.GetDifficultyResponse', null, global);
goog.exportSymbol('proto.platform.GetMySessionsRequest', null, global);
goog.exportSymbol('proto.platform.GetMySessionsResponse', null, global);
goog.exportSymbol('proto.platform.GetMySessionsResponse.SessionMeta', null, global);
goog.exportSymbol('proto.platform.GetNotificationsRequest', null, global);
goog.exportSymbol('proto.platform.GetNotificationsResponse', null, global);
goog.exportSymbol('proto.platform.GetOwnProfileRequest', null, global);
goog.exportSymbol('proto.platform.GetProfilesRequest', null, global);
goog.exportSymbol('proto.platform.GetProfilesResponse', null, global);
goog.exportSymbol('proto.platform.GetQueueStatusRequest', null, global);
goog.exportSymbol('proto.platform.GetQueueStatusResponse', null, global);
goog.exportSymbol('proto.platform.GetSessionStatusRequest', null, global);
goog.exportSymbol('proto.platform.GetSessionStatusResponse', null, global);
goog.exportSymbol('proto.platform.GetSessionStatusResponse.SessionEndpoint', null, global);
goog.exportSymbol('proto.platform.GetSessionStatusResponse.SessionEndpoint.EndpointKind', null, global);
goog.exportSymbol('proto.platform.GetSessionStatusResponse.State', null, global);
goog.exportSymbol('proto.platform.GetStateRequest', null, global);
goog.exportSymbol('proto.platform.GetTasksRequest', null, global);
goog.exportSymbol('proto.platform.GetTasksResponse', null, global);
goog.exportSymbol('proto.platform.GetTransactionsRequest', null, global);
goog.exportSymbol('proto.platform.GetTransactionsResponse', null, global);
goog.exportSymbol('proto.platform.KothHistoryTick', null, global);
goog.exportSymbol('proto.platform.KothHistoryTick.KothHistoryDatapoint', null, global);
goog.exportSymbol('proto.platform.KothTickData', null, global);
goog.exportSymbol('proto.platform.LeaveQueueRequest', null, global);
goog.exportSymbol('proto.platform.LeaveQueueResponse', null, global);
goog.exportSymbol('proto.platform.Notification', null, global);
goog.exportSymbol('proto.platform.PlatformConfiguration', null, global);
goog.exportSymbol('proto.platform.Profile', null, global);
goog.exportSymbol('proto.platform.ProfileType', null, global);
goog.exportSymbol('proto.platform.QueueEntry1', null, global);
goog.exportSymbol('proto.platform.QueueState', null, global);
goog.exportSymbol('proto.platform.RequestQueuePositionRequest', null, global);
goog.exportSymbol('proto.platform.RequestQueuePositionResponse', null, global);
goog.exportSymbol('proto.platform.ScoreboardBatch', null, global);
goog.exportSymbol('proto.platform.ScoreboardSnapshot', null, global);
goog.exportSymbol('proto.platform.Severity', null, global);
goog.exportSymbol('proto.platform.Standing', null, global);
goog.exportSymbol('proto.platform.Standing.TaskStat', null, global);
goog.exportSymbol('proto.platform.State', null, global);
goog.exportSymbol('proto.platform.SubmitFlagRequest', null, global);
goog.exportSymbol('proto.platform.SubmitFlagResponse', null, global);
goog.exportSymbol('proto.platform.SubmitKothTickRequest', null, global);
goog.exportSymbol('proto.platform.SubmitKothTickResponse', null, global);
goog.exportSymbol('proto.platform.SubmitWriteupRequest', null, global);
goog.exportSymbol('proto.platform.SubmitWriteupResponse', null, global);
goog.exportSymbol('proto.platform.Task', null, global);
goog.exportSymbol('proto.platform.Task.Deployment', null, global);
goog.exportSymbol('proto.platform.Task.Deployment.Container', null, global);
goog.exportSymbol('proto.platform.Task.Deployment.IngressPort', null, global);
goog.exportSymbol('proto.platform.Task.File', null, global);
goog.exportSymbol('proto.platform.TaskState', null, global);
goog.exportSymbol('proto.platform.TaskType', null, global);
goog.exportSymbol('proto.platform.TerminateSessionRequest', null, global);
goog.exportSymbol('proto.platform.Transaction', null, global);
goog.exportSymbol('proto.platform.Transaction.Type', null, global);
goog.exportSymbol('proto.platform.TransactionAggregate', null, global);
goog.exportSymbol('proto.platform.TransactionAggregate.Writeups', null, global);
goog.exportSymbol('proto.platform.UpdateProfileRequest', null, global);
goog.exportSymbol('proto.platform.UpdateQueueStatusRequest', null, global);
goog.exportSymbol('proto.platform.UpdateQueueStatusResponse', null, global);
goog.exportSymbol('proto.platform.ValidFlagSubmission', null, global);
goog.exportSymbol('proto.platform.ValidFlagSubmissionBatch', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.platform.GetDifficultyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.platform.GetDifficultyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.platform.GetDifficultyRequest.displayName = 'proto.platform.GetDifficultyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.platform.GetDifficultyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.platform.GetDifficultyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.platform.GetDifficultyResponse.displayName = 'proto.platform.GetDifficultyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.platform.CreateSessionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.platform.CreateSessionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.platform.CreateSessionRequest.displayName = 'proto.platform.CreateSessionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.platform.BrokerSession = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.platform.BrokerSession, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.platform.BrokerSession.displayName = 'proto.platform.BrokerSession';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.platform.GetSessionStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.platform.GetSessionStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.platform.GetSessionStatusRequest.displayName = 'proto.platform.GetSessionStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.platform.TerminateSessionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.platform.TerminateSessionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.platform.TerminateSessionRequest.displayName = 'proto.platform.TerminateSessionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.platform.GetMySessionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.platform.GetMySessionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.platform.GetMySessionsRequest.displayName = 'proto.platform.GetMySessionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.platform.GetMySessionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.platform.GetMySessionsResponse.repeatedFields_, null);
};
goog.inherits(proto.platform.GetMySessionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.platform.GetMySessionsResponse.displayName = 'proto.platform.GetMySessionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.platform.GetMySessionsResponse.SessionMeta = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.platform.GetMySessionsResponse.SessionMeta, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.platform.GetMySessionsResponse.SessionMeta.displayName = 'proto.platform.GetMySessionsResponse.SessionMeta';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.platform.GetSessionStatusResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.platform.GetSessionStatusResponse.repeatedFields_, null);
};
goog.inherits(proto.platform.GetSessionStatusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.platform.GetSessionStatusResponse.displayName = 'proto.platform.GetSessionStatusResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.platform.GetSessionStatusResponse.SessionEndpoint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.platform.GetSessionStatusResponse.SessionEndpoint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.platform.GetSessionStatusResponse.SessionEndpoint.displayName = 'proto.platform.GetSessionStatusResponse.SessionEndpoint';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.platform.GetStateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.platform.GetStateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.platform.GetStateRequest.displayName = 'proto.platform.GetStateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.platform.GetProfilesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.platform.GetProfilesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.platform.GetProfilesRequest.displayName = 'proto.platform.GetProfilesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.platform.GetOwnProfileRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.platform.GetOwnProfileRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.platform.GetOwnProfileRequest.displayName = 'proto.platform.GetOwnProfileRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.platform.UpdateProfileRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.platform.UpdateProfileRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.platform.UpdateProfileRequest.displayName = 'proto.platform.UpdateProfileRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.platform.GetProfilesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.platform.GetProfilesResponse.repeatedFields_, null);
};
goog.inherits(proto.platform.GetProfilesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.platform.GetProfilesResponse.displayName = 'proto.platform.GetProfilesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.platform.CreateProfileRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.platform.CreateProfileRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.platform.CreateProfileRequest.displayName = 'proto.platform.CreateProfileRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.platform.Profile = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.platform.Profile.repeatedFields_, null);
};
goog.inherits(proto.platform.Profile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.platform.Profile.displayName = 'proto.platform.Profile';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.platform.SubmitFlagRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.platform.SubmitFlagRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.platform.SubmitFlagRequest.displayName = 'proto.platform.SubmitFlagRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.platform.SubmitFlagResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.platform.SubmitFlagResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.platform.SubmitFlagResponse.displayName = 'proto.platform.SubmitFlagResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.platform.SubmitWriteupRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.platform.SubmitWriteupRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.platform.SubmitWriteupRequest.displayName = 'proto.platform.SubmitWriteupRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.platform.FetchWriteupRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.platform.FetchWriteupRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.platform.FetchWriteupRequest.displayName = 'proto.platform.FetchWriteupRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.platform.FetchWriteupResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.platform.FetchWriteupResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.platform.FetchWriteupResponse.displayName = 'proto.platform.FetchWriteupResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.platform.KothTickData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.platform.KothTickData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.platform.KothTickData.displayName = 'proto.platform.KothTickData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.platform.SubmitKothTickRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.platform.SubmitKothTickRequest.repeatedFields_, null);
};
goog.inherits(proto.platform.SubmitKothTickRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.platform.SubmitKothTickRequest.displayName = 'proto.platform.SubmitKothTickRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.platform.SubmitKothTickResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.platform.SubmitKothTickResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.platform.SubmitKothTickResponse.displayName = 'proto.platform.SubmitKothTickResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.platform.AuthenticateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.platform.AuthenticateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.platform.AuthenticateRequest.displayName = 'proto.platform.AuthenticateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.platform.AuthenticateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.platform.AuthenticateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.platform.AuthenticateResponse.displayName = 'proto.platform.AuthenticateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.platform.SubmitWriteupResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.platform.SubmitWriteupResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.platform.SubmitWriteupResponse.displayName = 'proto.platform.SubmitWriteupResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.platform.GetTransactionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.platform.GetTransactionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.platform.GetTransactionsRequest.displayName = 'proto.platform.GetTransactionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.platform.ValidFlagSubmission = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.platform.ValidFlagSubmission, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.platform.ValidFlagSubmission.displayName = 'proto.platform.ValidFlagSubmission';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.platform.ValidFlagSubmissionBatch = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.platform.ValidFlagSubmissionBatch.repeatedFields_, null);
};
goog.inherits(proto.platform.ValidFlagSubmissionBatch, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.platform.ValidFlagSubmissionBatch.displayName = 'proto.platform.ValidFlagSubmissionBatch';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.platform.GetTransactionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.platform.GetTransactionsResponse.repeatedFields_, null);
};
goog.inherits(proto.platform.GetTransactionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.platform.GetTransactionsResponse.displayName = 'proto.platform.GetTransactionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.platform.KothHistoryTick = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.platform.KothHistoryTick.repeatedFields_, null);
};
goog.inherits(proto.platform.KothHistoryTick, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.platform.KothHistoryTick.displayName = 'proto.platform.KothHistoryTick';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.platform.KothHistoryTick.KothHistoryDatapoint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.platform.KothHistoryTick.KothHistoryDatapoint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.platform.KothHistoryTick.KothHistoryDatapoint.displayName = 'proto.platform.KothHistoryTick.KothHistoryDatapoint';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.platform.GetTasksRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.platform.GetTasksRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.platform.GetTasksRequest.displayName = 'proto.platform.GetTasksRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.platform.GetTasksResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.platform.GetTasksResponse.repeatedFields_, null);
};
goog.inherits(proto.platform.GetTasksResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.platform.GetTasksResponse.displayName = 'proto.platform.GetTasksResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.platform.Transaction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.platform.Transaction.repeatedFields_, null);
};
goog.inherits(proto.platform.Transaction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.platform.Transaction.displayName = 'proto.platform.Transaction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.platform.Standing = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.platform.Standing, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.platform.Standing.displayName = 'proto.platform.Standing';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.platform.Standing.TaskStat = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.platform.Standing.TaskStat, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.platform.Standing.TaskStat.displayName = 'proto.platform.Standing.TaskStat';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.platform.QueueEntry1 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.platform.QueueEntry1, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.platform.QueueEntry1.displayName = 'proto.platform.QueueEntry1';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.platform.TaskState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.platform.TaskState.repeatedFields_, null);
};
goog.inherits(proto.platform.TaskState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.platform.TaskState.displayName = 'proto.platform.TaskState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.platform.Task = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.platform.Task.repeatedFields_, null);
};
goog.inherits(proto.platform.Task, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.platform.Task.displayName = 'proto.platform.Task';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.platform.Task.File = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.platform.Task.File, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.platform.Task.File.displayName = 'proto.platform.Task.File';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.platform.Task.Deployment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.platform.Task.Deployment.repeatedFields_, null);
};
goog.inherits(proto.platform.Task.Deployment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.platform.Task.Deployment.displayName = 'proto.platform.Task.Deployment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.platform.Task.Deployment.Container = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.platform.Task.Deployment.Container, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.platform.Task.Deployment.Container.displayName = 'proto.platform.Task.Deployment.Container';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.platform.Task.Deployment.IngressPort = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.platform.Task.Deployment.IngressPort, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.platform.Task.Deployment.IngressPort.displayName = 'proto.platform.Task.Deployment.IngressPort';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.platform.State = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.platform.State.repeatedFields_, null);
};
goog.inherits(proto.platform.State, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.platform.State.displayName = 'proto.platform.State';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.platform.PlatformConfiguration = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.platform.PlatformConfiguration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.platform.PlatformConfiguration.displayName = 'proto.platform.PlatformConfiguration';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.platform.ScoreboardSnapshot = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.platform.ScoreboardSnapshot.repeatedFields_, null);
};
goog.inherits(proto.platform.ScoreboardSnapshot, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.platform.ScoreboardSnapshot.displayName = 'proto.platform.ScoreboardSnapshot';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.platform.ScoreboardBatch = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.platform.ScoreboardBatch.repeatedFields_, null);
};
goog.inherits(proto.platform.ScoreboardBatch, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.platform.ScoreboardBatch.displayName = 'proto.platform.ScoreboardBatch';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.platform.TransactionAggregate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.platform.TransactionAggregate.repeatedFields_, null);
};
goog.inherits(proto.platform.TransactionAggregate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.platform.TransactionAggregate.displayName = 'proto.platform.TransactionAggregate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.platform.TransactionAggregate.Writeups = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.platform.TransactionAggregate.Writeups, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.platform.TransactionAggregate.Writeups.displayName = 'proto.platform.TransactionAggregate.Writeups';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.platform.Contest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.platform.Contest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.platform.Contest.displayName = 'proto.platform.Contest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.platform.ContestConfiguration = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.platform.ContestConfiguration.repeatedFields_, null);
};
goog.inherits(proto.platform.ContestConfiguration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.platform.ContestConfiguration.displayName = 'proto.platform.ContestConfiguration';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.platform.Bracket = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.platform.Bracket, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.platform.Bracket.displayName = 'proto.platform.Bracket';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.platform.RequestQueuePositionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.platform.RequestQueuePositionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.platform.RequestQueuePositionRequest.displayName = 'proto.platform.RequestQueuePositionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.platform.RequestQueuePositionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.platform.RequestQueuePositionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.platform.RequestQueuePositionResponse.displayName = 'proto.platform.RequestQueuePositionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.platform.LeaveQueueRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.platform.LeaveQueueRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.platform.LeaveQueueRequest.displayName = 'proto.platform.LeaveQueueRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.platform.LeaveQueueResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.platform.LeaveQueueResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.platform.LeaveQueueResponse.displayName = 'proto.platform.LeaveQueueResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.platform.GetQueueStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.platform.GetQueueStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.platform.GetQueueStatusRequest.displayName = 'proto.platform.GetQueueStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.platform.GetQueueStatusResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.platform.GetQueueStatusResponse.repeatedFields_, null);
};
goog.inherits(proto.platform.GetQueueStatusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.platform.GetQueueStatusResponse.displayName = 'proto.platform.GetQueueStatusResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.platform.UpdateQueueStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.platform.UpdateQueueStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.platform.UpdateQueueStatusRequest.displayName = 'proto.platform.UpdateQueueStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.platform.UpdateQueueStatusResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.platform.UpdateQueueStatusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.platform.UpdateQueueStatusResponse.displayName = 'proto.platform.UpdateQueueStatusResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.platform.Notification = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.platform.Notification, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.platform.Notification.displayName = 'proto.platform.Notification';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.platform.GetNotificationsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.platform.GetNotificationsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.platform.GetNotificationsRequest.displayName = 'proto.platform.GetNotificationsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.platform.GetNotificationsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.platform.GetNotificationsResponse.repeatedFields_, null);
};
goog.inherits(proto.platform.GetNotificationsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.platform.GetNotificationsResponse.displayName = 'proto.platform.GetNotificationsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.platform.Badge = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.platform.Badge, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.platform.Badge.displayName = 'proto.platform.Badge';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.platform.GetBadgesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.platform.GetBadgesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.platform.GetBadgesRequest.displayName = 'proto.platform.GetBadgesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.platform.GetBadgesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.platform.GetBadgesResponse.repeatedFields_, null);
};
goog.inherits(proto.platform.GetBadgesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.platform.GetBadgesResponse.displayName = 'proto.platform.GetBadgesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.platform.AddBadgeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.platform.AddBadgeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.platform.AddBadgeRequest.displayName = 'proto.platform.AddBadgeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.platform.AddBadgeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.platform.AddBadgeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.platform.AddBadgeResponse.displayName = 'proto.platform.AddBadgeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.platform.AddNotificationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.platform.AddNotificationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.platform.AddNotificationRequest.displayName = 'proto.platform.AddNotificationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.platform.AddNotificationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.platform.AddNotificationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.platform.AddNotificationResponse.displayName = 'proto.platform.AddNotificationResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.platform.GetDifficultyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.platform.GetDifficultyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.platform.GetDifficultyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.GetDifficultyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    task: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.platform.GetDifficultyRequest}
 */
proto.platform.GetDifficultyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.platform.GetDifficultyRequest;
  return proto.platform.GetDifficultyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.platform.GetDifficultyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.platform.GetDifficultyRequest}
 */
proto.platform.GetDifficultyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTask(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.platform.GetDifficultyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.platform.GetDifficultyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.platform.GetDifficultyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.GetDifficultyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTask();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string task = 1;
 * @return {string}
 */
proto.platform.GetDifficultyRequest.prototype.getTask = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.GetDifficultyRequest} returns this
 */
proto.platform.GetDifficultyRequest.prototype.setTask = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.platform.GetDifficultyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.platform.GetDifficultyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.platform.GetDifficultyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.GetDifficultyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    major: jspb.Message.getFieldWithDefault(msg, 1, 0),
    minor: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.platform.GetDifficultyResponse}
 */
proto.platform.GetDifficultyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.platform.GetDifficultyResponse;
  return proto.platform.GetDifficultyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.platform.GetDifficultyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.platform.GetDifficultyResponse}
 */
proto.platform.GetDifficultyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMajor(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMinor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.platform.GetDifficultyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.platform.GetDifficultyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.platform.GetDifficultyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.GetDifficultyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMajor();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getMinor();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional uint32 major = 1;
 * @return {number}
 */
proto.platform.GetDifficultyResponse.prototype.getMajor = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.platform.GetDifficultyResponse} returns this
 */
proto.platform.GetDifficultyResponse.prototype.setMajor = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 minor = 2;
 * @return {number}
 */
proto.platform.GetDifficultyResponse.prototype.getMinor = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.platform.GetDifficultyResponse} returns this
 */
proto.platform.GetDifficultyResponse.prototype.setMinor = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.platform.CreateSessionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.platform.CreateSessionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.platform.CreateSessionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.CreateSessionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    nonce: jspb.Message.getFieldWithDefault(msg, 1, ""),
    hash: jspb.Message.getFieldWithDefault(msg, 2, ""),
    task: jspb.Message.getFieldWithDefault(msg, 3, ""),
    skippow: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.platform.CreateSessionRequest}
 */
proto.platform.CreateSessionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.platform.CreateSessionRequest;
  return proto.platform.CreateSessionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.platform.CreateSessionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.platform.CreateSessionRequest}
 */
proto.platform.CreateSessionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setNonce(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setHash(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTask(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSkippow(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.platform.CreateSessionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.platform.CreateSessionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.platform.CreateSessionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.CreateSessionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNonce();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getHash();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTask();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSkippow();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string nonce = 1;
 * @return {string}
 */
proto.platform.CreateSessionRequest.prototype.getNonce = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.CreateSessionRequest} returns this
 */
proto.platform.CreateSessionRequest.prototype.setNonce = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string hash = 2;
 * @return {string}
 */
proto.platform.CreateSessionRequest.prototype.getHash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.CreateSessionRequest} returns this
 */
proto.platform.CreateSessionRequest.prototype.setHash = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string task = 3;
 * @return {string}
 */
proto.platform.CreateSessionRequest.prototype.getTask = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.CreateSessionRequest} returns this
 */
proto.platform.CreateSessionRequest.prototype.setTask = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool skipPoW = 4;
 * @return {boolean}
 */
proto.platform.CreateSessionRequest.prototype.getSkippow = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.platform.CreateSessionRequest} returns this
 */
proto.platform.CreateSessionRequest.prototype.setSkippow = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.platform.BrokerSession.prototype.toObject = function(opt_includeInstance) {
  return proto.platform.BrokerSession.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.platform.BrokerSession} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.BrokerSession.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    task: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.platform.BrokerSession}
 */
proto.platform.BrokerSession.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.platform.BrokerSession;
  return proto.platform.BrokerSession.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.platform.BrokerSession} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.platform.BrokerSession}
 */
proto.platform.BrokerSession.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTask(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.platform.BrokerSession.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.platform.BrokerSession.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.platform.BrokerSession} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.BrokerSession.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTask();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.platform.BrokerSession.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.BrokerSession} returns this
 */
proto.platform.BrokerSession.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string task = 2;
 * @return {string}
 */
proto.platform.BrokerSession.prototype.getTask = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.BrokerSession} returns this
 */
proto.platform.BrokerSession.prototype.setTask = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.platform.GetSessionStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.platform.GetSessionStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.platform.GetSessionStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.GetSessionStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    task: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.platform.GetSessionStatusRequest}
 */
proto.platform.GetSessionStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.platform.GetSessionStatusRequest;
  return proto.platform.GetSessionStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.platform.GetSessionStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.platform.GetSessionStatusRequest}
 */
proto.platform.GetSessionStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTask(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.platform.GetSessionStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.platform.GetSessionStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.platform.GetSessionStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.GetSessionStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTask();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.platform.GetSessionStatusRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.GetSessionStatusRequest} returns this
 */
proto.platform.GetSessionStatusRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string task = 2;
 * @return {string}
 */
proto.platform.GetSessionStatusRequest.prototype.getTask = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.GetSessionStatusRequest} returns this
 */
proto.platform.GetSessionStatusRequest.prototype.setTask = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.platform.TerminateSessionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.platform.TerminateSessionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.platform.TerminateSessionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.TerminateSessionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    task: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.platform.TerminateSessionRequest}
 */
proto.platform.TerminateSessionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.platform.TerminateSessionRequest;
  return proto.platform.TerminateSessionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.platform.TerminateSessionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.platform.TerminateSessionRequest}
 */
proto.platform.TerminateSessionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTask(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.platform.TerminateSessionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.platform.TerminateSessionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.platform.TerminateSessionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.TerminateSessionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTask();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.platform.TerminateSessionRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.TerminateSessionRequest} returns this
 */
proto.platform.TerminateSessionRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string task = 2;
 * @return {string}
 */
proto.platform.TerminateSessionRequest.prototype.getTask = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.TerminateSessionRequest} returns this
 */
proto.platform.TerminateSessionRequest.prototype.setTask = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.platform.GetMySessionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.platform.GetMySessionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.platform.GetMySessionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.GetMySessionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    task: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.platform.GetMySessionsRequest}
 */
proto.platform.GetMySessionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.platform.GetMySessionsRequest;
  return proto.platform.GetMySessionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.platform.GetMySessionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.platform.GetMySessionsRequest}
 */
proto.platform.GetMySessionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTask(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.platform.GetMySessionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.platform.GetMySessionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.platform.GetMySessionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.GetMySessionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTask();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string task = 1;
 * @return {string}
 */
proto.platform.GetMySessionsRequest.prototype.getTask = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.GetMySessionsRequest} returns this
 */
proto.platform.GetMySessionsRequest.prototype.setTask = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.platform.GetMySessionsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.platform.GetMySessionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.platform.GetMySessionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.platform.GetMySessionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.GetMySessionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionsList: jspb.Message.toObjectList(msg.getSessionsList(),
    proto.platform.GetMySessionsResponse.SessionMeta.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.platform.GetMySessionsResponse}
 */
proto.platform.GetMySessionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.platform.GetMySessionsResponse;
  return proto.platform.GetMySessionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.platform.GetMySessionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.platform.GetMySessionsResponse}
 */
proto.platform.GetMySessionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.platform.GetMySessionsResponse.SessionMeta;
      reader.readMessage(value,proto.platform.GetMySessionsResponse.SessionMeta.deserializeBinaryFromReader);
      msg.addSessions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.platform.GetMySessionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.platform.GetMySessionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.platform.GetMySessionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.GetMySessionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.platform.GetMySessionsResponse.SessionMeta.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.platform.GetMySessionsResponse.SessionMeta.prototype.toObject = function(opt_includeInstance) {
  return proto.platform.GetMySessionsResponse.SessionMeta.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.platform.GetMySessionsResponse.SessionMeta} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.GetMySessionsResponse.SessionMeta.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    task: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.platform.GetMySessionsResponse.SessionMeta}
 */
proto.platform.GetMySessionsResponse.SessionMeta.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.platform.GetMySessionsResponse.SessionMeta;
  return proto.platform.GetMySessionsResponse.SessionMeta.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.platform.GetMySessionsResponse.SessionMeta} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.platform.GetMySessionsResponse.SessionMeta}
 */
proto.platform.GetMySessionsResponse.SessionMeta.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTask(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.platform.GetMySessionsResponse.SessionMeta.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.platform.GetMySessionsResponse.SessionMeta.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.platform.GetMySessionsResponse.SessionMeta} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.GetMySessionsResponse.SessionMeta.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTask();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.platform.GetMySessionsResponse.SessionMeta.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.GetMySessionsResponse.SessionMeta} returns this
 */
proto.platform.GetMySessionsResponse.SessionMeta.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string task = 2;
 * @return {string}
 */
proto.platform.GetMySessionsResponse.SessionMeta.prototype.getTask = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.GetMySessionsResponse.SessionMeta} returns this
 */
proto.platform.GetMySessionsResponse.SessionMeta.prototype.setTask = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated SessionMeta sessions = 1;
 * @return {!Array<!proto.platform.GetMySessionsResponse.SessionMeta>}
 */
proto.platform.GetMySessionsResponse.prototype.getSessionsList = function() {
  return /** @type{!Array<!proto.platform.GetMySessionsResponse.SessionMeta>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.platform.GetMySessionsResponse.SessionMeta, 1));
};


/**
 * @param {!Array<!proto.platform.GetMySessionsResponse.SessionMeta>} value
 * @return {!proto.platform.GetMySessionsResponse} returns this
*/
proto.platform.GetMySessionsResponse.prototype.setSessionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.platform.GetMySessionsResponse.SessionMeta=} opt_value
 * @param {number=} opt_index
 * @return {!proto.platform.GetMySessionsResponse.SessionMeta}
 */
proto.platform.GetMySessionsResponse.prototype.addSessions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.platform.GetMySessionsResponse.SessionMeta, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.platform.GetMySessionsResponse} returns this
 */
proto.platform.GetMySessionsResponse.prototype.clearSessionsList = function() {
  return this.setSessionsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.platform.GetSessionStatusResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.platform.GetSessionStatusResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.platform.GetSessionStatusResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.platform.GetSessionStatusResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.GetSessionStatusResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    state: jspb.Message.getFieldWithDefault(msg, 2, 0),
    activeEndpointsList: jspb.Message.toObjectList(msg.getActiveEndpointsList(),
    proto.platform.GetSessionStatusResponse.SessionEndpoint.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.platform.GetSessionStatusResponse}
 */
proto.platform.GetSessionStatusResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.platform.GetSessionStatusResponse;
  return proto.platform.GetSessionStatusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.platform.GetSessionStatusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.platform.GetSessionStatusResponse}
 */
proto.platform.GetSessionStatusResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.platform.GetSessionStatusResponse.State} */ (reader.readEnum());
      msg.setState(value);
      break;
    case 3:
      var value = new proto.platform.GetSessionStatusResponse.SessionEndpoint;
      reader.readMessage(value,proto.platform.GetSessionStatusResponse.SessionEndpoint.deserializeBinaryFromReader);
      msg.addActiveEndpoints(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.platform.GetSessionStatusResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.platform.GetSessionStatusResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.platform.GetSessionStatusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.GetSessionStatusResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getActiveEndpointsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.platform.GetSessionStatusResponse.SessionEndpoint.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.platform.GetSessionStatusResponse.State = {
  UNKNOWN: 0,
  ALIVE: 1,
  FAILED: 2,
  ACTIVATING: 3,
  DEACTIVATING: 4
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.platform.GetSessionStatusResponse.SessionEndpoint.prototype.toObject = function(opt_includeInstance) {
  return proto.platform.GetSessionStatusResponse.SessionEndpoint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.platform.GetSessionStatusResponse.SessionEndpoint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.GetSessionStatusResponse.SessionEndpoint.toObject = function(includeInstance, msg) {
  var f, obj = {
    kind: jspb.Message.getFieldWithDefault(msg, 1, 0),
    address: jspb.Message.getFieldWithDefault(msg, 2, ""),
    port: jspb.Message.getFieldWithDefault(msg, 3, 0),
    displaystyle: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.platform.GetSessionStatusResponse.SessionEndpoint}
 */
proto.platform.GetSessionStatusResponse.SessionEndpoint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.platform.GetSessionStatusResponse.SessionEndpoint;
  return proto.platform.GetSessionStatusResponse.SessionEndpoint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.platform.GetSessionStatusResponse.SessionEndpoint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.platform.GetSessionStatusResponse.SessionEndpoint}
 */
proto.platform.GetSessionStatusResponse.SessionEndpoint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.platform.GetSessionStatusResponse.SessionEndpoint.EndpointKind} */ (reader.readEnum());
      msg.setKind(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPort(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplaystyle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.platform.GetSessionStatusResponse.SessionEndpoint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.platform.GetSessionStatusResponse.SessionEndpoint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.platform.GetSessionStatusResponse.SessionEndpoint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.GetSessionStatusResponse.SessionEndpoint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKind();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPort();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getDisplaystyle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.platform.GetSessionStatusResponse.SessionEndpoint.EndpointKind = {
  TLS: 0,
  SSH: 2
};

/**
 * optional EndpointKind kind = 1;
 * @return {!proto.platform.GetSessionStatusResponse.SessionEndpoint.EndpointKind}
 */
proto.platform.GetSessionStatusResponse.SessionEndpoint.prototype.getKind = function() {
  return /** @type {!proto.platform.GetSessionStatusResponse.SessionEndpoint.EndpointKind} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.platform.GetSessionStatusResponse.SessionEndpoint.EndpointKind} value
 * @return {!proto.platform.GetSessionStatusResponse.SessionEndpoint} returns this
 */
proto.platform.GetSessionStatusResponse.SessionEndpoint.prototype.setKind = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string address = 2;
 * @return {string}
 */
proto.platform.GetSessionStatusResponse.SessionEndpoint.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.GetSessionStatusResponse.SessionEndpoint} returns this
 */
proto.platform.GetSessionStatusResponse.SessionEndpoint.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 port = 3;
 * @return {number}
 */
proto.platform.GetSessionStatusResponse.SessionEndpoint.prototype.getPort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.platform.GetSessionStatusResponse.SessionEndpoint} returns this
 */
proto.platform.GetSessionStatusResponse.SessionEndpoint.prototype.setPort = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string displayStyle = 4;
 * @return {string}
 */
proto.platform.GetSessionStatusResponse.SessionEndpoint.prototype.getDisplaystyle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.GetSessionStatusResponse.SessionEndpoint} returns this
 */
proto.platform.GetSessionStatusResponse.SessionEndpoint.prototype.setDisplaystyle = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.platform.GetSessionStatusResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.GetSessionStatusResponse} returns this
 */
proto.platform.GetSessionStatusResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional State state = 2;
 * @return {!proto.platform.GetSessionStatusResponse.State}
 */
proto.platform.GetSessionStatusResponse.prototype.getState = function() {
  return /** @type {!proto.platform.GetSessionStatusResponse.State} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.platform.GetSessionStatusResponse.State} value
 * @return {!proto.platform.GetSessionStatusResponse} returns this
 */
proto.platform.GetSessionStatusResponse.prototype.setState = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * repeated SessionEndpoint active_endpoints = 3;
 * @return {!Array<!proto.platform.GetSessionStatusResponse.SessionEndpoint>}
 */
proto.platform.GetSessionStatusResponse.prototype.getActiveEndpointsList = function() {
  return /** @type{!Array<!proto.platform.GetSessionStatusResponse.SessionEndpoint>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.platform.GetSessionStatusResponse.SessionEndpoint, 3));
};


/**
 * @param {!Array<!proto.platform.GetSessionStatusResponse.SessionEndpoint>} value
 * @return {!proto.platform.GetSessionStatusResponse} returns this
*/
proto.platform.GetSessionStatusResponse.prototype.setActiveEndpointsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.platform.GetSessionStatusResponse.SessionEndpoint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.platform.GetSessionStatusResponse.SessionEndpoint}
 */
proto.platform.GetSessionStatusResponse.prototype.addActiveEndpoints = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.platform.GetSessionStatusResponse.SessionEndpoint, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.platform.GetSessionStatusResponse} returns this
 */
proto.platform.GetSessionStatusResponse.prototype.clearActiveEndpointsList = function() {
  return this.setActiveEndpointsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.platform.GetStateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.platform.GetStateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.platform.GetStateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.GetStateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    streaming: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.platform.GetStateRequest}
 */
proto.platform.GetStateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.platform.GetStateRequest;
  return proto.platform.GetStateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.platform.GetStateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.platform.GetStateRequest}
 */
proto.platform.GetStateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStreaming(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.platform.GetStateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.platform.GetStateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.platform.GetStateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.GetStateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStreaming();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool streaming = 1;
 * @return {boolean}
 */
proto.platform.GetStateRequest.prototype.getStreaming = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.platform.GetStateRequest} returns this
 */
proto.platform.GetStateRequest.prototype.setStreaming = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.platform.GetProfilesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.platform.GetProfilesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.platform.GetProfilesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.GetProfilesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    streaming: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.platform.GetProfilesRequest}
 */
proto.platform.GetProfilesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.platform.GetProfilesRequest;
  return proto.platform.GetProfilesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.platform.GetProfilesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.platform.GetProfilesRequest}
 */
proto.platform.GetProfilesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStreaming(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.platform.GetProfilesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.platform.GetProfilesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.platform.GetProfilesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.GetProfilesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStreaming();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool streaming = 1;
 * @return {boolean}
 */
proto.platform.GetProfilesRequest.prototype.getStreaming = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.platform.GetProfilesRequest} returns this
 */
proto.platform.GetProfilesRequest.prototype.setStreaming = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.platform.GetOwnProfileRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.platform.GetOwnProfileRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.platform.GetOwnProfileRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.GetOwnProfileRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    streaming: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.platform.GetOwnProfileRequest}
 */
proto.platform.GetOwnProfileRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.platform.GetOwnProfileRequest;
  return proto.platform.GetOwnProfileRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.platform.GetOwnProfileRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.platform.GetOwnProfileRequest}
 */
proto.platform.GetOwnProfileRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStreaming(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.platform.GetOwnProfileRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.platform.GetOwnProfileRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.platform.GetOwnProfileRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.GetOwnProfileRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStreaming();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool streaming = 1;
 * @return {boolean}
 */
proto.platform.GetOwnProfileRequest.prototype.getStreaming = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.platform.GetOwnProfileRequest} returns this
 */
proto.platform.GetOwnProfileRequest.prototype.setStreaming = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.platform.UpdateProfileRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.platform.UpdateProfileRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.platform.UpdateProfileRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.UpdateProfileRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    profile: (f = msg.getProfile()) && proto.platform.Profile.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.platform.UpdateProfileRequest}
 */
proto.platform.UpdateProfileRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.platform.UpdateProfileRequest;
  return proto.platform.UpdateProfileRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.platform.UpdateProfileRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.platform.UpdateProfileRequest}
 */
proto.platform.UpdateProfileRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.platform.Profile;
      reader.readMessage(value,proto.platform.Profile.deserializeBinaryFromReader);
      msg.setProfile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.platform.UpdateProfileRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.platform.UpdateProfileRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.platform.UpdateProfileRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.UpdateProfileRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProfile();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.platform.Profile.serializeBinaryToWriter
    );
  }
};


/**
 * optional Profile profile = 1;
 * @return {?proto.platform.Profile}
 */
proto.platform.UpdateProfileRequest.prototype.getProfile = function() {
  return /** @type{?proto.platform.Profile} */ (
    jspb.Message.getWrapperField(this, proto.platform.Profile, 1));
};


/**
 * @param {?proto.platform.Profile|undefined} value
 * @return {!proto.platform.UpdateProfileRequest} returns this
*/
proto.platform.UpdateProfileRequest.prototype.setProfile = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.platform.UpdateProfileRequest} returns this
 */
proto.platform.UpdateProfileRequest.prototype.clearProfile = function() {
  return this.setProfile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.platform.UpdateProfileRequest.prototype.hasProfile = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.platform.GetProfilesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.platform.GetProfilesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.platform.GetProfilesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.platform.GetProfilesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.GetProfilesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    profilesList: jspb.Message.toObjectList(msg.getProfilesList(),
    proto.platform.Profile.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.platform.GetProfilesResponse}
 */
proto.platform.GetProfilesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.platform.GetProfilesResponse;
  return proto.platform.GetProfilesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.platform.GetProfilesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.platform.GetProfilesResponse}
 */
proto.platform.GetProfilesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.platform.Profile;
      reader.readMessage(value,proto.platform.Profile.deserializeBinaryFromReader);
      msg.addProfiles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.platform.GetProfilesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.platform.GetProfilesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.platform.GetProfilesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.GetProfilesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProfilesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.platform.Profile.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Profile profiles = 1;
 * @return {!Array<!proto.platform.Profile>}
 */
proto.platform.GetProfilesResponse.prototype.getProfilesList = function() {
  return /** @type{!Array<!proto.platform.Profile>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.platform.Profile, 1));
};


/**
 * @param {!Array<!proto.platform.Profile>} value
 * @return {!proto.platform.GetProfilesResponse} returns this
*/
proto.platform.GetProfilesResponse.prototype.setProfilesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.platform.Profile=} opt_value
 * @param {number=} opt_index
 * @return {!proto.platform.Profile}
 */
proto.platform.GetProfilesResponse.prototype.addProfiles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.platform.Profile, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.platform.GetProfilesResponse} returns this
 */
proto.platform.GetProfilesResponse.prototype.clearProfilesList = function() {
  return this.setProfilesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.platform.CreateProfileRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.platform.CreateProfileRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.platform.CreateProfileRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.CreateProfileRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    profile: (f = msg.getProfile()) && proto.platform.Profile.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.platform.CreateProfileRequest}
 */
proto.platform.CreateProfileRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.platform.CreateProfileRequest;
  return proto.platform.CreateProfileRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.platform.CreateProfileRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.platform.CreateProfileRequest}
 */
proto.platform.CreateProfileRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.platform.Profile;
      reader.readMessage(value,proto.platform.Profile.deserializeBinaryFromReader);
      msg.setProfile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.platform.CreateProfileRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.platform.CreateProfileRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.platform.CreateProfileRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.CreateProfileRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProfile();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.platform.Profile.serializeBinaryToWriter
    );
  }
};


/**
 * optional Profile profile = 1;
 * @return {?proto.platform.Profile}
 */
proto.platform.CreateProfileRequest.prototype.getProfile = function() {
  return /** @type{?proto.platform.Profile} */ (
    jspb.Message.getWrapperField(this, proto.platform.Profile, 1));
};


/**
 * @param {?proto.platform.Profile|undefined} value
 * @return {!proto.platform.CreateProfileRequest} returns this
*/
proto.platform.CreateProfileRequest.prototype.setProfile = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.platform.CreateProfileRequest} returns this
 */
proto.platform.CreateProfileRequest.prototype.clearProfile = function() {
  return this.setProfile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.platform.CreateProfileRequest.prototype.hasProfile = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.platform.Profile.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.platform.Profile.prototype.toObject = function(opt_includeInstance) {
  return proto.platform.Profile.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.platform.Profile} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.Profile.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, ""),
    country: jspb.Message.getFieldWithDefault(msg, 3, ""),
    type: jspb.Message.getFieldWithDefault(msg, 4, 0),
    admin: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    avatar: jspb.Message.getFieldWithDefault(msg, 6, ""),
    badgesList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    bracketId: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.platform.Profile}
 */
proto.platform.Profile.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.platform.Profile;
  return proto.platform.Profile.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.platform.Profile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.platform.Profile}
 */
proto.platform.Profile.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 4:
      var value = /** @type {!proto.platform.ProfileType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAdmin(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAvatar(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addBadges(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setBracketId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.platform.Profile.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.platform.Profile.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.platform.Profile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.Profile.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getAdmin();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getAvatar();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getBadgesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
  f = message.getBracketId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.platform.Profile.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.Profile} returns this
 */
proto.platform.Profile.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.platform.Profile.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.Profile} returns this
 */
proto.platform.Profile.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string country = 3;
 * @return {string}
 */
proto.platform.Profile.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.Profile} returns this
 */
proto.platform.Profile.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional ProfileType type = 4;
 * @return {!proto.platform.ProfileType}
 */
proto.platform.Profile.prototype.getType = function() {
  return /** @type {!proto.platform.ProfileType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.platform.ProfileType} value
 * @return {!proto.platform.Profile} returns this
 */
proto.platform.Profile.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional bool admin = 5;
 * @return {boolean}
 */
proto.platform.Profile.prototype.getAdmin = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.platform.Profile} returns this
 */
proto.platform.Profile.prototype.setAdmin = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional string avatar = 6;
 * @return {string}
 */
proto.platform.Profile.prototype.getAvatar = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.Profile} returns this
 */
proto.platform.Profile.prototype.setAvatar = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated string badges = 7;
 * @return {!Array<string>}
 */
proto.platform.Profile.prototype.getBadgesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.platform.Profile} returns this
 */
proto.platform.Profile.prototype.setBadgesList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.platform.Profile} returns this
 */
proto.platform.Profile.prototype.addBadges = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.platform.Profile} returns this
 */
proto.platform.Profile.prototype.clearBadgesList = function() {
  return this.setBadgesList([]);
};


/**
 * optional string bracket_id = 8;
 * @return {string}
 */
proto.platform.Profile.prototype.getBracketId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.Profile} returns this
 */
proto.platform.Profile.prototype.setBracketId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.platform.SubmitFlagRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.platform.SubmitFlagRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.platform.SubmitFlagRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.SubmitFlagRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    flag: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.platform.SubmitFlagRequest}
 */
proto.platform.SubmitFlagRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.platform.SubmitFlagRequest;
  return proto.platform.SubmitFlagRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.platform.SubmitFlagRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.platform.SubmitFlagRequest}
 */
proto.platform.SubmitFlagRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlag(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.platform.SubmitFlagRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.platform.SubmitFlagRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.platform.SubmitFlagRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.SubmitFlagRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFlag();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string flag = 1;
 * @return {string}
 */
proto.platform.SubmitFlagRequest.prototype.getFlag = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.SubmitFlagRequest} returns this
 */
proto.platform.SubmitFlagRequest.prototype.setFlag = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.platform.SubmitFlagResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.platform.SubmitFlagResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.platform.SubmitFlagResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.SubmitFlagResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    task: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.platform.SubmitFlagResponse}
 */
proto.platform.SubmitFlagResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.platform.SubmitFlagResponse;
  return proto.platform.SubmitFlagResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.platform.SubmitFlagResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.platform.SubmitFlagResponse}
 */
proto.platform.SubmitFlagResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTask(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.platform.SubmitFlagResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.platform.SubmitFlagResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.platform.SubmitFlagResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.SubmitFlagResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTask();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string task = 1;
 * @return {string}
 */
proto.platform.SubmitFlagResponse.prototype.getTask = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.SubmitFlagResponse} returns this
 */
proto.platform.SubmitFlagResponse.prototype.setTask = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.platform.SubmitWriteupRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.platform.SubmitWriteupRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.platform.SubmitWriteupRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.SubmitWriteupRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    task: jspb.Message.getFieldWithDefault(msg, 2, ""),
    writeup: msg.getWriteup_asB64(),
    originalFilename: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.platform.SubmitWriteupRequest}
 */
proto.platform.SubmitWriteupRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.platform.SubmitWriteupRequest;
  return proto.platform.SubmitWriteupRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.platform.SubmitWriteupRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.platform.SubmitWriteupRequest}
 */
proto.platform.SubmitWriteupRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTask(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setWriteup(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginalFilename(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.platform.SubmitWriteupRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.platform.SubmitWriteupRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.platform.SubmitWriteupRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.SubmitWriteupRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTask();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getWriteup_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = message.getOriginalFilename();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string task = 2;
 * @return {string}
 */
proto.platform.SubmitWriteupRequest.prototype.getTask = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.SubmitWriteupRequest} returns this
 */
proto.platform.SubmitWriteupRequest.prototype.setTask = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bytes writeup = 3;
 * @return {!(string|Uint8Array)}
 */
proto.platform.SubmitWriteupRequest.prototype.getWriteup = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes writeup = 3;
 * This is a type-conversion wrapper around `getWriteup()`
 * @return {string}
 */
proto.platform.SubmitWriteupRequest.prototype.getWriteup_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getWriteup()));
};


/**
 * optional bytes writeup = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getWriteup()`
 * @return {!Uint8Array}
 */
proto.platform.SubmitWriteupRequest.prototype.getWriteup_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getWriteup()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.platform.SubmitWriteupRequest} returns this
 */
proto.platform.SubmitWriteupRequest.prototype.setWriteup = function(value) {
  return jspb.Message.setProto3BytesField(this, 3, value);
};


/**
 * optional string original_filename = 4;
 * @return {string}
 */
proto.platform.SubmitWriteupRequest.prototype.getOriginalFilename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.SubmitWriteupRequest} returns this
 */
proto.platform.SubmitWriteupRequest.prototype.setOriginalFilename = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.platform.FetchWriteupRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.platform.FetchWriteupRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.platform.FetchWriteupRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.FetchWriteupRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    task: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.platform.FetchWriteupRequest}
 */
proto.platform.FetchWriteupRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.platform.FetchWriteupRequest;
  return proto.platform.FetchWriteupRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.platform.FetchWriteupRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.platform.FetchWriteupRequest}
 */
proto.platform.FetchWriteupRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTask(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.platform.FetchWriteupRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.platform.FetchWriteupRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.platform.FetchWriteupRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.FetchWriteupRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTask();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string task = 1;
 * @return {string}
 */
proto.platform.FetchWriteupRequest.prototype.getTask = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.FetchWriteupRequest} returns this
 */
proto.platform.FetchWriteupRequest.prototype.setTask = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.platform.FetchWriteupResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.platform.FetchWriteupResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.platform.FetchWriteupResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.FetchWriteupResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    writeup: msg.getWriteup_asB64(),
    originalFilename: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.platform.FetchWriteupResponse}
 */
proto.platform.FetchWriteupResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.platform.FetchWriteupResponse;
  return proto.platform.FetchWriteupResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.platform.FetchWriteupResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.platform.FetchWriteupResponse}
 */
proto.platform.FetchWriteupResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setWriteup(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginalFilename(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.platform.FetchWriteupResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.platform.FetchWriteupResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.platform.FetchWriteupResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.FetchWriteupResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWriteup_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getOriginalFilename();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bytes writeup = 1;
 * @return {!(string|Uint8Array)}
 */
proto.platform.FetchWriteupResponse.prototype.getWriteup = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes writeup = 1;
 * This is a type-conversion wrapper around `getWriteup()`
 * @return {string}
 */
proto.platform.FetchWriteupResponse.prototype.getWriteup_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getWriteup()));
};


/**
 * optional bytes writeup = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getWriteup()`
 * @return {!Uint8Array}
 */
proto.platform.FetchWriteupResponse.prototype.getWriteup_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getWriteup()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.platform.FetchWriteupResponse} returns this
 */
proto.platform.FetchWriteupResponse.prototype.setWriteup = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional string original_filename = 2;
 * @return {string}
 */
proto.platform.FetchWriteupResponse.prototype.getOriginalFilename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.FetchWriteupResponse} returns this
 */
proto.platform.FetchWriteupResponse.prototype.setOriginalFilename = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.platform.KothTickData.prototype.toObject = function(opt_includeInstance) {
  return proto.platform.KothTickData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.platform.KothTickData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.KothTickData.toObject = function(includeInstance, msg) {
  var f, obj = {
    team: jspb.Message.getFieldWithDefault(msg, 1, ""),
    points: jspb.Message.getFieldWithDefault(msg, 2, 0),
    additionaldata: msg.getAdditionaldata_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.platform.KothTickData}
 */
proto.platform.KothTickData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.platform.KothTickData;
  return proto.platform.KothTickData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.platform.KothTickData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.platform.KothTickData}
 */
proto.platform.KothTickData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTeam(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPoints(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setAdditionaldata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.platform.KothTickData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.platform.KothTickData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.platform.KothTickData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.KothTickData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTeam();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPoints();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getAdditionaldata_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
};


/**
 * optional string team = 1;
 * @return {string}
 */
proto.platform.KothTickData.prototype.getTeam = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.KothTickData} returns this
 */
proto.platform.KothTickData.prototype.setTeam = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 points = 2;
 * @return {number}
 */
proto.platform.KothTickData.prototype.getPoints = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.platform.KothTickData} returns this
 */
proto.platform.KothTickData.prototype.setPoints = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bytes additionalData = 3;
 * @return {!(string|Uint8Array)}
 */
proto.platform.KothTickData.prototype.getAdditionaldata = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes additionalData = 3;
 * This is a type-conversion wrapper around `getAdditionaldata()`
 * @return {string}
 */
proto.platform.KothTickData.prototype.getAdditionaldata_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getAdditionaldata()));
};


/**
 * optional bytes additionalData = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getAdditionaldata()`
 * @return {!Uint8Array}
 */
proto.platform.KothTickData.prototype.getAdditionaldata_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getAdditionaldata()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.platform.KothTickData} returns this
 */
proto.platform.KothTickData.prototype.setAdditionaldata = function(value) {
  return jspb.Message.setProto3BytesField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.platform.SubmitKothTickRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.platform.SubmitKothTickRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.platform.SubmitKothTickRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.platform.SubmitKothTickRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.SubmitKothTickRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    task: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tickdataList: jspb.Message.toObjectList(msg.getTickdataList(),
    proto.platform.KothTickData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.platform.SubmitKothTickRequest}
 */
proto.platform.SubmitKothTickRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.platform.SubmitKothTickRequest;
  return proto.platform.SubmitKothTickRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.platform.SubmitKothTickRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.platform.SubmitKothTickRequest}
 */
proto.platform.SubmitKothTickRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTask(value);
      break;
    case 2:
      var value = new proto.platform.KothTickData;
      reader.readMessage(value,proto.platform.KothTickData.deserializeBinaryFromReader);
      msg.addTickdata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.platform.SubmitKothTickRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.platform.SubmitKothTickRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.platform.SubmitKothTickRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.SubmitKothTickRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTask();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTickdataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.platform.KothTickData.serializeBinaryToWriter
    );
  }
};


/**
 * optional string task = 1;
 * @return {string}
 */
proto.platform.SubmitKothTickRequest.prototype.getTask = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.SubmitKothTickRequest} returns this
 */
proto.platform.SubmitKothTickRequest.prototype.setTask = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated KothTickData tickData = 2;
 * @return {!Array<!proto.platform.KothTickData>}
 */
proto.platform.SubmitKothTickRequest.prototype.getTickdataList = function() {
  return /** @type{!Array<!proto.platform.KothTickData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.platform.KothTickData, 2));
};


/**
 * @param {!Array<!proto.platform.KothTickData>} value
 * @return {!proto.platform.SubmitKothTickRequest} returns this
*/
proto.platform.SubmitKothTickRequest.prototype.setTickdataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.platform.KothTickData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.platform.KothTickData}
 */
proto.platform.SubmitKothTickRequest.prototype.addTickdata = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.platform.KothTickData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.platform.SubmitKothTickRequest} returns this
 */
proto.platform.SubmitKothTickRequest.prototype.clearTickdataList = function() {
  return this.setTickdataList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.platform.SubmitKothTickResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.platform.SubmitKothTickResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.platform.SubmitKothTickResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.SubmitKothTickResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.platform.SubmitKothTickResponse}
 */
proto.platform.SubmitKothTickResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.platform.SubmitKothTickResponse;
  return proto.platform.SubmitKothTickResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.platform.SubmitKothTickResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.platform.SubmitKothTickResponse}
 */
proto.platform.SubmitKothTickResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.platform.SubmitKothTickResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.platform.SubmitKothTickResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.platform.SubmitKothTickResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.SubmitKothTickResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 status = 1;
 * @return {number}
 */
proto.platform.SubmitKothTickResponse.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.platform.SubmitKothTickResponse} returns this
 */
proto.platform.SubmitKothTickResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.platform.AuthenticateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.platform.AuthenticateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.platform.AuthenticateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.AuthenticateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    authenticationCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.platform.AuthenticateRequest}
 */
proto.platform.AuthenticateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.platform.AuthenticateRequest;
  return proto.platform.AuthenticateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.platform.AuthenticateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.platform.AuthenticateRequest}
 */
proto.platform.AuthenticateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthenticationCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 3:
      var value = /** @type {!proto.platform.AuthenticateRequest.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.platform.AuthenticateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.platform.AuthenticateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.platform.AuthenticateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.AuthenticateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuthenticationCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.platform.AuthenticateRequest.Type = {
  CTFTIME: 0,
  DISCORD: 1
};

/**
 * optional string authentication_code = 1;
 * @return {string}
 */
proto.platform.AuthenticateRequest.prototype.getAuthenticationCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.AuthenticateRequest} returns this
 */
proto.platform.AuthenticateRequest.prototype.setAuthenticationCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string display_name = 2;
 * @return {string}
 */
proto.platform.AuthenticateRequest.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.AuthenticateRequest} returns this
 */
proto.platform.AuthenticateRequest.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Type type = 3;
 * @return {!proto.platform.AuthenticateRequest.Type}
 */
proto.platform.AuthenticateRequest.prototype.getType = function() {
  return /** @type {!proto.platform.AuthenticateRequest.Type} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.platform.AuthenticateRequest.Type} value
 * @return {!proto.platform.AuthenticateRequest} returns this
 */
proto.platform.AuthenticateRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.platform.AuthenticateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.platform.AuthenticateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.platform.AuthenticateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.AuthenticateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    jwt: jspb.Message.getFieldWithDefault(msg, 1, ""),
    uid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    discordUsername: jspb.Message.getFieldWithDefault(msg, 4, ""),
    discordDiscriminator: jspb.Message.getFieldWithDefault(msg, 5, ""),
    ctftimeName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    ctftimeCountry: jspb.Message.getFieldWithDefault(msg, 7, ""),
    type: jspb.Message.getFieldWithDefault(msg, 8, 0),
    discordAvatar: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.platform.AuthenticateResponse}
 */
proto.platform.AuthenticateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.platform.AuthenticateResponse;
  return proto.platform.AuthenticateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.platform.AuthenticateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.platform.AuthenticateResponse}
 */
proto.platform.AuthenticateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setJwt(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDiscordUsername(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDiscordDiscriminator(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCtftimeName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCtftimeCountry(value);
      break;
    case 8:
      var value = /** @type {!proto.platform.ProfileType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setDiscordAvatar(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.platform.AuthenticateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.platform.AuthenticateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.platform.AuthenticateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.AuthenticateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getJwt();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDiscordUsername();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDiscordDiscriminator();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCtftimeName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCtftimeCountry();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getDiscordAvatar();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string jwt = 1;
 * @return {string}
 */
proto.platform.AuthenticateResponse.prototype.getJwt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.AuthenticateResponse} returns this
 */
proto.platform.AuthenticateResponse.prototype.setJwt = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string uid = 2;
 * @return {string}
 */
proto.platform.AuthenticateResponse.prototype.getUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.AuthenticateResponse} returns this
 */
proto.platform.AuthenticateResponse.prototype.setUid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string display_name = 3;
 * @return {string}
 */
proto.platform.AuthenticateResponse.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.AuthenticateResponse} returns this
 */
proto.platform.AuthenticateResponse.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string discord_username = 4;
 * @return {string}
 */
proto.platform.AuthenticateResponse.prototype.getDiscordUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.AuthenticateResponse} returns this
 */
proto.platform.AuthenticateResponse.prototype.setDiscordUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string discord_discriminator = 5;
 * @return {string}
 */
proto.platform.AuthenticateResponse.prototype.getDiscordDiscriminator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.AuthenticateResponse} returns this
 */
proto.platform.AuthenticateResponse.prototype.setDiscordDiscriminator = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string ctftime_name = 6;
 * @return {string}
 */
proto.platform.AuthenticateResponse.prototype.getCtftimeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.AuthenticateResponse} returns this
 */
proto.platform.AuthenticateResponse.prototype.setCtftimeName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string ctftime_country = 7;
 * @return {string}
 */
proto.platform.AuthenticateResponse.prototype.getCtftimeCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.AuthenticateResponse} returns this
 */
proto.platform.AuthenticateResponse.prototype.setCtftimeCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional ProfileType type = 8;
 * @return {!proto.platform.ProfileType}
 */
proto.platform.AuthenticateResponse.prototype.getType = function() {
  return /** @type {!proto.platform.ProfileType} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.platform.ProfileType} value
 * @return {!proto.platform.AuthenticateResponse} returns this
 */
proto.platform.AuthenticateResponse.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional string discord_avatar = 9;
 * @return {string}
 */
proto.platform.AuthenticateResponse.prototype.getDiscordAvatar = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.AuthenticateResponse} returns this
 */
proto.platform.AuthenticateResponse.prototype.setDiscordAvatar = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.platform.SubmitWriteupResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.platform.SubmitWriteupResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.platform.SubmitWriteupResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.SubmitWriteupResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    task: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.platform.SubmitWriteupResponse}
 */
proto.platform.SubmitWriteupResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.platform.SubmitWriteupResponse;
  return proto.platform.SubmitWriteupResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.platform.SubmitWriteupResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.platform.SubmitWriteupResponse}
 */
proto.platform.SubmitWriteupResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTask(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.platform.SubmitWriteupResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.platform.SubmitWriteupResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.platform.SubmitWriteupResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.SubmitWriteupResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTask();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string task = 1;
 * @return {string}
 */
proto.platform.SubmitWriteupResponse.prototype.getTask = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.SubmitWriteupResponse} returns this
 */
proto.platform.SubmitWriteupResponse.prototype.setTask = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.platform.GetTransactionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.platform.GetTransactionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.platform.GetTransactionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.GetTransactionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    streaming: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.platform.GetTransactionsRequest}
 */
proto.platform.GetTransactionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.platform.GetTransactionsRequest;
  return proto.platform.GetTransactionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.platform.GetTransactionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.platform.GetTransactionsRequest}
 */
proto.platform.GetTransactionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStreaming(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.platform.GetTransactionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.platform.GetTransactionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.platform.GetTransactionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.GetTransactionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStreaming();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool streaming = 1;
 * @return {boolean}
 */
proto.platform.GetTransactionsRequest.prototype.getStreaming = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.platform.GetTransactionsRequest} returns this
 */
proto.platform.GetTransactionsRequest.prototype.setStreaming = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.platform.ValidFlagSubmission.prototype.toObject = function(opt_includeInstance) {
  return proto.platform.ValidFlagSubmission.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.platform.ValidFlagSubmission} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.ValidFlagSubmission.toObject = function(includeInstance, msg) {
  var f, obj = {
    timestamp: (f = msg.getTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    teamId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    task: jspb.Message.getFieldWithDefault(msg, 3, ""),
    submitter: (f = msg.getSubmitter()) && proto.platform.Profile.toObject(includeInstance, f),
    firstBlood: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.platform.ValidFlagSubmission}
 */
proto.platform.ValidFlagSubmission.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.platform.ValidFlagSubmission;
  return proto.platform.ValidFlagSubmission.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.platform.ValidFlagSubmission} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.platform.ValidFlagSubmission}
 */
proto.platform.ValidFlagSubmission.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimestamp(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTeamId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTask(value);
      break;
    case 4:
      var value = new proto.platform.Profile;
      reader.readMessage(value,proto.platform.Profile.deserializeBinaryFromReader);
      msg.setSubmitter(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFirstBlood(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.platform.ValidFlagSubmission.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.platform.ValidFlagSubmission.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.platform.ValidFlagSubmission} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.ValidFlagSubmission.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimestamp();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTeamId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTask();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSubmitter();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.platform.Profile.serializeBinaryToWriter
    );
  }
  f = message.getFirstBlood();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp timestamp = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.platform.ValidFlagSubmission.prototype.getTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.platform.ValidFlagSubmission} returns this
*/
proto.platform.ValidFlagSubmission.prototype.setTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.platform.ValidFlagSubmission} returns this
 */
proto.platform.ValidFlagSubmission.prototype.clearTimestamp = function() {
  return this.setTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.platform.ValidFlagSubmission.prototype.hasTimestamp = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string team_id = 2;
 * @return {string}
 */
proto.platform.ValidFlagSubmission.prototype.getTeamId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.ValidFlagSubmission} returns this
 */
proto.platform.ValidFlagSubmission.prototype.setTeamId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string task = 3;
 * @return {string}
 */
proto.platform.ValidFlagSubmission.prototype.getTask = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.ValidFlagSubmission} returns this
 */
proto.platform.ValidFlagSubmission.prototype.setTask = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Profile submitter = 4;
 * @return {?proto.platform.Profile}
 */
proto.platform.ValidFlagSubmission.prototype.getSubmitter = function() {
  return /** @type{?proto.platform.Profile} */ (
    jspb.Message.getWrapperField(this, proto.platform.Profile, 4));
};


/**
 * @param {?proto.platform.Profile|undefined} value
 * @return {!proto.platform.ValidFlagSubmission} returns this
*/
proto.platform.ValidFlagSubmission.prototype.setSubmitter = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.platform.ValidFlagSubmission} returns this
 */
proto.platform.ValidFlagSubmission.prototype.clearSubmitter = function() {
  return this.setSubmitter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.platform.ValidFlagSubmission.prototype.hasSubmitter = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool first_blood = 5;
 * @return {boolean}
 */
proto.platform.ValidFlagSubmission.prototype.getFirstBlood = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.platform.ValidFlagSubmission} returns this
 */
proto.platform.ValidFlagSubmission.prototype.setFirstBlood = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.platform.ValidFlagSubmissionBatch.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.platform.ValidFlagSubmissionBatch.prototype.toObject = function(opt_includeInstance) {
  return proto.platform.ValidFlagSubmissionBatch.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.platform.ValidFlagSubmissionBatch} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.ValidFlagSubmissionBatch.toObject = function(includeInstance, msg) {
  var f, obj = {
    submissionsList: jspb.Message.toObjectList(msg.getSubmissionsList(),
    proto.platform.ValidFlagSubmission.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.platform.ValidFlagSubmissionBatch}
 */
proto.platform.ValidFlagSubmissionBatch.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.platform.ValidFlagSubmissionBatch;
  return proto.platform.ValidFlagSubmissionBatch.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.platform.ValidFlagSubmissionBatch} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.platform.ValidFlagSubmissionBatch}
 */
proto.platform.ValidFlagSubmissionBatch.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.platform.ValidFlagSubmission;
      reader.readMessage(value,proto.platform.ValidFlagSubmission.deserializeBinaryFromReader);
      msg.addSubmissions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.platform.ValidFlagSubmissionBatch.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.platform.ValidFlagSubmissionBatch.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.platform.ValidFlagSubmissionBatch} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.ValidFlagSubmissionBatch.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubmissionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.platform.ValidFlagSubmission.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ValidFlagSubmission submissions = 1;
 * @return {!Array<!proto.platform.ValidFlagSubmission>}
 */
proto.platform.ValidFlagSubmissionBatch.prototype.getSubmissionsList = function() {
  return /** @type{!Array<!proto.platform.ValidFlagSubmission>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.platform.ValidFlagSubmission, 1));
};


/**
 * @param {!Array<!proto.platform.ValidFlagSubmission>} value
 * @return {!proto.platform.ValidFlagSubmissionBatch} returns this
*/
proto.platform.ValidFlagSubmissionBatch.prototype.setSubmissionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.platform.ValidFlagSubmission=} opt_value
 * @param {number=} opt_index
 * @return {!proto.platform.ValidFlagSubmission}
 */
proto.platform.ValidFlagSubmissionBatch.prototype.addSubmissions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.platform.ValidFlagSubmission, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.platform.ValidFlagSubmissionBatch} returns this
 */
proto.platform.ValidFlagSubmissionBatch.prototype.clearSubmissionsList = function() {
  return this.setSubmissionsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.platform.GetTransactionsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.platform.GetTransactionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.platform.GetTransactionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.platform.GetTransactionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.GetTransactionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    transactionsList: jspb.Message.toObjectList(msg.getTransactionsList(),
    proto.platform.Transaction.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.platform.GetTransactionsResponse}
 */
proto.platform.GetTransactionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.platform.GetTransactionsResponse;
  return proto.platform.GetTransactionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.platform.GetTransactionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.platform.GetTransactionsResponse}
 */
proto.platform.GetTransactionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.platform.Transaction;
      reader.readMessage(value,proto.platform.Transaction.deserializeBinaryFromReader);
      msg.addTransactions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.platform.GetTransactionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.platform.GetTransactionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.platform.GetTransactionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.GetTransactionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransactionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.platform.Transaction.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Transaction transactions = 1;
 * @return {!Array<!proto.platform.Transaction>}
 */
proto.platform.GetTransactionsResponse.prototype.getTransactionsList = function() {
  return /** @type{!Array<!proto.platform.Transaction>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.platform.Transaction, 1));
};


/**
 * @param {!Array<!proto.platform.Transaction>} value
 * @return {!proto.platform.GetTransactionsResponse} returns this
*/
proto.platform.GetTransactionsResponse.prototype.setTransactionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.platform.Transaction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.platform.Transaction}
 */
proto.platform.GetTransactionsResponse.prototype.addTransactions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.platform.Transaction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.platform.GetTransactionsResponse} returns this
 */
proto.platform.GetTransactionsResponse.prototype.clearTransactionsList = function() {
  return this.setTransactionsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.platform.KothHistoryTick.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.platform.KothHistoryTick.prototype.toObject = function(opt_includeInstance) {
  return proto.platform.KothHistoryTick.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.platform.KothHistoryTick} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.KothHistoryTick.toObject = function(includeInstance, msg) {
  var f, obj = {
    timestamp: (f = msg.getTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    datapointsList: jspb.Message.toObjectList(msg.getDatapointsList(),
    proto.platform.KothHistoryTick.KothHistoryDatapoint.toObject, includeInstance),
    task: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.platform.KothHistoryTick}
 */
proto.platform.KothHistoryTick.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.platform.KothHistoryTick;
  return proto.platform.KothHistoryTick.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.platform.KothHistoryTick} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.platform.KothHistoryTick}
 */
proto.platform.KothHistoryTick.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimestamp(value);
      break;
    case 2:
      var value = new proto.platform.KothHistoryTick.KothHistoryDatapoint;
      reader.readMessage(value,proto.platform.KothHistoryTick.KothHistoryDatapoint.deserializeBinaryFromReader);
      msg.addDatapoints(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTask(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.platform.KothHistoryTick.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.platform.KothHistoryTick.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.platform.KothHistoryTick} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.KothHistoryTick.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimestamp();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDatapointsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.platform.KothHistoryTick.KothHistoryDatapoint.serializeBinaryToWriter
    );
  }
  f = message.getTask();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.platform.KothHistoryTick.KothHistoryDatapoint.prototype.toObject = function(opt_includeInstance) {
  return proto.platform.KothHistoryTick.KothHistoryDatapoint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.platform.KothHistoryTick.KothHistoryDatapoint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.KothHistoryTick.KothHistoryDatapoint.toObject = function(includeInstance, msg) {
  var f, obj = {
    team: jspb.Message.getFieldWithDefault(msg, 1, ""),
    points: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.platform.KothHistoryTick.KothHistoryDatapoint}
 */
proto.platform.KothHistoryTick.KothHistoryDatapoint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.platform.KothHistoryTick.KothHistoryDatapoint;
  return proto.platform.KothHistoryTick.KothHistoryDatapoint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.platform.KothHistoryTick.KothHistoryDatapoint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.platform.KothHistoryTick.KothHistoryDatapoint}
 */
proto.platform.KothHistoryTick.KothHistoryDatapoint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTeam(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPoints(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.platform.KothHistoryTick.KothHistoryDatapoint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.platform.KothHistoryTick.KothHistoryDatapoint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.platform.KothHistoryTick.KothHistoryDatapoint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.KothHistoryTick.KothHistoryDatapoint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTeam();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPoints();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string team = 1;
 * @return {string}
 */
proto.platform.KothHistoryTick.KothHistoryDatapoint.prototype.getTeam = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.KothHistoryTick.KothHistoryDatapoint} returns this
 */
proto.platform.KothHistoryTick.KothHistoryDatapoint.prototype.setTeam = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 points = 2;
 * @return {number}
 */
proto.platform.KothHistoryTick.KothHistoryDatapoint.prototype.getPoints = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.platform.KothHistoryTick.KothHistoryDatapoint} returns this
 */
proto.platform.KothHistoryTick.KothHistoryDatapoint.prototype.setPoints = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp timestamp = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.platform.KothHistoryTick.prototype.getTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.platform.KothHistoryTick} returns this
*/
proto.platform.KothHistoryTick.prototype.setTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.platform.KothHistoryTick} returns this
 */
proto.platform.KothHistoryTick.prototype.clearTimestamp = function() {
  return this.setTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.platform.KothHistoryTick.prototype.hasTimestamp = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated KothHistoryDatapoint datapoints = 2;
 * @return {!Array<!proto.platform.KothHistoryTick.KothHistoryDatapoint>}
 */
proto.platform.KothHistoryTick.prototype.getDatapointsList = function() {
  return /** @type{!Array<!proto.platform.KothHistoryTick.KothHistoryDatapoint>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.platform.KothHistoryTick.KothHistoryDatapoint, 2));
};


/**
 * @param {!Array<!proto.platform.KothHistoryTick.KothHistoryDatapoint>} value
 * @return {!proto.platform.KothHistoryTick} returns this
*/
proto.platform.KothHistoryTick.prototype.setDatapointsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.platform.KothHistoryTick.KothHistoryDatapoint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.platform.KothHistoryTick.KothHistoryDatapoint}
 */
proto.platform.KothHistoryTick.prototype.addDatapoints = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.platform.KothHistoryTick.KothHistoryDatapoint, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.platform.KothHistoryTick} returns this
 */
proto.platform.KothHistoryTick.prototype.clearDatapointsList = function() {
  return this.setDatapointsList([]);
};


/**
 * optional string task = 3;
 * @return {string}
 */
proto.platform.KothHistoryTick.prototype.getTask = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.KothHistoryTick} returns this
 */
proto.platform.KothHistoryTick.prototype.setTask = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.platform.GetTasksRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.platform.GetTasksRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.platform.GetTasksRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.GetTasksRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    streaming: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.platform.GetTasksRequest}
 */
proto.platform.GetTasksRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.platform.GetTasksRequest;
  return proto.platform.GetTasksRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.platform.GetTasksRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.platform.GetTasksRequest}
 */
proto.platform.GetTasksRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStreaming(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.platform.GetTasksRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.platform.GetTasksRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.platform.GetTasksRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.GetTasksRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStreaming();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool streaming = 1;
 * @return {boolean}
 */
proto.platform.GetTasksRequest.prototype.getStreaming = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.platform.GetTasksRequest} returns this
 */
proto.platform.GetTasksRequest.prototype.setStreaming = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.platform.GetTasksResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.platform.GetTasksResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.platform.GetTasksResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.platform.GetTasksResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.GetTasksResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    tasksList: jspb.Message.toObjectList(msg.getTasksList(),
    proto.platform.Task.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.platform.GetTasksResponse}
 */
proto.platform.GetTasksResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.platform.GetTasksResponse;
  return proto.platform.GetTasksResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.platform.GetTasksResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.platform.GetTasksResponse}
 */
proto.platform.GetTasksResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.platform.Task;
      reader.readMessage(value,proto.platform.Task.deserializeBinaryFromReader);
      msg.addTasks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.platform.GetTasksResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.platform.GetTasksResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.platform.GetTasksResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.GetTasksResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTasksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.platform.Task.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Task tasks = 1;
 * @return {!Array<!proto.platform.Task>}
 */
proto.platform.GetTasksResponse.prototype.getTasksList = function() {
  return /** @type{!Array<!proto.platform.Task>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.platform.Task, 1));
};


/**
 * @param {!Array<!proto.platform.Task>} value
 * @return {!proto.platform.GetTasksResponse} returns this
*/
proto.platform.GetTasksResponse.prototype.setTasksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.platform.Task=} opt_value
 * @param {number=} opt_index
 * @return {!proto.platform.Task}
 */
proto.platform.GetTasksResponse.prototype.addTasks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.platform.Task, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.platform.GetTasksResponse} returns this
 */
proto.platform.GetTasksResponse.prototype.clearTasksList = function() {
  return this.setTasksList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.platform.Transaction.repeatedFields_ = [7,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.platform.Transaction.prototype.toObject = function(opt_includeInstance) {
  return proto.platform.Transaction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.platform.Transaction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.Transaction.toObject = function(includeInstance, msg) {
  var f, obj = {
    timestamp: (f = msg.getTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    type: jspb.Message.getFieldWithDefault(msg, 3, 0),
    team: jspb.Message.getFieldWithDefault(msg, 4, ""),
    task: jspb.Message.getFieldWithDefault(msg, 5, ""),
    platformConfig: (f = msg.getPlatformConfig()) && proto.platform.PlatformConfiguration.toObject(includeInstance, f),
    tasksList: jspb.Message.toObjectList(msg.getTasksList(),
    proto.platform.Task.toObject, includeInstance),
    deleted: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    flagHash: jspb.Message.getFieldWithDefault(msg, 9, ""),
    kothdataList: jspb.Message.toObjectList(msg.getKothdataList(),
    proto.platform.KothTickData.toObject, includeInstance),
    profile: (f = msg.getProfile()) && proto.platform.Profile.toObject(includeInstance, f),
    queueEntry: (f = msg.getQueueEntry()) && proto.platform.QueueEntry1.toObject(includeInstance, f),
    notification: (f = msg.getNotification()) && proto.platform.Notification.toObject(includeInstance, f),
    jwtkey: jspb.Message.getFieldWithDefault(msg, 14, ""),
    updateProfile: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    badge: (f = msg.getBadge()) && proto.platform.Badge.toObject(includeInstance, f),
    writeupOriginalFilename: jspb.Message.getFieldWithDefault(msg, 17, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.platform.Transaction}
 */
proto.platform.Transaction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.platform.Transaction;
  return proto.platform.Transaction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.platform.Transaction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.platform.Transaction}
 */
proto.platform.Transaction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimestamp(value);
      break;
    case 3:
      var value = /** @type {!proto.platform.Transaction.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTeam(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTask(value);
      break;
    case 6:
      var value = new proto.platform.PlatformConfiguration;
      reader.readMessage(value,proto.platform.PlatformConfiguration.deserializeBinaryFromReader);
      msg.setPlatformConfig(value);
      break;
    case 7:
      var value = new proto.platform.Task;
      reader.readMessage(value,proto.platform.Task.deserializeBinaryFromReader);
      msg.addTasks(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDeleted(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlagHash(value);
      break;
    case 10:
      var value = new proto.platform.KothTickData;
      reader.readMessage(value,proto.platform.KothTickData.deserializeBinaryFromReader);
      msg.addKothdata(value);
      break;
    case 11:
      var value = new proto.platform.Profile;
      reader.readMessage(value,proto.platform.Profile.deserializeBinaryFromReader);
      msg.setProfile(value);
      break;
    case 12:
      var value = new proto.platform.QueueEntry1;
      reader.readMessage(value,proto.platform.QueueEntry1.deserializeBinaryFromReader);
      msg.setQueueEntry(value);
      break;
    case 13:
      var value = new proto.platform.Notification;
      reader.readMessage(value,proto.platform.Notification.deserializeBinaryFromReader);
      msg.setNotification(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setJwtkey(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUpdateProfile(value);
      break;
    case 16:
      var value = new proto.platform.Badge;
      reader.readMessage(value,proto.platform.Badge.deserializeBinaryFromReader);
      msg.setBadge(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setWriteupOriginalFilename(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.platform.Transaction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.platform.Transaction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.platform.Transaction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.Transaction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimestamp();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getTeam();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTask();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPlatformConfig();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.platform.PlatformConfiguration.serializeBinaryToWriter
    );
  }
  f = message.getTasksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.platform.Task.serializeBinaryToWriter
    );
  }
  f = message.getDeleted();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getFlagHash();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getKothdataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.platform.KothTickData.serializeBinaryToWriter
    );
  }
  f = message.getProfile();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.platform.Profile.serializeBinaryToWriter
    );
  }
  f = message.getQueueEntry();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.platform.QueueEntry1.serializeBinaryToWriter
    );
  }
  f = message.getNotification();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.platform.Notification.serializeBinaryToWriter
    );
  }
  f = message.getJwtkey();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getUpdateProfile();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getBadge();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.platform.Badge.serializeBinaryToWriter
    );
  }
  f = message.getWriteupOriginalFilename();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.platform.Transaction.Type = {
  INVALID: 0,
  WRITEUP: 2,
  PLATFORM_CONFIG: 4,
  TASKS: 5,
  TEAM_ASSIGNMENTS: 6,
  CREATE_PROFILE: 7,
  FLAG_SUBMIT: 8,
  KOTH_TICK: 9,
  UPDATE_PROFILE: 10,
  CONTEST_STATE_UPDATE: 11,
  QUEUE_REQUEST: 12,
  NOTIFICATION: 13,
  INITIALIZE_JWT: 14,
  BADGE: 15
};

/**
 * optional google.protobuf.Timestamp timestamp = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.platform.Transaction.prototype.getTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.platform.Transaction} returns this
*/
proto.platform.Transaction.prototype.setTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.platform.Transaction} returns this
 */
proto.platform.Transaction.prototype.clearTimestamp = function() {
  return this.setTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.platform.Transaction.prototype.hasTimestamp = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Type type = 3;
 * @return {!proto.platform.Transaction.Type}
 */
proto.platform.Transaction.prototype.getType = function() {
  return /** @type {!proto.platform.Transaction.Type} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.platform.Transaction.Type} value
 * @return {!proto.platform.Transaction} returns this
 */
proto.platform.Transaction.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string team = 4;
 * @return {string}
 */
proto.platform.Transaction.prototype.getTeam = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.Transaction} returns this
 */
proto.platform.Transaction.prototype.setTeam = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string task = 5;
 * @return {string}
 */
proto.platform.Transaction.prototype.getTask = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.Transaction} returns this
 */
proto.platform.Transaction.prototype.setTask = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional PlatformConfiguration platform_config = 6;
 * @return {?proto.platform.PlatformConfiguration}
 */
proto.platform.Transaction.prototype.getPlatformConfig = function() {
  return /** @type{?proto.platform.PlatformConfiguration} */ (
    jspb.Message.getWrapperField(this, proto.platform.PlatformConfiguration, 6));
};


/**
 * @param {?proto.platform.PlatformConfiguration|undefined} value
 * @return {!proto.platform.Transaction} returns this
*/
proto.platform.Transaction.prototype.setPlatformConfig = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.platform.Transaction} returns this
 */
proto.platform.Transaction.prototype.clearPlatformConfig = function() {
  return this.setPlatformConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.platform.Transaction.prototype.hasPlatformConfig = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated Task tasks = 7;
 * @return {!Array<!proto.platform.Task>}
 */
proto.platform.Transaction.prototype.getTasksList = function() {
  return /** @type{!Array<!proto.platform.Task>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.platform.Task, 7));
};


/**
 * @param {!Array<!proto.platform.Task>} value
 * @return {!proto.platform.Transaction} returns this
*/
proto.platform.Transaction.prototype.setTasksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.platform.Task=} opt_value
 * @param {number=} opt_index
 * @return {!proto.platform.Task}
 */
proto.platform.Transaction.prototype.addTasks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.platform.Task, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.platform.Transaction} returns this
 */
proto.platform.Transaction.prototype.clearTasksList = function() {
  return this.setTasksList([]);
};


/**
 * optional bool deleted = 8;
 * @return {boolean}
 */
proto.platform.Transaction.prototype.getDeleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.platform.Transaction} returns this
 */
proto.platform.Transaction.prototype.setDeleted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional string flag_hash = 9;
 * @return {string}
 */
proto.platform.Transaction.prototype.getFlagHash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.Transaction} returns this
 */
proto.platform.Transaction.prototype.setFlagHash = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * repeated KothTickData kothData = 10;
 * @return {!Array<!proto.platform.KothTickData>}
 */
proto.platform.Transaction.prototype.getKothdataList = function() {
  return /** @type{!Array<!proto.platform.KothTickData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.platform.KothTickData, 10));
};


/**
 * @param {!Array<!proto.platform.KothTickData>} value
 * @return {!proto.platform.Transaction} returns this
*/
proto.platform.Transaction.prototype.setKothdataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.platform.KothTickData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.platform.KothTickData}
 */
proto.platform.Transaction.prototype.addKothdata = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.platform.KothTickData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.platform.Transaction} returns this
 */
proto.platform.Transaction.prototype.clearKothdataList = function() {
  return this.setKothdataList([]);
};


/**
 * optional Profile profile = 11;
 * @return {?proto.platform.Profile}
 */
proto.platform.Transaction.prototype.getProfile = function() {
  return /** @type{?proto.platform.Profile} */ (
    jspb.Message.getWrapperField(this, proto.platform.Profile, 11));
};


/**
 * @param {?proto.platform.Profile|undefined} value
 * @return {!proto.platform.Transaction} returns this
*/
proto.platform.Transaction.prototype.setProfile = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.platform.Transaction} returns this
 */
proto.platform.Transaction.prototype.clearProfile = function() {
  return this.setProfile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.platform.Transaction.prototype.hasProfile = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional QueueEntry1 queue_entry = 12;
 * @return {?proto.platform.QueueEntry1}
 */
proto.platform.Transaction.prototype.getQueueEntry = function() {
  return /** @type{?proto.platform.QueueEntry1} */ (
    jspb.Message.getWrapperField(this, proto.platform.QueueEntry1, 12));
};


/**
 * @param {?proto.platform.QueueEntry1|undefined} value
 * @return {!proto.platform.Transaction} returns this
*/
proto.platform.Transaction.prototype.setQueueEntry = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.platform.Transaction} returns this
 */
proto.platform.Transaction.prototype.clearQueueEntry = function() {
  return this.setQueueEntry(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.platform.Transaction.prototype.hasQueueEntry = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional Notification notification = 13;
 * @return {?proto.platform.Notification}
 */
proto.platform.Transaction.prototype.getNotification = function() {
  return /** @type{?proto.platform.Notification} */ (
    jspb.Message.getWrapperField(this, proto.platform.Notification, 13));
};


/**
 * @param {?proto.platform.Notification|undefined} value
 * @return {!proto.platform.Transaction} returns this
*/
proto.platform.Transaction.prototype.setNotification = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.platform.Transaction} returns this
 */
proto.platform.Transaction.prototype.clearNotification = function() {
  return this.setNotification(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.platform.Transaction.prototype.hasNotification = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional string jwtkey = 14;
 * @return {string}
 */
proto.platform.Transaction.prototype.getJwtkey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.Transaction} returns this
 */
proto.platform.Transaction.prototype.setJwtkey = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional bool update_profile = 15;
 * @return {boolean}
 */
proto.platform.Transaction.prototype.getUpdateProfile = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.platform.Transaction} returns this
 */
proto.platform.Transaction.prototype.setUpdateProfile = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional Badge badge = 16;
 * @return {?proto.platform.Badge}
 */
proto.platform.Transaction.prototype.getBadge = function() {
  return /** @type{?proto.platform.Badge} */ (
    jspb.Message.getWrapperField(this, proto.platform.Badge, 16));
};


/**
 * @param {?proto.platform.Badge|undefined} value
 * @return {!proto.platform.Transaction} returns this
*/
proto.platform.Transaction.prototype.setBadge = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.platform.Transaction} returns this
 */
proto.platform.Transaction.prototype.clearBadge = function() {
  return this.setBadge(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.platform.Transaction.prototype.hasBadge = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional string writeup_original_filename = 17;
 * @return {string}
 */
proto.platform.Transaction.prototype.getWriteupOriginalFilename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.Transaction} returns this
 */
proto.platform.Transaction.prototype.setWriteupOriginalFilename = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.platform.Standing.prototype.toObject = function(opt_includeInstance) {
  return proto.platform.Standing.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.platform.Standing} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.Standing.toObject = function(includeInstance, msg) {
  var f, obj = {
    teamId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    score: jspb.Message.getFieldWithDefault(msg, 2, 0),
    taskStatsMap: (f = msg.getTaskStatsMap()) ? f.toObject(includeInstance, proto.platform.Standing.TaskStat.toObject) : [],
    pos: jspb.Message.getFieldWithDefault(msg, 4, 0),
    lastaccept: jspb.Message.getFieldWithDefault(msg, 5, 0),
    lastacceptts: (f = msg.getLastacceptts()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.platform.Standing}
 */
proto.platform.Standing.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.platform.Standing;
  return proto.platform.Standing.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.platform.Standing} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.platform.Standing}
 */
proto.platform.Standing.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTeamId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setScore(value);
      break;
    case 3:
      var value = msg.getTaskStatsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.platform.Standing.TaskStat.deserializeBinaryFromReader, "", new proto.platform.Standing.TaskStat());
         });
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPos(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLastaccept(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastacceptts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.platform.Standing.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.platform.Standing.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.platform.Standing} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.Standing.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTeamId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getScore();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTaskStatsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(3, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.platform.Standing.TaskStat.serializeBinaryToWriter);
  }
  f = message.getPos();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getLastaccept();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getLastacceptts();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.platform.Standing.TaskStat.prototype.toObject = function(opt_includeInstance) {
  return proto.platform.Standing.TaskStat.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.platform.Standing.TaskStat} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.Standing.TaskStat.toObject = function(includeInstance, msg) {
  var f, obj = {
    points: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pointsatsolve: jspb.Message.getFieldWithDefault(msg, 2, 0),
    time: jspb.Message.getFieldWithDefault(msg, 3, 0),
    height: (f = msg.getHeight()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.platform.Standing.TaskStat}
 */
proto.platform.Standing.TaskStat.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.platform.Standing.TaskStat;
  return proto.platform.Standing.TaskStat.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.platform.Standing.TaskStat} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.platform.Standing.TaskStat}
 */
proto.platform.Standing.TaskStat.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPoints(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPointsatsolve(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTime(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setHeight(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.platform.Standing.TaskStat.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.platform.Standing.TaskStat.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.platform.Standing.TaskStat} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.Standing.TaskStat.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPoints();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPointsatsolve();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTime();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getHeight();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 points = 1;
 * @return {number}
 */
proto.platform.Standing.TaskStat.prototype.getPoints = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.platform.Standing.TaskStat} returns this
 */
proto.platform.Standing.TaskStat.prototype.setPoints = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 pointsAtSolve = 2;
 * @return {number}
 */
proto.platform.Standing.TaskStat.prototype.getPointsatsolve = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.platform.Standing.TaskStat} returns this
 */
proto.platform.Standing.TaskStat.prototype.setPointsatsolve = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 time = 3;
 * @return {number}
 */
proto.platform.Standing.TaskStat.prototype.getTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.platform.Standing.TaskStat} returns this
 */
proto.platform.Standing.TaskStat.prototype.setTime = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp height = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.platform.Standing.TaskStat.prototype.getHeight = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.platform.Standing.TaskStat} returns this
*/
proto.platform.Standing.TaskStat.prototype.setHeight = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.platform.Standing.TaskStat} returns this
 */
proto.platform.Standing.TaskStat.prototype.clearHeight = function() {
  return this.setHeight(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.platform.Standing.TaskStat.prototype.hasHeight = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string team_id = 1;
 * @return {string}
 */
proto.platform.Standing.prototype.getTeamId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.Standing} returns this
 */
proto.platform.Standing.prototype.setTeamId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 score = 2;
 * @return {number}
 */
proto.platform.Standing.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.platform.Standing} returns this
 */
proto.platform.Standing.prototype.setScore = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * map<string, TaskStat> task_stats = 3;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.platform.Standing.TaskStat>}
 */
proto.platform.Standing.prototype.getTaskStatsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.platform.Standing.TaskStat>} */ (
      jspb.Message.getMapField(this, 3, opt_noLazyCreate,
      proto.platform.Standing.TaskStat));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.platform.Standing} returns this
 */
proto.platform.Standing.prototype.clearTaskStatsMap = function() {
  this.getTaskStatsMap().clear();
  return this;
};


/**
 * optional int32 pos = 4;
 * @return {number}
 */
proto.platform.Standing.prototype.getPos = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.platform.Standing} returns this
 */
proto.platform.Standing.prototype.setPos = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 lastAccept = 5;
 * @return {number}
 */
proto.platform.Standing.prototype.getLastaccept = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.platform.Standing} returns this
 */
proto.platform.Standing.prototype.setLastaccept = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional google.protobuf.Timestamp lastAcceptTS = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.platform.Standing.prototype.getLastacceptts = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.platform.Standing} returns this
*/
proto.platform.Standing.prototype.setLastacceptts = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.platform.Standing} returns this
 */
proto.platform.Standing.prototype.clearLastacceptts = function() {
  return this.setLastacceptts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.platform.Standing.prototype.hasLastacceptts = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.platform.QueueEntry1.prototype.toObject = function(opt_includeInstance) {
  return proto.platform.QueueEntry1.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.platform.QueueEntry1} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.QueueEntry1.toObject = function(includeInstance, msg) {
  var f, obj = {
    team: jspb.Message.getFieldWithDefault(msg, 1, ""),
    state: jspb.Message.getFieldWithDefault(msg, 2, 0),
    endtime: (f = msg.getEndtime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    additionalInformation: jspb.Message.getFieldWithDefault(msg, 4, ""),
    id: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.platform.QueueEntry1}
 */
proto.platform.QueueEntry1.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.platform.QueueEntry1;
  return proto.platform.QueueEntry1.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.platform.QueueEntry1} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.platform.QueueEntry1}
 */
proto.platform.QueueEntry1.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTeam(value);
      break;
    case 2:
      var value = /** @type {!proto.platform.QueueState} */ (reader.readEnum());
      msg.setState(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndtime(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdditionalInformation(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.platform.QueueEntry1.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.platform.QueueEntry1.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.platform.QueueEntry1} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.QueueEntry1.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTeam();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getEndtime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getAdditionalInformation();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string team = 1;
 * @return {string}
 */
proto.platform.QueueEntry1.prototype.getTeam = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.QueueEntry1} returns this
 */
proto.platform.QueueEntry1.prototype.setTeam = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional QueueState state = 2;
 * @return {!proto.platform.QueueState}
 */
proto.platform.QueueEntry1.prototype.getState = function() {
  return /** @type {!proto.platform.QueueState} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.platform.QueueState} value
 * @return {!proto.platform.QueueEntry1} returns this
 */
proto.platform.QueueEntry1.prototype.setState = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp endtime = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.platform.QueueEntry1.prototype.getEndtime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.platform.QueueEntry1} returns this
*/
proto.platform.QueueEntry1.prototype.setEndtime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.platform.QueueEntry1} returns this
 */
proto.platform.QueueEntry1.prototype.clearEndtime = function() {
  return this.setEndtime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.platform.QueueEntry1.prototype.hasEndtime = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string additional_information = 4;
 * @return {string}
 */
proto.platform.QueueEntry1.prototype.getAdditionalInformation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.QueueEntry1} returns this
 */
proto.platform.QueueEntry1.prototype.setAdditionalInformation = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string id = 5;
 * @return {string}
 */
proto.platform.QueueEntry1.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.QueueEntry1} returns this
 */
proto.platform.QueueEntry1.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.platform.TaskState.repeatedFields_ = [2,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.platform.TaskState.prototype.toObject = function(opt_includeInstance) {
  return proto.platform.TaskState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.platform.TaskState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.TaskState.toObject = function(includeInstance, msg) {
  var f, obj = {
    points: jspb.Message.getFieldWithDefault(msg, 1, 0),
    solvedByList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    firstBlood: jspb.Message.getFieldWithDefault(msg, 3, ""),
    writeupByList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    pointsKothMap: (f = msg.getPointsKothMap()) ? f.toObject(includeInstance, undefined) : [],
    ticksLeft: jspb.Message.getFieldWithDefault(msg, 6, 0),
    queueMap: (f = msg.getQueueMap()) ? f.toObject(includeInstance, proto.platform.QueueEntry1.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.platform.TaskState}
 */
proto.platform.TaskState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.platform.TaskState;
  return proto.platform.TaskState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.platform.TaskState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.platform.TaskState}
 */
proto.platform.TaskState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPoints(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addSolvedBy(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstBlood(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addWriteupBy(value);
      break;
    case 5:
      var value = msg.getPointsKothMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readFloat, null, "", 0.0);
         });
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTicksLeft(value);
      break;
    case 7:
      var value = msg.getQueueMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.platform.QueueEntry1.deserializeBinaryFromReader, "", new proto.platform.QueueEntry1());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.platform.TaskState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.platform.TaskState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.platform.TaskState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.TaskState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPoints();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getSolvedByList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getFirstBlood();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getWriteupByList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getPointsKothMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(5, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeFloat);
  }
  f = message.getTicksLeft();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getQueueMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(7, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.platform.QueueEntry1.serializeBinaryToWriter);
  }
};


/**
 * optional int32 points = 1;
 * @return {number}
 */
proto.platform.TaskState.prototype.getPoints = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.platform.TaskState} returns this
 */
proto.platform.TaskState.prototype.setPoints = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated string solved_by = 2;
 * @return {!Array<string>}
 */
proto.platform.TaskState.prototype.getSolvedByList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.platform.TaskState} returns this
 */
proto.platform.TaskState.prototype.setSolvedByList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.platform.TaskState} returns this
 */
proto.platform.TaskState.prototype.addSolvedBy = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.platform.TaskState} returns this
 */
proto.platform.TaskState.prototype.clearSolvedByList = function() {
  return this.setSolvedByList([]);
};


/**
 * optional string first_blood = 3;
 * @return {string}
 */
proto.platform.TaskState.prototype.getFirstBlood = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.TaskState} returns this
 */
proto.platform.TaskState.prototype.setFirstBlood = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string writeup_by = 4;
 * @return {!Array<string>}
 */
proto.platform.TaskState.prototype.getWriteupByList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.platform.TaskState} returns this
 */
proto.platform.TaskState.prototype.setWriteupByList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.platform.TaskState} returns this
 */
proto.platform.TaskState.prototype.addWriteupBy = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.platform.TaskState} returns this
 */
proto.platform.TaskState.prototype.clearWriteupByList = function() {
  return this.setWriteupByList([]);
};


/**
 * map<string, float> points_koth = 5;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,number>}
 */
proto.platform.TaskState.prototype.getPointsKothMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,number>} */ (
      jspb.Message.getMapField(this, 5, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.platform.TaskState} returns this
 */
proto.platform.TaskState.prototype.clearPointsKothMap = function() {
  this.getPointsKothMap().clear();
  return this;
};


/**
 * optional int32 ticks_left = 6;
 * @return {number}
 */
proto.platform.TaskState.prototype.getTicksLeft = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.platform.TaskState} returns this
 */
proto.platform.TaskState.prototype.setTicksLeft = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * map<string, QueueEntry1> queue = 7;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.platform.QueueEntry1>}
 */
proto.platform.TaskState.prototype.getQueueMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.platform.QueueEntry1>} */ (
      jspb.Message.getMapField(this, 7, opt_noLazyCreate,
      proto.platform.QueueEntry1));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.platform.TaskState} returns this
 */
proto.platform.TaskState.prototype.clearQueueMap = function() {
  this.getQueueMap().clear();
  return this;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.platform.Task.repeatedFields_ = [2,4,8,9,18,19,22];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.platform.Task.prototype.toObject = function(opt_includeInstance) {
  return proto.platform.Task.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.platform.Task} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.Task.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    categoriesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    difficulty: jspb.Message.getFieldWithDefault(msg, 3, ""),
    authorsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    description: jspb.Message.getFieldWithDefault(msg, 5, ""),
    flag: jspb.Message.getFieldWithDefault(msg, 6, ""),
    enabled: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    dependenciesList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    filesList: jspb.Message.toObjectList(msg.getFilesList(),
    proto.platform.Task.File.toObject, includeInstance),
    type: jspb.Message.getFieldWithDefault(msg, 10, 0),
    displayname: jspb.Message.getFieldWithDefault(msg, 11, ""),
    points: jspb.Message.getFieldWithDefault(msg, 12, 0),
    enableWriteups: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    ticks: jspb.Message.getFieldWithDefault(msg, 14, 0),
    broker: jspb.Message.getFieldWithDefault(msg, 15, ""),
    brokerprotocol: jspb.Message.getFieldWithDefault(msg, 16, ""),
    queueable: jspb.Message.getBooleanFieldWithDefault(msg, 17, false),
    verifiedByList: (f = jspb.Message.getRepeatedField(msg, 18)) == null ? undefined : f,
    brokernetList: (f = jspb.Message.getRepeatedField(msg, 19)) == null ? undefined : f,
    deployment: (f = msg.getDeployment()) && proto.platform.Task.Deployment.toObject(includeInstance, f),
    competition: jspb.Message.getFieldWithDefault(msg, 21, ""),
    tagsList: (f = jspb.Message.getRepeatedField(msg, 22)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.platform.Task}
 */
proto.platform.Task.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.platform.Task;
  return proto.platform.Task.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.platform.Task} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.platform.Task}
 */
proto.platform.Task.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addCategories(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDifficulty(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addAuthors(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlag(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnabled(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.addDependencies(value);
      break;
    case 9:
      var value = new proto.platform.Task.File;
      reader.readMessage(value,proto.platform.Task.File.deserializeBinaryFromReader);
      msg.addFiles(value);
      break;
    case 10:
      var value = /** @type {!proto.platform.TaskType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayname(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPoints(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableWriteups(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTicks(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setBroker(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setBrokerprotocol(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setQueueable(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.addVerifiedBy(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.addBrokernet(value);
      break;
    case 20:
      var value = new proto.platform.Task.Deployment;
      reader.readMessage(value,proto.platform.Task.Deployment.deserializeBinaryFromReader);
      msg.setDeployment(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompetition(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.addTags(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.platform.Task.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.platform.Task.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.platform.Task} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.Task.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCategoriesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getDifficulty();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAuthorsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getFlag();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getEnabled();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getDependenciesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      8,
      f
    );
  }
  f = message.getFilesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.platform.Task.File.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getDisplayname();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getPoints();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getEnableWriteups();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getTicks();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getBroker();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getBrokerprotocol();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getQueueable();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
  f = message.getVerifiedByList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      18,
      f
    );
  }
  f = message.getBrokernetList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      19,
      f
    );
  }
  f = message.getDeployment();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto.platform.Task.Deployment.serializeBinaryToWriter
    );
  }
  f = message.getCompetition();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      22,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.platform.Task.File.prototype.toObject = function(opt_includeInstance) {
  return proto.platform.Task.File.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.platform.Task.File} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.Task.File.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sha256sum: jspb.Message.getFieldWithDefault(msg, 2, ""),
    notingit: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.platform.Task.File}
 */
proto.platform.Task.File.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.platform.Task.File;
  return proto.platform.Task.File.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.platform.Task.File} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.platform.Task.File}
 */
proto.platform.Task.File.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSha256sum(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNotingit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.platform.Task.File.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.platform.Task.File.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.platform.Task.File} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.Task.File.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSha256sum();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNotingit();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.platform.Task.File.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.Task.File} returns this
 */
proto.platform.Task.File.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sha256sum = 2;
 * @return {string}
 */
proto.platform.Task.File.prototype.getSha256sum = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.Task.File} returns this
 */
proto.platform.Task.File.prototype.setSha256sum = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool notInGit = 3;
 * @return {boolean}
 */
proto.platform.Task.File.prototype.getNotingit = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.platform.Task.File} returns this
 */
proto.platform.Task.File.prototype.setNotingit = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.platform.Task.Deployment.repeatedFields_ = [1,2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.platform.Task.Deployment.prototype.toObject = function(opt_includeInstance) {
  return proto.platform.Task.Deployment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.platform.Task.Deployment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.Task.Deployment.toObject = function(includeInstance, msg) {
  var f, obj = {
    containersList: jspb.Message.toObjectList(msg.getContainersList(),
    proto.platform.Task.Deployment.Container.toObject, includeInstance),
    portsList: jspb.Message.toObjectList(msg.getPortsList(),
    proto.platform.Task.Deployment.IngressPort.toObject, includeInstance),
    networkpoliciesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.platform.Task.Deployment}
 */
proto.platform.Task.Deployment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.platform.Task.Deployment;
  return proto.platform.Task.Deployment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.platform.Task.Deployment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.platform.Task.Deployment}
 */
proto.platform.Task.Deployment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.platform.Task.Deployment.Container;
      reader.readMessage(value,proto.platform.Task.Deployment.Container.deserializeBinaryFromReader);
      msg.addContainers(value);
      break;
    case 2:
      var value = new proto.platform.Task.Deployment.IngressPort;
      reader.readMessage(value,proto.platform.Task.Deployment.IngressPort.deserializeBinaryFromReader);
      msg.addPorts(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addNetworkpolicies(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.platform.Task.Deployment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.platform.Task.Deployment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.platform.Task.Deployment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.Task.Deployment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContainersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.platform.Task.Deployment.Container.serializeBinaryToWriter
    );
  }
  f = message.getPortsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.platform.Task.Deployment.IngressPort.serializeBinaryToWriter
    );
  }
  f = message.getNetworkpoliciesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.platform.Task.Deployment.Container.prototype.toObject = function(opt_includeInstance) {
  return proto.platform.Task.Deployment.Container.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.platform.Task.Deployment.Container} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.Task.Deployment.Container.toObject = function(includeInstance, msg) {
  var f, obj = {
    image: jspb.Message.getFieldWithDefault(msg, 1, ""),
    envMap: (f = msg.getEnvMap()) ? f.toObject(includeInstance, undefined) : [],
    build: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    buildroot: jspb.Message.getFieldWithDefault(msg, 4, ""),
    dockerfile: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.platform.Task.Deployment.Container}
 */
proto.platform.Task.Deployment.Container.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.platform.Task.Deployment.Container;
  return proto.platform.Task.Deployment.Container.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.platform.Task.Deployment.Container} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.platform.Task.Deployment.Container}
 */
proto.platform.Task.Deployment.Container.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setImage(value);
      break;
    case 2:
      var value = msg.getEnvMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBuild(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBuildroot(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDockerfile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.platform.Task.Deployment.Container.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.platform.Task.Deployment.Container.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.platform.Task.Deployment.Container} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.Task.Deployment.Container.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getImage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEnvMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getBuild();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getBuildroot();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDockerfile();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string image = 1;
 * @return {string}
 */
proto.platform.Task.Deployment.Container.prototype.getImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.Task.Deployment.Container} returns this
 */
proto.platform.Task.Deployment.Container.prototype.setImage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * map<string, string> env = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.platform.Task.Deployment.Container.prototype.getEnvMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.platform.Task.Deployment.Container} returns this
 */
proto.platform.Task.Deployment.Container.prototype.clearEnvMap = function() {
  this.getEnvMap().clear();
  return this;
};


/**
 * optional bool build = 3;
 * @return {boolean}
 */
proto.platform.Task.Deployment.Container.prototype.getBuild = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.platform.Task.Deployment.Container} returns this
 */
proto.platform.Task.Deployment.Container.prototype.setBuild = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string buildRoot = 4;
 * @return {string}
 */
proto.platform.Task.Deployment.Container.prototype.getBuildroot = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.Task.Deployment.Container} returns this
 */
proto.platform.Task.Deployment.Container.prototype.setBuildroot = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string dockerfile = 5;
 * @return {string}
 */
proto.platform.Task.Deployment.Container.prototype.getDockerfile = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.Task.Deployment.Container} returns this
 */
proto.platform.Task.Deployment.Container.prototype.setDockerfile = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.platform.Task.Deployment.IngressPort.prototype.toObject = function(opt_includeInstance) {
  return proto.platform.Task.Deployment.IngressPort.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.platform.Task.Deployment.IngressPort} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.Task.Deployment.IngressPort.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    port: jspb.Message.getFieldWithDefault(msg, 3, 0),
    displaystyle: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.platform.Task.Deployment.IngressPort}
 */
proto.platform.Task.Deployment.IngressPort.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.platform.Task.Deployment.IngressPort;
  return proto.platform.Task.Deployment.IngressPort.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.platform.Task.Deployment.IngressPort} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.platform.Task.Deployment.IngressPort}
 */
proto.platform.Task.Deployment.IngressPort.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPort(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplaystyle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.platform.Task.Deployment.IngressPort.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.platform.Task.Deployment.IngressPort.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.platform.Task.Deployment.IngressPort} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.Task.Deployment.IngressPort.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPort();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getDisplaystyle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string type = 1;
 * @return {string}
 */
proto.platform.Task.Deployment.IngressPort.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.Task.Deployment.IngressPort} returns this
 */
proto.platform.Task.Deployment.IngressPort.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.platform.Task.Deployment.IngressPort.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.Task.Deployment.IngressPort} returns this
 */
proto.platform.Task.Deployment.IngressPort.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint64 port = 3;
 * @return {number}
 */
proto.platform.Task.Deployment.IngressPort.prototype.getPort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.platform.Task.Deployment.IngressPort} returns this
 */
proto.platform.Task.Deployment.IngressPort.prototype.setPort = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string displayStyle = 4;
 * @return {string}
 */
proto.platform.Task.Deployment.IngressPort.prototype.getDisplaystyle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.Task.Deployment.IngressPort} returns this
 */
proto.platform.Task.Deployment.IngressPort.prototype.setDisplaystyle = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated Container containers = 1;
 * @return {!Array<!proto.platform.Task.Deployment.Container>}
 */
proto.platform.Task.Deployment.prototype.getContainersList = function() {
  return /** @type{!Array<!proto.platform.Task.Deployment.Container>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.platform.Task.Deployment.Container, 1));
};


/**
 * @param {!Array<!proto.platform.Task.Deployment.Container>} value
 * @return {!proto.platform.Task.Deployment} returns this
*/
proto.platform.Task.Deployment.prototype.setContainersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.platform.Task.Deployment.Container=} opt_value
 * @param {number=} opt_index
 * @return {!proto.platform.Task.Deployment.Container}
 */
proto.platform.Task.Deployment.prototype.addContainers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.platform.Task.Deployment.Container, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.platform.Task.Deployment} returns this
 */
proto.platform.Task.Deployment.prototype.clearContainersList = function() {
  return this.setContainersList([]);
};


/**
 * repeated IngressPort ports = 2;
 * @return {!Array<!proto.platform.Task.Deployment.IngressPort>}
 */
proto.platform.Task.Deployment.prototype.getPortsList = function() {
  return /** @type{!Array<!proto.platform.Task.Deployment.IngressPort>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.platform.Task.Deployment.IngressPort, 2));
};


/**
 * @param {!Array<!proto.platform.Task.Deployment.IngressPort>} value
 * @return {!proto.platform.Task.Deployment} returns this
*/
proto.platform.Task.Deployment.prototype.setPortsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.platform.Task.Deployment.IngressPort=} opt_value
 * @param {number=} opt_index
 * @return {!proto.platform.Task.Deployment.IngressPort}
 */
proto.platform.Task.Deployment.prototype.addPorts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.platform.Task.Deployment.IngressPort, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.platform.Task.Deployment} returns this
 */
proto.platform.Task.Deployment.prototype.clearPortsList = function() {
  return this.setPortsList([]);
};


/**
 * repeated string networkPolicies = 3;
 * @return {!Array<string>}
 */
proto.platform.Task.Deployment.prototype.getNetworkpoliciesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.platform.Task.Deployment} returns this
 */
proto.platform.Task.Deployment.prototype.setNetworkpoliciesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.platform.Task.Deployment} returns this
 */
proto.platform.Task.Deployment.prototype.addNetworkpolicies = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.platform.Task.Deployment} returns this
 */
proto.platform.Task.Deployment.prototype.clearNetworkpoliciesList = function() {
  return this.setNetworkpoliciesList([]);
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.platform.Task.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.Task} returns this
 */
proto.platform.Task.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string categories = 2;
 * @return {!Array<string>}
 */
proto.platform.Task.prototype.getCategoriesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.platform.Task} returns this
 */
proto.platform.Task.prototype.setCategoriesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.platform.Task} returns this
 */
proto.platform.Task.prototype.addCategories = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.platform.Task} returns this
 */
proto.platform.Task.prototype.clearCategoriesList = function() {
  return this.setCategoriesList([]);
};


/**
 * optional string difficulty = 3;
 * @return {string}
 */
proto.platform.Task.prototype.getDifficulty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.Task} returns this
 */
proto.platform.Task.prototype.setDifficulty = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string authors = 4;
 * @return {!Array<string>}
 */
proto.platform.Task.prototype.getAuthorsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.platform.Task} returns this
 */
proto.platform.Task.prototype.setAuthorsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.platform.Task} returns this
 */
proto.platform.Task.prototype.addAuthors = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.platform.Task} returns this
 */
proto.platform.Task.prototype.clearAuthorsList = function() {
  return this.setAuthorsList([]);
};


/**
 * optional string description = 5;
 * @return {string}
 */
proto.platform.Task.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.Task} returns this
 */
proto.platform.Task.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string flag = 6;
 * @return {string}
 */
proto.platform.Task.prototype.getFlag = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.Task} returns this
 */
proto.platform.Task.prototype.setFlag = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool enabled = 7;
 * @return {boolean}
 */
proto.platform.Task.prototype.getEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.platform.Task} returns this
 */
proto.platform.Task.prototype.setEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * repeated string dependencies = 8;
 * @return {!Array<string>}
 */
proto.platform.Task.prototype.getDependenciesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.platform.Task} returns this
 */
proto.platform.Task.prototype.setDependenciesList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.platform.Task} returns this
 */
proto.platform.Task.prototype.addDependencies = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.platform.Task} returns this
 */
proto.platform.Task.prototype.clearDependenciesList = function() {
  return this.setDependenciesList([]);
};


/**
 * repeated File files = 9;
 * @return {!Array<!proto.platform.Task.File>}
 */
proto.platform.Task.prototype.getFilesList = function() {
  return /** @type{!Array<!proto.platform.Task.File>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.platform.Task.File, 9));
};


/**
 * @param {!Array<!proto.platform.Task.File>} value
 * @return {!proto.platform.Task} returns this
*/
proto.platform.Task.prototype.setFilesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.platform.Task.File=} opt_value
 * @param {number=} opt_index
 * @return {!proto.platform.Task.File}
 */
proto.platform.Task.prototype.addFiles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.platform.Task.File, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.platform.Task} returns this
 */
proto.platform.Task.prototype.clearFilesList = function() {
  return this.setFilesList([]);
};


/**
 * optional TaskType type = 10;
 * @return {!proto.platform.TaskType}
 */
proto.platform.Task.prototype.getType = function() {
  return /** @type {!proto.platform.TaskType} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.platform.TaskType} value
 * @return {!proto.platform.Task} returns this
 */
proto.platform.Task.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional string displayName = 11;
 * @return {string}
 */
proto.platform.Task.prototype.getDisplayname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.Task} returns this
 */
proto.platform.Task.prototype.setDisplayname = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional int32 points = 12;
 * @return {number}
 */
proto.platform.Task.prototype.getPoints = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.platform.Task} returns this
 */
proto.platform.Task.prototype.setPoints = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional bool enable_writeups = 13;
 * @return {boolean}
 */
proto.platform.Task.prototype.getEnableWriteups = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.platform.Task} returns this
 */
proto.platform.Task.prototype.setEnableWriteups = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional int32 ticks = 14;
 * @return {number}
 */
proto.platform.Task.prototype.getTicks = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.platform.Task} returns this
 */
proto.platform.Task.prototype.setTicks = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional string broker = 15;
 * @return {string}
 */
proto.platform.Task.prototype.getBroker = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.Task} returns this
 */
proto.platform.Task.prototype.setBroker = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string brokerProtocol = 16;
 * @return {string}
 */
proto.platform.Task.prototype.getBrokerprotocol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.Task} returns this
 */
proto.platform.Task.prototype.setBrokerprotocol = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional bool queueable = 17;
 * @return {boolean}
 */
proto.platform.Task.prototype.getQueueable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
};


/**
 * @param {boolean} value
 * @return {!proto.platform.Task} returns this
 */
proto.platform.Task.prototype.setQueueable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 17, value);
};


/**
 * repeated string verified_by = 18;
 * @return {!Array<string>}
 */
proto.platform.Task.prototype.getVerifiedByList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 18));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.platform.Task} returns this
 */
proto.platform.Task.prototype.setVerifiedByList = function(value) {
  return jspb.Message.setField(this, 18, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.platform.Task} returns this
 */
proto.platform.Task.prototype.addVerifiedBy = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 18, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.platform.Task} returns this
 */
proto.platform.Task.prototype.clearVerifiedByList = function() {
  return this.setVerifiedByList([]);
};


/**
 * repeated string brokerNet = 19;
 * @return {!Array<string>}
 */
proto.platform.Task.prototype.getBrokernetList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 19));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.platform.Task} returns this
 */
proto.platform.Task.prototype.setBrokernetList = function(value) {
  return jspb.Message.setField(this, 19, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.platform.Task} returns this
 */
proto.platform.Task.prototype.addBrokernet = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 19, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.platform.Task} returns this
 */
proto.platform.Task.prototype.clearBrokernetList = function() {
  return this.setBrokernetList([]);
};


/**
 * optional Deployment deployment = 20;
 * @return {?proto.platform.Task.Deployment}
 */
proto.platform.Task.prototype.getDeployment = function() {
  return /** @type{?proto.platform.Task.Deployment} */ (
    jspb.Message.getWrapperField(this, proto.platform.Task.Deployment, 20));
};


/**
 * @param {?proto.platform.Task.Deployment|undefined} value
 * @return {!proto.platform.Task} returns this
*/
proto.platform.Task.prototype.setDeployment = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.platform.Task} returns this
 */
proto.platform.Task.prototype.clearDeployment = function() {
  return this.setDeployment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.platform.Task.prototype.hasDeployment = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional string competition = 21;
 * @return {string}
 */
proto.platform.Task.prototype.getCompetition = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.Task} returns this
 */
proto.platform.Task.prototype.setCompetition = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * repeated string tags = 22;
 * @return {!Array<string>}
 */
proto.platform.Task.prototype.getTagsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 22));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.platform.Task} returns this
 */
proto.platform.Task.prototype.setTagsList = function(value) {
  return jspb.Message.setField(this, 22, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.platform.Task} returns this
 */
proto.platform.Task.prototype.addTags = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 22, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.platform.Task} returns this
 */
proto.platform.Task.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.platform.State.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.platform.State.prototype.toObject = function(opt_includeInstance) {
  return proto.platform.State.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.platform.State} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.State.toObject = function(includeInstance, msg) {
  var f, obj = {
    height: (f = msg.getHeight()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    standingsList: jspb.Message.toObjectList(msg.getStandingsList(),
    proto.platform.Standing.toObject, includeInstance),
    taskStatesMap: (f = msg.getTaskStatesMap()) ? f.toObject(includeInstance, proto.platform.TaskState.toObject) : [],
    contest: (f = msg.getContest()) && proto.platform.Contest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.platform.State}
 */
proto.platform.State.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.platform.State;
  return proto.platform.State.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.platform.State} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.platform.State}
 */
proto.platform.State.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setHeight(value);
      break;
    case 2:
      var value = new proto.platform.Standing;
      reader.readMessage(value,proto.platform.Standing.deserializeBinaryFromReader);
      msg.addStandings(value);
      break;
    case 3:
      var value = msg.getTaskStatesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.platform.TaskState.deserializeBinaryFromReader, "", new proto.platform.TaskState());
         });
      break;
    case 4:
      var value = new proto.platform.Contest;
      reader.readMessage(value,proto.platform.Contest.deserializeBinaryFromReader);
      msg.setContest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.platform.State.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.platform.State.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.platform.State} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.State.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeight();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getStandingsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.platform.Standing.serializeBinaryToWriter
    );
  }
  f = message.getTaskStatesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(3, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.platform.TaskState.serializeBinaryToWriter);
  }
  f = message.getContest();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.platform.Contest.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp height = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.platform.State.prototype.getHeight = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.platform.State} returns this
*/
proto.platform.State.prototype.setHeight = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.platform.State} returns this
 */
proto.platform.State.prototype.clearHeight = function() {
  return this.setHeight(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.platform.State.prototype.hasHeight = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Standing standings = 2;
 * @return {!Array<!proto.platform.Standing>}
 */
proto.platform.State.prototype.getStandingsList = function() {
  return /** @type{!Array<!proto.platform.Standing>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.platform.Standing, 2));
};


/**
 * @param {!Array<!proto.platform.Standing>} value
 * @return {!proto.platform.State} returns this
*/
proto.platform.State.prototype.setStandingsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.platform.Standing=} opt_value
 * @param {number=} opt_index
 * @return {!proto.platform.Standing}
 */
proto.platform.State.prototype.addStandings = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.platform.Standing, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.platform.State} returns this
 */
proto.platform.State.prototype.clearStandingsList = function() {
  return this.setStandingsList([]);
};


/**
 * map<string, TaskState> task_states = 3;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.platform.TaskState>}
 */
proto.platform.State.prototype.getTaskStatesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.platform.TaskState>} */ (
      jspb.Message.getMapField(this, 3, opt_noLazyCreate,
      proto.platform.TaskState));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.platform.State} returns this
 */
proto.platform.State.prototype.clearTaskStatesMap = function() {
  this.getTaskStatesMap().clear();
  return this;
};


/**
 * optional Contest contest = 4;
 * @return {?proto.platform.Contest}
 */
proto.platform.State.prototype.getContest = function() {
  return /** @type{?proto.platform.Contest} */ (
    jspb.Message.getWrapperField(this, proto.platform.Contest, 4));
};


/**
 * @param {?proto.platform.Contest|undefined} value
 * @return {!proto.platform.State} returns this
*/
proto.platform.State.prototype.setContest = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.platform.State} returns this
 */
proto.platform.State.prototype.clearContest = function() {
  return this.setContest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.platform.State.prototype.hasContest = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.platform.PlatformConfiguration.prototype.toObject = function(opt_includeInstance) {
  return proto.platform.PlatformConfiguration.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.platform.PlatformConfiguration} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.PlatformConfiguration.toObject = function(includeInstance, msg) {
  var f, obj = {
    dynamicscoring: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    minpoints: jspb.Message.getFieldWithDefault(msg, 2, 0),
    maxpoints: jspb.Message.getFieldWithDefault(msg, 3, 0),
    contest: (f = msg.getContest()) && proto.platform.ContestConfiguration.toObject(includeInstance, f),
    autoteams: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    teamassignmentsMap: (f = msg.getTeamassignmentsMap()) ? f.toObject(includeInstance, undefined) : [],
    teamnamesMap: (f = msg.getTeamnamesMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.platform.PlatformConfiguration}
 */
proto.platform.PlatformConfiguration.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.platform.PlatformConfiguration;
  return proto.platform.PlatformConfiguration.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.platform.PlatformConfiguration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.platform.PlatformConfiguration}
 */
proto.platform.PlatformConfiguration.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDynamicscoring(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMinpoints(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxpoints(value);
      break;
    case 4:
      var value = new proto.platform.ContestConfiguration;
      reader.readMessage(value,proto.platform.ContestConfiguration.deserializeBinaryFromReader);
      msg.setContest(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAutoteams(value);
      break;
    case 6:
      var value = msg.getTeamassignmentsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 7:
      var value = msg.getTeamnamesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.platform.PlatformConfiguration.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.platform.PlatformConfiguration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.platform.PlatformConfiguration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.PlatformConfiguration.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDynamicscoring();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getMinpoints();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getMaxpoints();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getContest();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.platform.ContestConfiguration.serializeBinaryToWriter
    );
  }
  f = message.getAutoteams();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getTeamassignmentsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(6, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getTeamnamesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(7, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * optional bool dynamicScoring = 1;
 * @return {boolean}
 */
proto.platform.PlatformConfiguration.prototype.getDynamicscoring = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.platform.PlatformConfiguration} returns this
 */
proto.platform.PlatformConfiguration.prototype.setDynamicscoring = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional int32 minPoints = 2;
 * @return {number}
 */
proto.platform.PlatformConfiguration.prototype.getMinpoints = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.platform.PlatformConfiguration} returns this
 */
proto.platform.PlatformConfiguration.prototype.setMinpoints = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 maxPoints = 3;
 * @return {number}
 */
proto.platform.PlatformConfiguration.prototype.getMaxpoints = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.platform.PlatformConfiguration} returns this
 */
proto.platform.PlatformConfiguration.prototype.setMaxpoints = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional ContestConfiguration contest = 4;
 * @return {?proto.platform.ContestConfiguration}
 */
proto.platform.PlatformConfiguration.prototype.getContest = function() {
  return /** @type{?proto.platform.ContestConfiguration} */ (
    jspb.Message.getWrapperField(this, proto.platform.ContestConfiguration, 4));
};


/**
 * @param {?proto.platform.ContestConfiguration|undefined} value
 * @return {!proto.platform.PlatformConfiguration} returns this
*/
proto.platform.PlatformConfiguration.prototype.setContest = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.platform.PlatformConfiguration} returns this
 */
proto.platform.PlatformConfiguration.prototype.clearContest = function() {
  return this.setContest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.platform.PlatformConfiguration.prototype.hasContest = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool autoTeams = 5;
 * @return {boolean}
 */
proto.platform.PlatformConfiguration.prototype.getAutoteams = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.platform.PlatformConfiguration} returns this
 */
proto.platform.PlatformConfiguration.prototype.setAutoteams = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * map<string, string> teamAssignments = 6;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.platform.PlatformConfiguration.prototype.getTeamassignmentsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 6, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.platform.PlatformConfiguration} returns this
 */
proto.platform.PlatformConfiguration.prototype.clearTeamassignmentsMap = function() {
  this.getTeamassignmentsMap().clear();
  return this;
};


/**
 * map<string, string> teamNames = 7;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.platform.PlatformConfiguration.prototype.getTeamnamesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 7, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.platform.PlatformConfiguration} returns this
 */
proto.platform.PlatformConfiguration.prototype.clearTeamnamesMap = function() {
  this.getTeamnamesMap().clear();
  return this;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.platform.ScoreboardSnapshot.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.platform.ScoreboardSnapshot.prototype.toObject = function(opt_includeInstance) {
  return proto.platform.ScoreboardSnapshot.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.platform.ScoreboardSnapshot} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.ScoreboardSnapshot.toObject = function(includeInstance, msg) {
  var f, obj = {
    height: (f = msg.getHeight()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    pointsList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.platform.ScoreboardSnapshot}
 */
proto.platform.ScoreboardSnapshot.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.platform.ScoreboardSnapshot;
  return proto.platform.ScoreboardSnapshot.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.platform.ScoreboardSnapshot} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.platform.ScoreboardSnapshot}
 */
proto.platform.ScoreboardSnapshot.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setHeight(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addPoints(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.platform.ScoreboardSnapshot.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.platform.ScoreboardSnapshot.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.platform.ScoreboardSnapshot} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.ScoreboardSnapshot.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeight();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPointsList();
  if (f.length > 0) {
    writer.writePackedDouble(
      2,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp height = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.platform.ScoreboardSnapshot.prototype.getHeight = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.platform.ScoreboardSnapshot} returns this
*/
proto.platform.ScoreboardSnapshot.prototype.setHeight = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.platform.ScoreboardSnapshot} returns this
 */
proto.platform.ScoreboardSnapshot.prototype.clearHeight = function() {
  return this.setHeight(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.platform.ScoreboardSnapshot.prototype.hasHeight = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated double points = 2;
 * @return {!Array<number>}
 */
proto.platform.ScoreboardSnapshot.prototype.getPointsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.platform.ScoreboardSnapshot} returns this
 */
proto.platform.ScoreboardSnapshot.prototype.setPointsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.platform.ScoreboardSnapshot} returns this
 */
proto.platform.ScoreboardSnapshot.prototype.addPoints = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.platform.ScoreboardSnapshot} returns this
 */
proto.platform.ScoreboardSnapshot.prototype.clearPointsList = function() {
  return this.setPointsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.platform.ScoreboardBatch.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.platform.ScoreboardBatch.prototype.toObject = function(opt_includeInstance) {
  return proto.platform.ScoreboardBatch.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.platform.ScoreboardBatch} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.ScoreboardBatch.toObject = function(includeInstance, msg) {
  var f, obj = {
    teamidsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    ticksList: jspb.Message.toObjectList(msg.getTicksList(),
    proto.platform.ScoreboardSnapshot.toObject, includeInstance),
    clear: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.platform.ScoreboardBatch}
 */
proto.platform.ScoreboardBatch.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.platform.ScoreboardBatch;
  return proto.platform.ScoreboardBatch.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.platform.ScoreboardBatch} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.platform.ScoreboardBatch}
 */
proto.platform.ScoreboardBatch.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addTeamids(value);
      break;
    case 2:
      var value = new proto.platform.ScoreboardSnapshot;
      reader.readMessage(value,proto.platform.ScoreboardSnapshot.deserializeBinaryFromReader);
      msg.addTicks(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setClear(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.platform.ScoreboardBatch.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.platform.ScoreboardBatch.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.platform.ScoreboardBatch} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.ScoreboardBatch.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTeamidsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getTicksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.platform.ScoreboardSnapshot.serializeBinaryToWriter
    );
  }
  f = message.getClear();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * repeated string teamIDs = 1;
 * @return {!Array<string>}
 */
proto.platform.ScoreboardBatch.prototype.getTeamidsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.platform.ScoreboardBatch} returns this
 */
proto.platform.ScoreboardBatch.prototype.setTeamidsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.platform.ScoreboardBatch} returns this
 */
proto.platform.ScoreboardBatch.prototype.addTeamids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.platform.ScoreboardBatch} returns this
 */
proto.platform.ScoreboardBatch.prototype.clearTeamidsList = function() {
  return this.setTeamidsList([]);
};


/**
 * repeated ScoreboardSnapshot ticks = 2;
 * @return {!Array<!proto.platform.ScoreboardSnapshot>}
 */
proto.platform.ScoreboardBatch.prototype.getTicksList = function() {
  return /** @type{!Array<!proto.platform.ScoreboardSnapshot>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.platform.ScoreboardSnapshot, 2));
};


/**
 * @param {!Array<!proto.platform.ScoreboardSnapshot>} value
 * @return {!proto.platform.ScoreboardBatch} returns this
*/
proto.platform.ScoreboardBatch.prototype.setTicksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.platform.ScoreboardSnapshot=} opt_value
 * @param {number=} opt_index
 * @return {!proto.platform.ScoreboardSnapshot}
 */
proto.platform.ScoreboardBatch.prototype.addTicks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.platform.ScoreboardSnapshot, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.platform.ScoreboardBatch} returns this
 */
proto.platform.ScoreboardBatch.prototype.clearTicksList = function() {
  return this.setTicksList([]);
};


/**
 * optional bool clear = 3;
 * @return {boolean}
 */
proto.platform.ScoreboardBatch.prototype.getClear = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.platform.ScoreboardBatch} returns this
 */
proto.platform.ScoreboardBatch.prototype.setClear = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.platform.TransactionAggregate.repeatedFields_ = [3,5,6,9,11,12,14];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.platform.TransactionAggregate.prototype.toObject = function(opt_includeInstance) {
  return proto.platform.TransactionAggregate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.platform.TransactionAggregate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.TransactionAggregate.toObject = function(includeInstance, msg) {
  var f, obj = {
    height: jspb.Message.getFieldWithDefault(msg, 1, 0),
    timestamp: (f = msg.getTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    tasksList: jspb.Message.toObjectList(msg.getTasksList(),
    proto.platform.Task.toObject, includeInstance),
    taskStatesMap: (f = msg.getTaskStatesMap()) ? f.toObject(includeInstance, proto.platform.TaskState.toObject) : [],
    scoreboardHistoryList: jspb.Message.toObjectList(msg.getScoreboardHistoryList(),
    proto.platform.ScoreboardSnapshot.toObject, includeInstance),
    standingsList: jspb.Message.toObjectList(msg.getStandingsList(),
    proto.platform.Standing.toObject, includeInstance),
    lastAcceptMap: (f = msg.getLastAcceptMap()) ? f.toObject(includeInstance, proto.google.protobuf.Timestamp.toObject) : [],
    platformConfiguration: (f = msg.getPlatformConfiguration()) && proto.platform.PlatformConfiguration.toObject(includeInstance, f),
    flagSubmissionsList: jspb.Message.toObjectList(msg.getFlagSubmissionsList(),
    proto.platform.ValidFlagSubmission.toObject, includeInstance),
    profilesMap: (f = msg.getProfilesMap()) ? f.toObject(includeInstance, proto.platform.Profile.toObject) : [],
    kothHistoryList: jspb.Message.toObjectList(msg.getKothHistoryList(),
    proto.platform.KothHistoryTick.toObject, includeInstance),
    notificationsList: jspb.Message.toObjectList(msg.getNotificationsList(),
    proto.platform.Notification.toObject, includeInstance),
    jwtkey: jspb.Message.getFieldWithDefault(msg, 13, ""),
    badgesList: jspb.Message.toObjectList(msg.getBadgesList(),
    proto.platform.Badge.toObject, includeInstance),
    writeupsMap: (f = msg.getWriteupsMap()) ? f.toObject(includeInstance, proto.platform.TransactionAggregate.Writeups.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.platform.TransactionAggregate}
 */
proto.platform.TransactionAggregate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.platform.TransactionAggregate;
  return proto.platform.TransactionAggregate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.platform.TransactionAggregate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.platform.TransactionAggregate}
 */
proto.platform.TransactionAggregate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setHeight(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimestamp(value);
      break;
    case 3:
      var value = new proto.platform.Task;
      reader.readMessage(value,proto.platform.Task.deserializeBinaryFromReader);
      msg.addTasks(value);
      break;
    case 4:
      var value = msg.getTaskStatesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.platform.TaskState.deserializeBinaryFromReader, "", new proto.platform.TaskState());
         });
      break;
    case 5:
      var value = new proto.platform.ScoreboardSnapshot;
      reader.readMessage(value,proto.platform.ScoreboardSnapshot.deserializeBinaryFromReader);
      msg.addScoreboardHistory(value);
      break;
    case 6:
      var value = new proto.platform.Standing;
      reader.readMessage(value,proto.platform.Standing.deserializeBinaryFromReader);
      msg.addStandings(value);
      break;
    case 7:
      var value = msg.getLastAcceptMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.google.protobuf.Timestamp.deserializeBinaryFromReader, "", new proto.google.protobuf.Timestamp());
         });
      break;
    case 8:
      var value = new proto.platform.PlatformConfiguration;
      reader.readMessage(value,proto.platform.PlatformConfiguration.deserializeBinaryFromReader);
      msg.setPlatformConfiguration(value);
      break;
    case 9:
      var value = new proto.platform.ValidFlagSubmission;
      reader.readMessage(value,proto.platform.ValidFlagSubmission.deserializeBinaryFromReader);
      msg.addFlagSubmissions(value);
      break;
    case 10:
      var value = msg.getProfilesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.platform.Profile.deserializeBinaryFromReader, "", new proto.platform.Profile());
         });
      break;
    case 11:
      var value = new proto.platform.KothHistoryTick;
      reader.readMessage(value,proto.platform.KothHistoryTick.deserializeBinaryFromReader);
      msg.addKothHistory(value);
      break;
    case 12:
      var value = new proto.platform.Notification;
      reader.readMessage(value,proto.platform.Notification.deserializeBinaryFromReader);
      msg.addNotifications(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setJwtkey(value);
      break;
    case 14:
      var value = new proto.platform.Badge;
      reader.readMessage(value,proto.platform.Badge.deserializeBinaryFromReader);
      msg.addBadges(value);
      break;
    case 15:
      var value = msg.getWriteupsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.platform.TransactionAggregate.Writeups.deserializeBinaryFromReader, "", new proto.platform.TransactionAggregate.Writeups());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.platform.TransactionAggregate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.platform.TransactionAggregate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.platform.TransactionAggregate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.TransactionAggregate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeight();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getTimestamp();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTasksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.platform.Task.serializeBinaryToWriter
    );
  }
  f = message.getTaskStatesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(4, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.platform.TaskState.serializeBinaryToWriter);
  }
  f = message.getScoreboardHistoryList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.platform.ScoreboardSnapshot.serializeBinaryToWriter
    );
  }
  f = message.getStandingsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.platform.Standing.serializeBinaryToWriter
    );
  }
  f = message.getLastAcceptMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(7, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.google.protobuf.Timestamp.serializeBinaryToWriter);
  }
  f = message.getPlatformConfiguration();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.platform.PlatformConfiguration.serializeBinaryToWriter
    );
  }
  f = message.getFlagSubmissionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.platform.ValidFlagSubmission.serializeBinaryToWriter
    );
  }
  f = message.getProfilesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(10, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.platform.Profile.serializeBinaryToWriter);
  }
  f = message.getKothHistoryList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.platform.KothHistoryTick.serializeBinaryToWriter
    );
  }
  f = message.getNotificationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.platform.Notification.serializeBinaryToWriter
    );
  }
  f = message.getJwtkey();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getBadgesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      proto.platform.Badge.serializeBinaryToWriter
    );
  }
  f = message.getWriteupsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(15, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.platform.TransactionAggregate.Writeups.serializeBinaryToWriter);
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.platform.TransactionAggregate.Writeups.prototype.toObject = function(opt_includeInstance) {
  return proto.platform.TransactionAggregate.Writeups.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.platform.TransactionAggregate.Writeups} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.TransactionAggregate.Writeups.toObject = function(includeInstance, msg) {
  var f, obj = {
    originalFilenamesMap: (f = msg.getOriginalFilenamesMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.platform.TransactionAggregate.Writeups}
 */
proto.platform.TransactionAggregate.Writeups.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.platform.TransactionAggregate.Writeups;
  return proto.platform.TransactionAggregate.Writeups.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.platform.TransactionAggregate.Writeups} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.platform.TransactionAggregate.Writeups}
 */
proto.platform.TransactionAggregate.Writeups.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getOriginalFilenamesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.platform.TransactionAggregate.Writeups.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.platform.TransactionAggregate.Writeups.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.platform.TransactionAggregate.Writeups} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.TransactionAggregate.Writeups.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOriginalFilenamesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * map<string, string> original_filenames = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.platform.TransactionAggregate.Writeups.prototype.getOriginalFilenamesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.platform.TransactionAggregate.Writeups} returns this
 */
proto.platform.TransactionAggregate.Writeups.prototype.clearOriginalFilenamesMap = function() {
  this.getOriginalFilenamesMap().clear();
  return this;
};


/**
 * optional uint64 height = 1;
 * @return {number}
 */
proto.platform.TransactionAggregate.prototype.getHeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.platform.TransactionAggregate} returns this
 */
proto.platform.TransactionAggregate.prototype.setHeight = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp timestamp = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.platform.TransactionAggregate.prototype.getTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.platform.TransactionAggregate} returns this
*/
proto.platform.TransactionAggregate.prototype.setTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.platform.TransactionAggregate} returns this
 */
proto.platform.TransactionAggregate.prototype.clearTimestamp = function() {
  return this.setTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.platform.TransactionAggregate.prototype.hasTimestamp = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated Task tasks = 3;
 * @return {!Array<!proto.platform.Task>}
 */
proto.platform.TransactionAggregate.prototype.getTasksList = function() {
  return /** @type{!Array<!proto.platform.Task>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.platform.Task, 3));
};


/**
 * @param {!Array<!proto.platform.Task>} value
 * @return {!proto.platform.TransactionAggregate} returns this
*/
proto.platform.TransactionAggregate.prototype.setTasksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.platform.Task=} opt_value
 * @param {number=} opt_index
 * @return {!proto.platform.Task}
 */
proto.platform.TransactionAggregate.prototype.addTasks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.platform.Task, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.platform.TransactionAggregate} returns this
 */
proto.platform.TransactionAggregate.prototype.clearTasksList = function() {
  return this.setTasksList([]);
};


/**
 * map<string, TaskState> task_states = 4;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.platform.TaskState>}
 */
proto.platform.TransactionAggregate.prototype.getTaskStatesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.platform.TaskState>} */ (
      jspb.Message.getMapField(this, 4, opt_noLazyCreate,
      proto.platform.TaskState));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.platform.TransactionAggregate} returns this
 */
proto.platform.TransactionAggregate.prototype.clearTaskStatesMap = function() {
  this.getTaskStatesMap().clear();
  return this;
};


/**
 * repeated ScoreboardSnapshot scoreboard_history = 5;
 * @return {!Array<!proto.platform.ScoreboardSnapshot>}
 */
proto.platform.TransactionAggregate.prototype.getScoreboardHistoryList = function() {
  return /** @type{!Array<!proto.platform.ScoreboardSnapshot>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.platform.ScoreboardSnapshot, 5));
};


/**
 * @param {!Array<!proto.platform.ScoreboardSnapshot>} value
 * @return {!proto.platform.TransactionAggregate} returns this
*/
proto.platform.TransactionAggregate.prototype.setScoreboardHistoryList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.platform.ScoreboardSnapshot=} opt_value
 * @param {number=} opt_index
 * @return {!proto.platform.ScoreboardSnapshot}
 */
proto.platform.TransactionAggregate.prototype.addScoreboardHistory = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.platform.ScoreboardSnapshot, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.platform.TransactionAggregate} returns this
 */
proto.platform.TransactionAggregate.prototype.clearScoreboardHistoryList = function() {
  return this.setScoreboardHistoryList([]);
};


/**
 * repeated Standing standings = 6;
 * @return {!Array<!proto.platform.Standing>}
 */
proto.platform.TransactionAggregate.prototype.getStandingsList = function() {
  return /** @type{!Array<!proto.platform.Standing>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.platform.Standing, 6));
};


/**
 * @param {!Array<!proto.platform.Standing>} value
 * @return {!proto.platform.TransactionAggregate} returns this
*/
proto.platform.TransactionAggregate.prototype.setStandingsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.platform.Standing=} opt_value
 * @param {number=} opt_index
 * @return {!proto.platform.Standing}
 */
proto.platform.TransactionAggregate.prototype.addStandings = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.platform.Standing, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.platform.TransactionAggregate} returns this
 */
proto.platform.TransactionAggregate.prototype.clearStandingsList = function() {
  return this.setStandingsList([]);
};


/**
 * map<string, google.protobuf.Timestamp> last_accept = 7;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.google.protobuf.Timestamp>}
 */
proto.platform.TransactionAggregate.prototype.getLastAcceptMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.google.protobuf.Timestamp>} */ (
      jspb.Message.getMapField(this, 7, opt_noLazyCreate,
      proto.google.protobuf.Timestamp));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.platform.TransactionAggregate} returns this
 */
proto.platform.TransactionAggregate.prototype.clearLastAcceptMap = function() {
  this.getLastAcceptMap().clear();
  return this;
};


/**
 * optional PlatformConfiguration platform_configuration = 8;
 * @return {?proto.platform.PlatformConfiguration}
 */
proto.platform.TransactionAggregate.prototype.getPlatformConfiguration = function() {
  return /** @type{?proto.platform.PlatformConfiguration} */ (
    jspb.Message.getWrapperField(this, proto.platform.PlatformConfiguration, 8));
};


/**
 * @param {?proto.platform.PlatformConfiguration|undefined} value
 * @return {!proto.platform.TransactionAggregate} returns this
*/
proto.platform.TransactionAggregate.prototype.setPlatformConfiguration = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.platform.TransactionAggregate} returns this
 */
proto.platform.TransactionAggregate.prototype.clearPlatformConfiguration = function() {
  return this.setPlatformConfiguration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.platform.TransactionAggregate.prototype.hasPlatformConfiguration = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * repeated ValidFlagSubmission flag_submissions = 9;
 * @return {!Array<!proto.platform.ValidFlagSubmission>}
 */
proto.platform.TransactionAggregate.prototype.getFlagSubmissionsList = function() {
  return /** @type{!Array<!proto.platform.ValidFlagSubmission>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.platform.ValidFlagSubmission, 9));
};


/**
 * @param {!Array<!proto.platform.ValidFlagSubmission>} value
 * @return {!proto.platform.TransactionAggregate} returns this
*/
proto.platform.TransactionAggregate.prototype.setFlagSubmissionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.platform.ValidFlagSubmission=} opt_value
 * @param {number=} opt_index
 * @return {!proto.platform.ValidFlagSubmission}
 */
proto.platform.TransactionAggregate.prototype.addFlagSubmissions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.platform.ValidFlagSubmission, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.platform.TransactionAggregate} returns this
 */
proto.platform.TransactionAggregate.prototype.clearFlagSubmissionsList = function() {
  return this.setFlagSubmissionsList([]);
};


/**
 * map<string, Profile> profiles = 10;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.platform.Profile>}
 */
proto.platform.TransactionAggregate.prototype.getProfilesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.platform.Profile>} */ (
      jspb.Message.getMapField(this, 10, opt_noLazyCreate,
      proto.platform.Profile));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.platform.TransactionAggregate} returns this
 */
proto.platform.TransactionAggregate.prototype.clearProfilesMap = function() {
  this.getProfilesMap().clear();
  return this;
};


/**
 * repeated KothHistoryTick koth_history = 11;
 * @return {!Array<!proto.platform.KothHistoryTick>}
 */
proto.platform.TransactionAggregate.prototype.getKothHistoryList = function() {
  return /** @type{!Array<!proto.platform.KothHistoryTick>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.platform.KothHistoryTick, 11));
};


/**
 * @param {!Array<!proto.platform.KothHistoryTick>} value
 * @return {!proto.platform.TransactionAggregate} returns this
*/
proto.platform.TransactionAggregate.prototype.setKothHistoryList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.platform.KothHistoryTick=} opt_value
 * @param {number=} opt_index
 * @return {!proto.platform.KothHistoryTick}
 */
proto.platform.TransactionAggregate.prototype.addKothHistory = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.platform.KothHistoryTick, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.platform.TransactionAggregate} returns this
 */
proto.platform.TransactionAggregate.prototype.clearKothHistoryList = function() {
  return this.setKothHistoryList([]);
};


/**
 * repeated Notification notifications = 12;
 * @return {!Array<!proto.platform.Notification>}
 */
proto.platform.TransactionAggregate.prototype.getNotificationsList = function() {
  return /** @type{!Array<!proto.platform.Notification>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.platform.Notification, 12));
};


/**
 * @param {!Array<!proto.platform.Notification>} value
 * @return {!proto.platform.TransactionAggregate} returns this
*/
proto.platform.TransactionAggregate.prototype.setNotificationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.platform.Notification=} opt_value
 * @param {number=} opt_index
 * @return {!proto.platform.Notification}
 */
proto.platform.TransactionAggregate.prototype.addNotifications = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.platform.Notification, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.platform.TransactionAggregate} returns this
 */
proto.platform.TransactionAggregate.prototype.clearNotificationsList = function() {
  return this.setNotificationsList([]);
};


/**
 * optional string jwtKey = 13;
 * @return {string}
 */
proto.platform.TransactionAggregate.prototype.getJwtkey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.TransactionAggregate} returns this
 */
proto.platform.TransactionAggregate.prototype.setJwtkey = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * repeated Badge badges = 14;
 * @return {!Array<!proto.platform.Badge>}
 */
proto.platform.TransactionAggregate.prototype.getBadgesList = function() {
  return /** @type{!Array<!proto.platform.Badge>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.platform.Badge, 14));
};


/**
 * @param {!Array<!proto.platform.Badge>} value
 * @return {!proto.platform.TransactionAggregate} returns this
*/
proto.platform.TransactionAggregate.prototype.setBadgesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.platform.Badge=} opt_value
 * @param {number=} opt_index
 * @return {!proto.platform.Badge}
 */
proto.platform.TransactionAggregate.prototype.addBadges = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.platform.Badge, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.platform.TransactionAggregate} returns this
 */
proto.platform.TransactionAggregate.prototype.clearBadgesList = function() {
  return this.setBadgesList([]);
};


/**
 * map<string, Writeups> writeups = 15;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.platform.TransactionAggregate.Writeups>}
 */
proto.platform.TransactionAggregate.prototype.getWriteupsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.platform.TransactionAggregate.Writeups>} */ (
      jspb.Message.getMapField(this, 15, opt_noLazyCreate,
      proto.platform.TransactionAggregate.Writeups));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.platform.TransactionAggregate} returns this
 */
proto.platform.TransactionAggregate.prototype.clearWriteupsMap = function() {
  this.getWriteupsMap().clear();
  return this;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.platform.Contest.prototype.toObject = function(opt_includeInstance) {
  return proto.platform.Contest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.platform.Contest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.Contest.toObject = function(includeInstance, msg) {
  var f, obj = {
    state: jspb.Message.getFieldWithDefault(msg, 1, 0),
    config: (f = msg.getConfig()) && proto.platform.ContestConfiguration.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.platform.Contest}
 */
proto.platform.Contest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.platform.Contest;
  return proto.platform.Contest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.platform.Contest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.platform.Contest}
 */
proto.platform.Contest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.platform.ContestState} */ (reader.readEnum());
      msg.setState(value);
      break;
    case 2:
      var value = new proto.platform.ContestConfiguration;
      reader.readMessage(value,proto.platform.ContestConfiguration.deserializeBinaryFromReader);
      msg.setConfig(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.platform.Contest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.platform.Contest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.platform.Contest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.Contest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getConfig();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.platform.ContestConfiguration.serializeBinaryToWriter
    );
  }
};


/**
 * optional ContestState state = 1;
 * @return {!proto.platform.ContestState}
 */
proto.platform.Contest.prototype.getState = function() {
  return /** @type {!proto.platform.ContestState} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.platform.ContestState} value
 * @return {!proto.platform.Contest} returns this
 */
proto.platform.Contest.prototype.setState = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional ContestConfiguration config = 2;
 * @return {?proto.platform.ContestConfiguration}
 */
proto.platform.Contest.prototype.getConfig = function() {
  return /** @type{?proto.platform.ContestConfiguration} */ (
    jspb.Message.getWrapperField(this, proto.platform.ContestConfiguration, 2));
};


/**
 * @param {?proto.platform.ContestConfiguration|undefined} value
 * @return {!proto.platform.Contest} returns this
*/
proto.platform.Contest.prototype.setConfig = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.platform.Contest} returns this
 */
proto.platform.Contest.prototype.clearConfig = function() {
  return this.setConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.platform.Contest.prototype.hasConfig = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.platform.ContestConfiguration.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.platform.ContestConfiguration.prototype.toObject = function(opt_includeInstance) {
  return proto.platform.ContestConfiguration.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.platform.ContestConfiguration} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.ContestConfiguration.toObject = function(includeInstance, msg) {
  var f, obj = {
    starttime: (f = msg.getStarttime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    endtime: (f = msg.getEndtime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    bracketsList: jspb.Message.toObjectList(msg.getBracketsList(),
    proto.platform.Bracket.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.platform.ContestConfiguration}
 */
proto.platform.ContestConfiguration.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.platform.ContestConfiguration;
  return proto.platform.ContestConfiguration.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.platform.ContestConfiguration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.platform.ContestConfiguration}
 */
proto.platform.ContestConfiguration.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStarttime(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndtime(value);
      break;
    case 3:
      var value = new proto.platform.Bracket;
      reader.readMessage(value,proto.platform.Bracket.deserializeBinaryFromReader);
      msg.addBrackets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.platform.ContestConfiguration.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.platform.ContestConfiguration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.platform.ContestConfiguration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.ContestConfiguration.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStarttime();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEndtime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getBracketsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.platform.Bracket.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp startTime = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.platform.ContestConfiguration.prototype.getStarttime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.platform.ContestConfiguration} returns this
*/
proto.platform.ContestConfiguration.prototype.setStarttime = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.platform.ContestConfiguration} returns this
 */
proto.platform.ContestConfiguration.prototype.clearStarttime = function() {
  return this.setStarttime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.platform.ContestConfiguration.prototype.hasStarttime = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp endTime = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.platform.ContestConfiguration.prototype.getEndtime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.platform.ContestConfiguration} returns this
*/
proto.platform.ContestConfiguration.prototype.setEndtime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.platform.ContestConfiguration} returns this
 */
proto.platform.ContestConfiguration.prototype.clearEndtime = function() {
  return this.setEndtime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.platform.ContestConfiguration.prototype.hasEndtime = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated Bracket brackets = 3;
 * @return {!Array<!proto.platform.Bracket>}
 */
proto.platform.ContestConfiguration.prototype.getBracketsList = function() {
  return /** @type{!Array<!proto.platform.Bracket>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.platform.Bracket, 3));
};


/**
 * @param {!Array<!proto.platform.Bracket>} value
 * @return {!proto.platform.ContestConfiguration} returns this
*/
proto.platform.ContestConfiguration.prototype.setBracketsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.platform.Bracket=} opt_value
 * @param {number=} opt_index
 * @return {!proto.platform.Bracket}
 */
proto.platform.ContestConfiguration.prototype.addBrackets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.platform.Bracket, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.platform.ContestConfiguration} returns this
 */
proto.platform.ContestConfiguration.prototype.clearBracketsList = function() {
  return this.setBracketsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.platform.Bracket.prototype.toObject = function(opt_includeInstance) {
  return proto.platform.Bracket.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.platform.Bracket} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.Bracket.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    url: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.platform.Bracket}
 */
proto.platform.Bracket.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.platform.Bracket;
  return proto.platform.Bracket.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.platform.Bracket} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.platform.Bracket}
 */
proto.platform.Bracket.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.platform.Bracket.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.platform.Bracket.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.platform.Bracket} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.Bracket.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.platform.Bracket.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.Bracket} returns this
 */
proto.platform.Bracket.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.platform.Bracket.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.Bracket} returns this
 */
proto.platform.Bracket.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string url = 3;
 * @return {string}
 */
proto.platform.Bracket.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.Bracket} returns this
 */
proto.platform.Bracket.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.platform.RequestQueuePositionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.platform.RequestQueuePositionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.platform.RequestQueuePositionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.RequestQueuePositionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    task: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.platform.RequestQueuePositionRequest}
 */
proto.platform.RequestQueuePositionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.platform.RequestQueuePositionRequest;
  return proto.platform.RequestQueuePositionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.platform.RequestQueuePositionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.platform.RequestQueuePositionRequest}
 */
proto.platform.RequestQueuePositionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTask(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.platform.RequestQueuePositionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.platform.RequestQueuePositionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.platform.RequestQueuePositionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.RequestQueuePositionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTask();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string task = 1;
 * @return {string}
 */
proto.platform.RequestQueuePositionRequest.prototype.getTask = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.RequestQueuePositionRequest} returns this
 */
proto.platform.RequestQueuePositionRequest.prototype.setTask = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.platform.RequestQueuePositionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.platform.RequestQueuePositionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.platform.RequestQueuePositionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.RequestQueuePositionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.platform.RequestQueuePositionResponse}
 */
proto.platform.RequestQueuePositionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.platform.RequestQueuePositionResponse;
  return proto.platform.RequestQueuePositionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.platform.RequestQueuePositionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.platform.RequestQueuePositionResponse}
 */
proto.platform.RequestQueuePositionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.platform.RequestQueuePositionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.platform.RequestQueuePositionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.platform.RequestQueuePositionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.RequestQueuePositionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.platform.RequestQueuePositionResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.platform.RequestQueuePositionResponse} returns this
 */
proto.platform.RequestQueuePositionResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.platform.LeaveQueueRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.platform.LeaveQueueRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.platform.LeaveQueueRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.LeaveQueueRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    task: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.platform.LeaveQueueRequest}
 */
proto.platform.LeaveQueueRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.platform.LeaveQueueRequest;
  return proto.platform.LeaveQueueRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.platform.LeaveQueueRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.platform.LeaveQueueRequest}
 */
proto.platform.LeaveQueueRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTask(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.platform.LeaveQueueRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.platform.LeaveQueueRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.platform.LeaveQueueRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.LeaveQueueRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTask();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string task = 1;
 * @return {string}
 */
proto.platform.LeaveQueueRequest.prototype.getTask = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.LeaveQueueRequest} returns this
 */
proto.platform.LeaveQueueRequest.prototype.setTask = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.platform.LeaveQueueResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.platform.LeaveQueueResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.platform.LeaveQueueResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.LeaveQueueResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.platform.LeaveQueueResponse}
 */
proto.platform.LeaveQueueResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.platform.LeaveQueueResponse;
  return proto.platform.LeaveQueueResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.platform.LeaveQueueResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.platform.LeaveQueueResponse}
 */
proto.platform.LeaveQueueResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.platform.LeaveQueueResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.platform.LeaveQueueResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.platform.LeaveQueueResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.LeaveQueueResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.platform.LeaveQueueResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.platform.LeaveQueueResponse} returns this
 */
proto.platform.LeaveQueueResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.platform.GetQueueStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.platform.GetQueueStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.platform.GetQueueStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.GetQueueStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    task: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.platform.GetQueueStatusRequest}
 */
proto.platform.GetQueueStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.platform.GetQueueStatusRequest;
  return proto.platform.GetQueueStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.platform.GetQueueStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.platform.GetQueueStatusRequest}
 */
proto.platform.GetQueueStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTask(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.platform.GetQueueStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.platform.GetQueueStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.platform.GetQueueStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.GetQueueStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTask();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string task = 1;
 * @return {string}
 */
proto.platform.GetQueueStatusRequest.prototype.getTask = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.GetQueueStatusRequest} returns this
 */
proto.platform.GetQueueStatusRequest.prototype.setTask = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.platform.GetQueueStatusResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.platform.GetQueueStatusResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.platform.GetQueueStatusResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.platform.GetQueueStatusResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.GetQueueStatusResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    queueList: jspb.Message.toObjectList(msg.getQueueList(),
    proto.platform.QueueEntry1.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.platform.GetQueueStatusResponse}
 */
proto.platform.GetQueueStatusResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.platform.GetQueueStatusResponse;
  return proto.platform.GetQueueStatusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.platform.GetQueueStatusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.platform.GetQueueStatusResponse}
 */
proto.platform.GetQueueStatusResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.platform.QueueEntry1;
      reader.readMessage(value,proto.platform.QueueEntry1.deserializeBinaryFromReader);
      msg.addQueue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.platform.GetQueueStatusResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.platform.GetQueueStatusResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.platform.GetQueueStatusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.GetQueueStatusResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQueueList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.platform.QueueEntry1.serializeBinaryToWriter
    );
  }
};


/**
 * repeated QueueEntry1 queue = 1;
 * @return {!Array<!proto.platform.QueueEntry1>}
 */
proto.platform.GetQueueStatusResponse.prototype.getQueueList = function() {
  return /** @type{!Array<!proto.platform.QueueEntry1>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.platform.QueueEntry1, 1));
};


/**
 * @param {!Array<!proto.platform.QueueEntry1>} value
 * @return {!proto.platform.GetQueueStatusResponse} returns this
*/
proto.platform.GetQueueStatusResponse.prototype.setQueueList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.platform.QueueEntry1=} opt_value
 * @param {number=} opt_index
 * @return {!proto.platform.QueueEntry1}
 */
proto.platform.GetQueueStatusResponse.prototype.addQueue = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.platform.QueueEntry1, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.platform.GetQueueStatusResponse} returns this
 */
proto.platform.GetQueueStatusResponse.prototype.clearQueueList = function() {
  return this.setQueueList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.platform.UpdateQueueStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.platform.UpdateQueueStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.platform.UpdateQueueStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.UpdateQueueStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    task: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, ""),
    state: jspb.Message.getFieldWithDefault(msg, 3, 0),
    additionalinfo: jspb.Message.getFieldWithDefault(msg, 4, ""),
    endtime: (f = msg.getEndtime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.platform.UpdateQueueStatusRequest}
 */
proto.platform.UpdateQueueStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.platform.UpdateQueueStatusRequest;
  return proto.platform.UpdateQueueStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.platform.UpdateQueueStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.platform.UpdateQueueStatusRequest}
 */
proto.platform.UpdateQueueStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTask(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {!proto.platform.QueueState} */ (reader.readEnum());
      msg.setState(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdditionalinfo(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndtime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.platform.UpdateQueueStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.platform.UpdateQueueStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.platform.UpdateQueueStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.UpdateQueueStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTask();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getAdditionalinfo();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEndtime();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string task = 1;
 * @return {string}
 */
proto.platform.UpdateQueueStatusRequest.prototype.getTask = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.UpdateQueueStatusRequest} returns this
 */
proto.platform.UpdateQueueStatusRequest.prototype.setTask = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.platform.UpdateQueueStatusRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.UpdateQueueStatusRequest} returns this
 */
proto.platform.UpdateQueueStatusRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional QueueState state = 3;
 * @return {!proto.platform.QueueState}
 */
proto.platform.UpdateQueueStatusRequest.prototype.getState = function() {
  return /** @type {!proto.platform.QueueState} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.platform.QueueState} value
 * @return {!proto.platform.UpdateQueueStatusRequest} returns this
 */
proto.platform.UpdateQueueStatusRequest.prototype.setState = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string additionalInfo = 4;
 * @return {string}
 */
proto.platform.UpdateQueueStatusRequest.prototype.getAdditionalinfo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.UpdateQueueStatusRequest} returns this
 */
proto.platform.UpdateQueueStatusRequest.prototype.setAdditionalinfo = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp endtime = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.platform.UpdateQueueStatusRequest.prototype.getEndtime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.platform.UpdateQueueStatusRequest} returns this
*/
proto.platform.UpdateQueueStatusRequest.prototype.setEndtime = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.platform.UpdateQueueStatusRequest} returns this
 */
proto.platform.UpdateQueueStatusRequest.prototype.clearEndtime = function() {
  return this.setEndtime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.platform.UpdateQueueStatusRequest.prototype.hasEndtime = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.platform.UpdateQueueStatusResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.platform.UpdateQueueStatusResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.platform.UpdateQueueStatusResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.UpdateQueueStatusResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.platform.UpdateQueueStatusResponse}
 */
proto.platform.UpdateQueueStatusResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.platform.UpdateQueueStatusResponse;
  return proto.platform.UpdateQueueStatusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.platform.UpdateQueueStatusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.platform.UpdateQueueStatusResponse}
 */
proto.platform.UpdateQueueStatusResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.platform.UpdateQueueStatusResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.platform.UpdateQueueStatusResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.platform.UpdateQueueStatusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.UpdateQueueStatusResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.platform.UpdateQueueStatusResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.platform.UpdateQueueStatusResponse} returns this
 */
proto.platform.UpdateQueueStatusResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.platform.Notification.prototype.toObject = function(opt_includeInstance) {
  return proto.platform.Notification.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.platform.Notification} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.Notification.toObject = function(includeInstance, msg) {
  var f, obj = {
    time: (f = msg.getTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    text: jspb.Message.getFieldWithDefault(msg, 3, ""),
    severity: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.platform.Notification}
 */
proto.platform.Notification.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.platform.Notification;
  return proto.platform.Notification.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.platform.Notification} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.platform.Notification}
 */
proto.platform.Notification.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTime(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 4:
      var value = /** @type {!proto.platform.Severity} */ (reader.readEnum());
      msg.setSeverity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.platform.Notification.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.platform.Notification.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.platform.Notification} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.Notification.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTime();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSeverity();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp time = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.platform.Notification.prototype.getTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.platform.Notification} returns this
*/
proto.platform.Notification.prototype.setTime = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.platform.Notification} returns this
 */
proto.platform.Notification.prototype.clearTime = function() {
  return this.setTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.platform.Notification.prototype.hasTime = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.platform.Notification.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.Notification} returns this
 */
proto.platform.Notification.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string text = 3;
 * @return {string}
 */
proto.platform.Notification.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.Notification} returns this
 */
proto.platform.Notification.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Severity severity = 4;
 * @return {!proto.platform.Severity}
 */
proto.platform.Notification.prototype.getSeverity = function() {
  return /** @type {!proto.platform.Severity} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.platform.Severity} value
 * @return {!proto.platform.Notification} returns this
 */
proto.platform.Notification.prototype.setSeverity = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.platform.GetNotificationsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.platform.GetNotificationsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.platform.GetNotificationsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.GetNotificationsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    streaming: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.platform.GetNotificationsRequest}
 */
proto.platform.GetNotificationsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.platform.GetNotificationsRequest;
  return proto.platform.GetNotificationsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.platform.GetNotificationsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.platform.GetNotificationsRequest}
 */
proto.platform.GetNotificationsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStreaming(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.platform.GetNotificationsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.platform.GetNotificationsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.platform.GetNotificationsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.GetNotificationsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStreaming();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool streaming = 1;
 * @return {boolean}
 */
proto.platform.GetNotificationsRequest.prototype.getStreaming = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.platform.GetNotificationsRequest} returns this
 */
proto.platform.GetNotificationsRequest.prototype.setStreaming = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.platform.GetNotificationsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.platform.GetNotificationsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.platform.GetNotificationsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.platform.GetNotificationsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.GetNotificationsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    notificationsList: jspb.Message.toObjectList(msg.getNotificationsList(),
    proto.platform.Notification.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.platform.GetNotificationsResponse}
 */
proto.platform.GetNotificationsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.platform.GetNotificationsResponse;
  return proto.platform.GetNotificationsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.platform.GetNotificationsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.platform.GetNotificationsResponse}
 */
proto.platform.GetNotificationsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.platform.Notification;
      reader.readMessage(value,proto.platform.Notification.deserializeBinaryFromReader);
      msg.addNotifications(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.platform.GetNotificationsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.platform.GetNotificationsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.platform.GetNotificationsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.GetNotificationsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNotificationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.platform.Notification.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Notification notifications = 1;
 * @return {!Array<!proto.platform.Notification>}
 */
proto.platform.GetNotificationsResponse.prototype.getNotificationsList = function() {
  return /** @type{!Array<!proto.platform.Notification>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.platform.Notification, 1));
};


/**
 * @param {!Array<!proto.platform.Notification>} value
 * @return {!proto.platform.GetNotificationsResponse} returns this
*/
proto.platform.GetNotificationsResponse.prototype.setNotificationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.platform.Notification=} opt_value
 * @param {number=} opt_index
 * @return {!proto.platform.Notification}
 */
proto.platform.GetNotificationsResponse.prototype.addNotifications = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.platform.Notification, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.platform.GetNotificationsResponse} returns this
 */
proto.platform.GetNotificationsResponse.prototype.clearNotificationsList = function() {
  return this.setNotificationsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.platform.Badge.prototype.toObject = function(opt_includeInstance) {
  return proto.platform.Badge.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.platform.Badge} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.Badge.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    url: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.platform.Badge}
 */
proto.platform.Badge.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.platform.Badge;
  return proto.platform.Badge.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.platform.Badge} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.platform.Badge}
 */
proto.platform.Badge.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.platform.Badge.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.platform.Badge.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.platform.Badge} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.Badge.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.platform.Badge.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.Badge} returns this
 */
proto.platform.Badge.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.platform.Badge.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.Badge} returns this
 */
proto.platform.Badge.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string url = 3;
 * @return {string}
 */
proto.platform.Badge.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.platform.Badge} returns this
 */
proto.platform.Badge.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.platform.GetBadgesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.platform.GetBadgesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.platform.GetBadgesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.GetBadgesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    streaming: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.platform.GetBadgesRequest}
 */
proto.platform.GetBadgesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.platform.GetBadgesRequest;
  return proto.platform.GetBadgesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.platform.GetBadgesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.platform.GetBadgesRequest}
 */
proto.platform.GetBadgesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStreaming(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.platform.GetBadgesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.platform.GetBadgesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.platform.GetBadgesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.GetBadgesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStreaming();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool streaming = 1;
 * @return {boolean}
 */
proto.platform.GetBadgesRequest.prototype.getStreaming = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.platform.GetBadgesRequest} returns this
 */
proto.platform.GetBadgesRequest.prototype.setStreaming = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.platform.GetBadgesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.platform.GetBadgesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.platform.GetBadgesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.platform.GetBadgesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.GetBadgesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    badgesList: jspb.Message.toObjectList(msg.getBadgesList(),
    proto.platform.Badge.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.platform.GetBadgesResponse}
 */
proto.platform.GetBadgesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.platform.GetBadgesResponse;
  return proto.platform.GetBadgesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.platform.GetBadgesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.platform.GetBadgesResponse}
 */
proto.platform.GetBadgesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.platform.Badge;
      reader.readMessage(value,proto.platform.Badge.deserializeBinaryFromReader);
      msg.addBadges(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.platform.GetBadgesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.platform.GetBadgesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.platform.GetBadgesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.GetBadgesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBadgesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.platform.Badge.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Badge badges = 1;
 * @return {!Array<!proto.platform.Badge>}
 */
proto.platform.GetBadgesResponse.prototype.getBadgesList = function() {
  return /** @type{!Array<!proto.platform.Badge>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.platform.Badge, 1));
};


/**
 * @param {!Array<!proto.platform.Badge>} value
 * @return {!proto.platform.GetBadgesResponse} returns this
*/
proto.platform.GetBadgesResponse.prototype.setBadgesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.platform.Badge=} opt_value
 * @param {number=} opt_index
 * @return {!proto.platform.Badge}
 */
proto.platform.GetBadgesResponse.prototype.addBadges = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.platform.Badge, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.platform.GetBadgesResponse} returns this
 */
proto.platform.GetBadgesResponse.prototype.clearBadgesList = function() {
  return this.setBadgesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.platform.AddBadgeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.platform.AddBadgeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.platform.AddBadgeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.AddBadgeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    badge: (f = msg.getBadge()) && proto.platform.Badge.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.platform.AddBadgeRequest}
 */
proto.platform.AddBadgeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.platform.AddBadgeRequest;
  return proto.platform.AddBadgeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.platform.AddBadgeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.platform.AddBadgeRequest}
 */
proto.platform.AddBadgeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.platform.Badge;
      reader.readMessage(value,proto.platform.Badge.deserializeBinaryFromReader);
      msg.setBadge(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.platform.AddBadgeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.platform.AddBadgeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.platform.AddBadgeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.AddBadgeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBadge();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.platform.Badge.serializeBinaryToWriter
    );
  }
};


/**
 * optional Badge badge = 1;
 * @return {?proto.platform.Badge}
 */
proto.platform.AddBadgeRequest.prototype.getBadge = function() {
  return /** @type{?proto.platform.Badge} */ (
    jspb.Message.getWrapperField(this, proto.platform.Badge, 1));
};


/**
 * @param {?proto.platform.Badge|undefined} value
 * @return {!proto.platform.AddBadgeRequest} returns this
*/
proto.platform.AddBadgeRequest.prototype.setBadge = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.platform.AddBadgeRequest} returns this
 */
proto.platform.AddBadgeRequest.prototype.clearBadge = function() {
  return this.setBadge(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.platform.AddBadgeRequest.prototype.hasBadge = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.platform.AddBadgeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.platform.AddBadgeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.platform.AddBadgeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.AddBadgeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    badge: (f = msg.getBadge()) && proto.platform.Badge.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.platform.AddBadgeResponse}
 */
proto.platform.AddBadgeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.platform.AddBadgeResponse;
  return proto.platform.AddBadgeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.platform.AddBadgeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.platform.AddBadgeResponse}
 */
proto.platform.AddBadgeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new proto.platform.Badge;
      reader.readMessage(value,proto.platform.Badge.deserializeBinaryFromReader);
      msg.setBadge(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.platform.AddBadgeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.platform.AddBadgeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.platform.AddBadgeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.AddBadgeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getBadge();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.platform.Badge.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.platform.AddBadgeResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.platform.AddBadgeResponse} returns this
 */
proto.platform.AddBadgeResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Badge badge = 2;
 * @return {?proto.platform.Badge}
 */
proto.platform.AddBadgeResponse.prototype.getBadge = function() {
  return /** @type{?proto.platform.Badge} */ (
    jspb.Message.getWrapperField(this, proto.platform.Badge, 2));
};


/**
 * @param {?proto.platform.Badge|undefined} value
 * @return {!proto.platform.AddBadgeResponse} returns this
*/
proto.platform.AddBadgeResponse.prototype.setBadge = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.platform.AddBadgeResponse} returns this
 */
proto.platform.AddBadgeResponse.prototype.clearBadge = function() {
  return this.setBadge(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.platform.AddBadgeResponse.prototype.hasBadge = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.platform.AddNotificationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.platform.AddNotificationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.platform.AddNotificationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.AddNotificationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    notification: (f = msg.getNotification()) && proto.platform.Notification.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.platform.AddNotificationRequest}
 */
proto.platform.AddNotificationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.platform.AddNotificationRequest;
  return proto.platform.AddNotificationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.platform.AddNotificationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.platform.AddNotificationRequest}
 */
proto.platform.AddNotificationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.platform.Notification;
      reader.readMessage(value,proto.platform.Notification.deserializeBinaryFromReader);
      msg.setNotification(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.platform.AddNotificationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.platform.AddNotificationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.platform.AddNotificationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.AddNotificationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNotification();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.platform.Notification.serializeBinaryToWriter
    );
  }
};


/**
 * optional Notification notification = 1;
 * @return {?proto.platform.Notification}
 */
proto.platform.AddNotificationRequest.prototype.getNotification = function() {
  return /** @type{?proto.platform.Notification} */ (
    jspb.Message.getWrapperField(this, proto.platform.Notification, 1));
};


/**
 * @param {?proto.platform.Notification|undefined} value
 * @return {!proto.platform.AddNotificationRequest} returns this
*/
proto.platform.AddNotificationRequest.prototype.setNotification = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.platform.AddNotificationRequest} returns this
 */
proto.platform.AddNotificationRequest.prototype.clearNotification = function() {
  return this.setNotification(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.platform.AddNotificationRequest.prototype.hasNotification = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.platform.AddNotificationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.platform.AddNotificationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.platform.AddNotificationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.AddNotificationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.platform.AddNotificationResponse}
 */
proto.platform.AddNotificationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.platform.AddNotificationResponse;
  return proto.platform.AddNotificationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.platform.AddNotificationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.platform.AddNotificationResponse}
 */
proto.platform.AddNotificationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.platform.AddNotificationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.platform.AddNotificationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.platform.AddNotificationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.platform.AddNotificationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.platform.AddNotificationResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.platform.AddNotificationResponse} returns this
 */
proto.platform.AddNotificationResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * @enum {number}
 */
proto.platform.ProfileType = {
  CTFTIME: 0,
  DISCORD: 1,
  BOT: 2
};

/**
 * @enum {number}
 */
proto.platform.QueueState = {
  INVALID: 0,
  QUEUED: 1,
  CLAIMED: 2,
  QUEUE_RUNNING: 3,
  FINISHED: 4
};

/**
 * @enum {number}
 */
proto.platform.TaskType = {
  JEOPARDY: 0,
  KOTH: 1
};

/**
 * @enum {number}
 */
proto.platform.ContestState = {
  RUNNING: 0,
  SUBMISSION_CLOSED: 1,
  NOT_YET_STARTED: 2
};

/**
 * @enum {number}
 */
proto.platform.Severity = {
  SUCCESS: 0,
  DANGER: 1,
  WARNING: 2,
  INFO: 3
};

goog.object.extend(exports, proto.platform);
