import React, { useContext, useEffect, useState } from "react";
import { AutoSizer, Column, SortDirection, Table } from "react-virtualized";
import { APIState } from "../State/API";
import clsx from "clsx";
import { ResponsiveLine } from "@nivo/line";
import { makeStyles } from "@material-ui/styles";
import { FaBaby, FaUserGraduate } from "react-icons/fa";
import {
  IoIosAddCircleOutline,
  IoIosArrowUp,
  IoIosRemoveCircleOutline,
} from "react-icons/io";
import {
  IoEarth,
  IoFlag,
  IoFlagOutline,
  IoDocumentTextSharp,
} from "react-icons/io5";
import { GiCrownedSkull } from "react-icons/gi";
import { Profile } from "API/platform_pb";
import { ResponsiveBump } from "@nivo/bump";
import { Toggle } from "./Toggle";
import "react-virtualized/styles.css";

const useStyles = makeStyles({
  root: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
  },
  entry: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  entryName: {
    flexGrow: 1,
    textAlign: "left",
  },
  entryAvatar: {
    borderRadius: "50%",
  },
  entryAdd: {
    width: "40px",
    height: "40px",
    fill: "green",
    filter: "brightness(150%)",
    "&:hover": {
      filter: "brightness(50%)",
    },
  },
  entryRemove: {
    width: "40px",
    height: "40px",
    fill: "red",
    filter: "brightness(150%)",
    "&:hover": {
      filter: "brightness(50%)",
    },
  },
  entryCategory: {
    padding: "10px",
    "& > *": {
      height: "20px",
      width: "20px",
    },
  },

  graph: {
    height: "40%",
  },
  toggle: {
    position: "absolute",
    display: "none",
  },
  diagonalHeader: {
    transform: "rotate(-45deg)",
    transformOrigin: "left",
    whiteSpace: "nowrap",
  },
  emptyMessage: {
    padding: "10px",
  },
  customHeader: {},
  customSortIndicator: {},
  customSortIndicatorUp: {
    animation: `$rotateUp .3s ease-in-out`,
    animationFillMode: "forwards",
  },
  customSortIndicatorDown: {
    animation: `$rotateDown .3s ease-in-out`,
    animationFillMode: "forwards",
  },
  "@keyframes rotateUp": {
    "0%": {
      transform: "rotate(180deg)",
    },
    "100%": {
      transform: "rotate(0deg)",
    },
  },
  "@keyframes rotateDown": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(180deg)",
    },
  },
});

interface IGraph {
  compared: string[];
  className: string;
}

interface LineData {
  id: string;
  data: {
    x: Date;
    y: number;
  }[];
}

const GraphLine = (props: IGraph) => {
  const api = useContext(APIState);
  const [resolution, setResolution] = useState(100);
  const [displayResolution, setDisplayResolution] = useState(100);

  const [data, setData] = useState<LineData[]>([]);

  useEffect(() => {
    const data = api.scoreboard
      .getTeamidsList()
      .filter((profile) => props.compared.includes(profile))
      .map((teamID, i) => {
        let id = api.profileIndexMap.get(teamID)
        return {
          id: api.profiles.get(teamID)?.getName() || teamID,
          data: api.scoreboard.getTicksList().filter((_, idx) => (idx % (Math.round(api.scoreboard.getTicksList().length / displayResolution)) === 0) || idx === api.scoreboard.getTicksList().length - 1)
            .map(snapshot => {
              return {
                x: snapshot.getHeight().toDate(),
                y: snapshot.getPointsList()[id]
                            }
            })
                }
      })

    data.reverse();

    setData(data);
  }, [
    api.scoreboard,
    api.profileIndexMap,
    api.profiles,
    props.compared,
    displayResolution,
  ]);

  return (
    <div className={props.className}>
      <input
        type="range"
        min="1"
        max={api.scoreboard.getTicksList().length}
        defaultValue="100"
        onChange={(e) => setResolution(parseInt(e.target.value))}
        onMouseUp={() => {
          setDisplayResolution(resolution);
        }}
      />

      <ResponsiveLine
        data={data}
        enablePoints={false}
        enableSlices="x"
        curve="monotoneX"
        margin={{ top: 20, right: 120, bottom: 5, left: 40 }}
        xScale={{ type: "time", format: "native", precision: "second" }}
        yScale={{ type: "linear", stacked: false, min: 0, max: "auto" }}
        axisTop={null}
        axisRight={null}
        axisBottom={null}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: null,
        }}
        colors={{ scheme: "paired" }}
        legends={[
          {
            anchor: "bottom-right",
            direction: "column",
            justify: false,
            translateX: 100,
            translateY: 0,
            itemsSpacing: 0,
            itemDirection: "left-to-right",
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: "circle",
            symbolBorderColor: "rgba(0, 0, 0, .5)",
            effects: [
              {
                on: "hover",
                style: {
                  itemBackground: "rgba(0, 0, 0, .03)",
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
      />
    </div>
  );
};

interface BumpData {
  id: string;
  data: {
    x: number;
    y: number;
  }[];
}

const GraphBump = (props: IGraph) => {
  const api = useContext(APIState);

  const [data, setData] = useState<BumpData[]>([]);
  const [resolution, setResolution] = useState(10);
  const [displayResolution, setDisplayResolution] = useState(10);

  useEffect(() => {
    const data = api.scoreboard
      .getTeamidsList()
      .filter((profile) => props.compared.includes(profile))
      .map((teamID, i) => {
        let id = api.profileIndexMap.get(teamID)
        return {
          id: api.profiles.get(teamID)?.getName() || teamID,
          data: api.scoreboard
            .getTicksList()
            .filter(
              (_, idx) =>
                idx %
                  Math.round(
                    api.scoreboard.getTicksList().length / displayResolution
                  ) ===
                  0 || idx === api.scoreboard.getTicksList().length - 1
            )
            .map((snapshot) => {
              return {
                x: snapshot.getHeight().toDate().getTime(),
                y:
                  snapshot
                    .getPointsList()
                    .map((v, idx) => ({ idx: idx, v: v }))
                    .filter((v, idx) =>
                      props.compared.includes(
                        api.scoreboard.getTeamidsList()[idx]
                      )
                    )
                    .sort((a, b) => b.v - a.v)
                    .findIndex((x) => x.idx === id) + 1,
              };
            }),
        };
      });
    data.reverse();

    setData(data);
  }, [
    api.scoreboard,
    api.profileIndexMap,
    api.profiles,
    props.compared,
    displayResolution,
  ]);

  return (
    <div className={props.className}>
      <input
        type="range"
        min="1"
        max={api.scoreboard.getTicksList().length / 100}
        defaultValue="10"
        onChange={(e) => setResolution(parseInt(e.target.value))}
        onMouseUp={() => {
          setDisplayResolution(resolution);
        }}
      />
      <ResponsiveBump
        data={data}
        margin={{ top: 40, right: 100, bottom: 40, left: 60 }}
        colors={{ scheme: "spectral" }}
        pointSize={10}
        activePointSize={16}
        inactivePointSize={0}
        pointColor={{ theme: "background" }}
        pointBorderWidth={3}
        activePointBorderWidth={3}
        pointBorderColor={{ from: "serie.color" }}
        axisTop={null}
        axisRight={null}
        axisBottom={null}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "ranking",
          legendPosition: "middle",
          legendOffset: -40,
        }}
      />
    </div>
  );
};

interface IAvatar extends React.ImgHTMLAttributes<HTMLImageElement> {
  profile: Profile;
}

const UserAvatar = (props: IAvatar) => {
  const classes = useStyles();
  const [loaded, setLoaded] = useState(false);

  const image = new Image();
  image.onload = () => {
    setLoaded(true);
  };
  image.src = `https://cdn.discordapp.com/avatars/${props.profile.getId()}/${props.profile.getAvatar()}.png`;
  return (
    <>
      {loaded ? (
        <img {...props} src={image.src} className={classes.entryAvatar} />
      ) : undefined}
    </>
  );
};

interface IProfileList {
  compared: Array<string>;
  setCompared: React.Dispatch<React.SetStateAction<string[]>>;
}

const ProfileList = ({ compared, setCompared }: IProfileList) => {
  const classes = useStyles();
  const [profiles, setProfiles] = useState<Profile[]>([]);

  const [filter, setFilter] = useState("");
  const [profilesFiltered, setProfilesFiltered] = useState<Profile[]>([]);
  const [profilesSorted, setProfilesSorted] =
    useState<Profile[]>(profilesFiltered);
  const [sortBy, setSortBy] = useState("score");
  const [sortDirection, setSortDirection] = useState(SortDirection.DESC);
  const [profilesSortedScore, setProfilesSortedScore] = useState<Profile[]>([]);

  const [lastSortKey, setLastSortKey] = useState(sortBy);
  const [lastSortDirection, setLastSortDirection] = useState(sortDirection);
  const api = useContext(APIState);

  useEffect(() => {
    setProfilesFiltered(
      profiles.filter((x) =>
        x.getName().toLocaleLowerCase().includes(filter.toLocaleLowerCase())
      )
    );
  }, [profiles, filter]);

  useEffect(() => {
    setProfiles(Array.from(api.profiles.values()));
  }, [api.profiles]);

  useEffect(() => {
    sort({ sortBy, sortDirection });
  }, [
    profilesFiltered,
    sortBy,
    sortDirection,
    api.scoreboard,
    profilesSortedScore,
  ]);

  useEffect(() => {
    setProfilesSortedScore(
      [...profilesFiltered].sort(
        (a, b) => getScore(a.getId()) - getScore(b.getId())
      )
    );
  }, [profilesFiltered, api.scoreboard]);

  const sort = ({ sortBy, sortDirection }) => {
    setProfilesSorted(sortList({ sortBy, sortDirection }));
  };

  const sortList = ({ sortBy, sortDirection }) => {
    let sorted = [...profilesFiltered];
    switch (sortBy) {
      case "score":
        sorted = [...profilesSortedScore];
        break;
      case "name":
        sorted.sort((a, b) => a.getName().localeCompare(b.getName()));
        break;
      default:
        let task = api.tasks.find((task) => task.getName() === sortBy);
        if (task) {
          let solvedByList = api.state
            .getTaskStatesMap()
            .get(sortBy)
            ?.getSolvedByList();

          sorted.sort(
            (a, b) =>
              (solvedByList.includes(a.getId()) ? 1 : 0) -
              (solvedByList.includes(b.getId()) ? 1 : 0)
          );
        }
    }

    if (sortDirection === SortDirection.DESC) {
      sorted.reverse();
    }

    setSortBy(sortBy);
    setSortDirection(sortDirection);

    return sorted;
  };

  const clear = () => {
    setFilter("");
    setCompared([]);
  };

  const getScore = (id: string) => {
    if (api.scoreboard.getTicksList().length === 0) {
      return 0;
    }

    return api.scoreboard
      .getTicksList()
      [api.scoreboard.getTicksList().length - 1].getPointsList()[
      api.profileIndexMap.get(id)
    ];
  };

  const addTop10 = () => {
    let top10 = api.scoreboard
      .getTicksList()
      [api.scoreboard.getTicksList().length - 1].getPointsList()
      .map((v, idx) => ({ v: v, idx: idx }))
      .sort((a, b) => b.v - a.v)
      .slice(0, 10)
      .map((x) => api.scoreboard.getTeamidsList()[x.idx]);

    // @ts-ignore
    setCompared((compared) => [...new Set<string>([...compared, ...top10])]);
  };

  const platformCountry = ({
    cellData,
    columnData,
    columnIndex,
    dataKey,
    isScrolling,
    rowData,
    rowIndex,
  }) => {
    let identifier = cellData.toLowerCase();
    if (identifier.length != 2) {
      return "";
    }

    return (
      String.fromCodePoint(0x1f1e6 + identifier.charCodeAt(0) - 97) +
      String.fromCodePoint(0x1f1e6 + identifier.charCodeAt(1) - 97)
    );
  };

  const platformRenderer = ({
    cellData,
    columnData,
    columnIndex,
    dataKey,
    isScrolling,
    rowData,
    rowIndex,
  }) => {
    switch (cellData) {
      case "admin":
        return <GiCrownedSkull />;
      case "junior":
        return <FaBaby />;
      case "senior":
        return <FaUserGraduate />;
      case "earth":
        return <IoEarth />;
    }
  };

  const avatarRenderer = ({
    cellData,
    columnData,
    columnIndex,
    dataKey,
    isScrolling,
    rowData,
    rowIndex,
  }) => {
    if (cellData !== "") {
      return (
        <UserAvatar width={50} height={50} profile={profilesSorted[rowIndex]} />
      );
    }

    return undefined;
  };

  const comparedRenderer = ({
    cellData,
    columnData,
    columnIndex,
    dataKey,
    isScrolling,
    rowData,
    rowIndex,
  }) => {
    if (compared.includes(cellData)) {
      return (
        <IoIosRemoveCircleOutline
          onClick={() => {
            setCompared((list) => list.filter((p) => p !== cellData));
          }}
          className={classes.entryRemove}
        />
      );
    }

    return (
      <IoIosAddCircleOutline
        onClick={() => {
          setCompared((list) => [...list, cellData]);
        }}
        className={classes.entryAdd}
      />
    );
  };

  const flagRenderer = ({
    cellData,
    columnData,
    columnIndex,
    dataKey,
    isScrolling,
    rowData,
    rowIndex,
  }) => {
    if (cellData == 1) {
      return <IoFlag />;
    }
    if (cellData == 2) {
      return <IoDocumentTextSharp />;
    }
    return <IoFlagOutline />;
  };

  const CustomSortIndicator = ({
    up,
    show,
    playAnimation,
  }: {
    up: boolean;
    show: boolean;
    playAnimation: boolean;
  }) => {
    return (
      <>
        <IoIosArrowUp
          style={{
            opacity: show ? 1 : 0,
            transform: !playAnimation
              ? up
                ? "rotate(180deg)"
                : "rotate(0deg)"
              : undefined,
          }}
          className={clsx(
            classes.customSortIndicator,
            playAnimation
              ? up
                ? classes.customSortIndicatorUp
                : classes.customSortIndicatorDown
              : undefined
          )}
        />
      </>
    );
  };

  const scoreRenderer = ({
    cellData,
    columnData,
    columnIndex,
    dataKey,
    isScrolling,
    rowData,
    rowIndex,
  }) => {
    return String(cellData);
  };

  const customHeaderRenderer = ({ dataKey, label, sortBy, sortDirection }) => {
    return (
      <>
        <div className={classes.customHeader}>{label}</div>
        <CustomSortIndicator
          show={sortBy === dataKey}
          playAnimation={dataKey === sortBy}
          up={sortDirection === "DESC"}
        />
      </>
    );
  };

  const diagonalHeaderRenderer = ({
    dataKey,
    label,
    sortBy,
    sortDirection,
  }) => {
    return (
      <>
        <div className={classes.diagonalHeader}>{label}</div>
        <CustomSortIndicator
          show={sortBy === dataKey}
          up={sortDirection === "DESC"}
          playAnimation={dataKey === sortBy}
        />
      </>
    );
  };

  return (
    <>
      <div style={{ zIndex: 100 }}>
        <input
          value={filter}
          placeholder="filter username"
          onChange={(e) => {
            setFilter(e.target.value);
          }}
        />
        <button onClick={clear}>clear</button>
        <button onClick={addTop10}>add top 10</button>
      </div>
      {profilesSorted.length > 0 ? (
        <div style={{ flex: "1 1 auto" }}>
          <AutoSizer>
            {({ height, width }) => {
              return (
                <Table
                  width={width}
                  height={height}
                  headerHeight={225}
                  headerStyle={{
                    alignSelf: "flex-end",
                  }}
                  rowGetter={({ index }) => profilesSorted[index].toObject()}
                  rowHeight={60}
                  rowCount={profilesSorted.length}
                  rowStyle={{
                    paddingBottom: "10px",
                  }}
                  sort={sort}
                  sortBy={sortBy}
                  sortDirection={sortDirection}
                  overscanRowCount={3}
                >
                  <Column
                    label=""
                    dataKey="rank"
                    cellDataGetter={({ dataKey, rowData }) =>
                      profilesSortedScore.length -
                      profilesSortedScore.findIndex(
                        (p) => p.getId() === rowData["id"]
                      )
                    }
                    width={100}
                    flexShrink={0}
                  />
                  <Column
                    label=""
                    dataKey="avatar"
                    width={50}
                    flexShrink={0}
                    cellRenderer={avatarRenderer}
                    disableSort
                  />
                  <Column
                    label="Name"
                    dataKey="name"
                    headerRenderer={customHeaderRenderer}
                    width={500}
                  />
                  <Column
                    label="Country"
                    dataKey="country"
                    width={100}
                    cellRenderer={platformCountry}
                    headerRenderer={diagonalHeaderRenderer}
                    disableSort
                  />
                  <Column
                    label="Platform"
                    dataKey="platform"
                    cellDataGetter={({ dataKey, rowData }) =>
                      rowData["admin"] ? "admin" : rowData["platform"]
                    }
                    width={100}
                    cellRenderer={platformRenderer}
                    headerRenderer={diagonalHeaderRenderer}
                    disableSort
                  />
                  <Column
                    label="Score"
                    dataKey="score"
                    width={150}
                    cellDataGetter={({ dataKey, rowData }) =>
                      getScore(rowData["id"])
                    }
                    cellRenderer={scoreRenderer}
                    headerRenderer={diagonalHeaderRenderer}
                    defaultSortDirection={SortDirection.DESC}
                  />
                  {api.tasks
                    .sort((a, b) => {
                      let lengthDiff =
                        b.getDisplayname().length - a.getDisplayname().length;
                      if (lengthDiff === 0) {
                        return a
                          .getDisplayname()
                          .localeCompare(b.getDisplayname());
                      }
                      return lengthDiff;
                    })
                    .map((task) => (
                      <Column
                        key={task.getName()}
                        label={task.getDisplayname()}
                        dataKey={task.getName()}
                        defaultSortDirection={SortDirection.DESC}
                        cellDataGetter={({ dataKey, rowData }) =>
                          api.state
                            .getTaskStatesMap()
                            .get(dataKey)
                            ?.getSolvedByList()
                            .includes(rowData["id"])
                            ? api.state
                                .getTaskStatesMap()
                                .get(dataKey)
                                ?.getWriteupByList()
                                .includes(rowData["id"])
                              ? 2
                              : 1
                            : 0
                        }
                        width={100}
                        cellRenderer={flagRenderer}
                        headerRenderer={diagonalHeaderRenderer}
                      />
                    ))}
                  <Column
                    label=""
                    dataKey="id"
                    disableSort
                    width={50}
                    flexShrink={0}
                    cellRenderer={comparedRenderer}
                  />
                </Table>
              );
            }}
          </AutoSizer>
        </div>
      ) : (
        <div className={classes.emptyMessage}>
          <b> No user matches this filter </b>
        </div>
      )}
    </>
  );
};

export const Scoreboard = () => {
  const classes = useStyles();
  const [compared, setCompared] = useState<Array<string>>(new Array());
  const [showLine, setShowLine] = useState(false);

  return (
    <div className={classes.root}>
      {compared.length > 0 ? (
        showLine ? (
          <GraphLine className={classes.graph} compared={compared} />
        ) : (
          <GraphBump className={classes.graph} compared={compared} />
        )
      ) : (
        <div className={classes.emptyMessage}>
          <b> Add users to compare them in nice graphs </b>
        </div>
      )}
      <ProfileList compared={compared} setCompared={setCompared} />

      <Toggle onToggle={(toggled) => setShowLine(!toggled)} />
    </div>
  );
};
