import {
  Profile,
  ScoreboardBatch,
  ScoreboardSnapshot,
  State,
  Task,
  TaskState,
  ValidFlagSubmission,
} from "../API/platform_pb";
import * as React from "react";
import { useContext, useEffect, useState } from "react";
import {
  APIClient as APIClientEarth,
  APIState as APIStateEarth,
} from "State/APIEarth";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";

interface IAPIClient {
  children: React.ReactNode;
}

const APIState = React.createContext<{
  tasks: Array<Task>;
  state: State;
  profiles: Map<string, Profile>;
  submissions: Array<ValidFlagSubmission>;
  scoreboard: ScoreboardBatch | null;
  profileIndexMap: Map<string, number>;
}>(null);

const InternalAPIClient: React.FC<IAPIClient> = (props) => {
  const apiEarth = useContext(APIStateEarth);

  const [submissions, setSubmissions] = useState<Array<ValidFlagSubmission>>(
    []
  );
  const [profiles, setProfiles] = useState<Map<string, Profile>>(new Map());
  const [tasks, setTasks] = useState<Array<Task>>([]);
  const [scoreboard, setScoreboard] = useState<ScoreboardBatch>(
    new ScoreboardBatch()
  );
  const [state, setState] = useState<State>(new State());
  const [profileIndexMap, setProfileIndexMap] = useState<Map<string, number>>(
    new Map()
  );

  useEffect(() => {
    let timer = setTimeout(() => {
      console.log("SUB");
      setSubmissions(
        [...apiEarth.submissions].sort(
          (a, b) =>
            a.getTimestamp().toDate().getTime() -
            b.getTimestamp().toDate().getTime()
        )
      );
    }, 250);

    return () => {
      clearTimeout(timer);
    };
  }, [apiEarth.submissions]);

  useEffect(() => {
    setProfiles(
      new Map([
        ...Array.from(apiEarth.profiles.entries()).map<[string, Profile]>(
          ([key, p]) => {
            return [key, p];
          }
        ),
      ])
    );
  }, [apiEarth.profiles]);

  useEffect(() => {
    if (apiEarth.tasks.length > 0) {
      console.log("TAS");

      setTasks(apiEarth.tasks);
    }
  }, [apiEarth.tasks]);

  const calcPoints = (solves: number) => {
    let div = 1 + Math.pow(Math.max(0, solves - 1) / 11.92201, 1.206069);
    let points = Math.round(50 + (500 - 50) / div);
    return points;
  };

  useEffect(() => {
    if (apiEarth.state) {
      //console.log("STA")
      let state = new State();

      state.setHeight(
        Timestamp.fromDate(
          new Date(Math.max(apiEarth.state.getHeight().toDate().getTime()))
        )
      );

      state.setStandingsList([...apiEarth.state.getStandingsList()]);

      apiEarth.state.getTaskStatesMap().forEach((taskStateEarth, key) => {
        let taskState = new TaskState();
        taskState.setSolvedByList([...taskStateEarth.getSolvedByList()]);

        taskState.setWriteupByList([...taskStateEarth.getWriteupByList()]);

        taskState.setPoints(calcPoints(taskState.getSolvedByList().length));

        state.getTaskStatesMap().set(key, taskState);
      });

      state.setContest(apiEarth.state.getContest());

      setState(state);
    }
  }, [apiEarth.state]);

  useEffect(() => {
    if (profiles.size === 0 || tasks.length === 0 || submissions.length === 0) {
      return;
    }

    let scoreboard = new ScoreboardBatch();

    let userIndexMap = new Map<string, number>();
    let count = 0;
    profiles.forEach((_, key) => {
      scoreboard.addTeamids(key);
      userIndexMap.set(key, count);
      count++;
    });
    setProfileIndexMap(userIndexMap);

    let taskUserMap = new Map<string, Array<string>>();
    tasks.forEach((task) => {
      taskUserMap.set(task.getName(), []);
    });

    submissions.forEach((submission, index) => {
      let lastSnapshot: ScoreboardSnapshot;
      if (index > 0) {
        lastSnapshot = scoreboard.getTicksList()[index - 1];
      } else {
        console.log("Init");
        lastSnapshot = new ScoreboardSnapshot();

        let points = new Array(profiles.size);
        points.fill(0);
        lastSnapshot.setPointsList(points);
      }

      let snapshot = new ScoreboardSnapshot();
      snapshot.setPointsList([...lastSnapshot.getPointsList()]);

      snapshot.setHeight(submission.getTimestamp());

      let taskSolves = taskUserMap.get(submission.getTask());
      taskSolves.push(submission.getTeamId());
      let solvesCount = taskUserMap.get(submission.getTask()).length;

      let oldPoints = 0;
      if (index > 0) {
        oldPoints = calcPoints(solvesCount - 1);

        snapshot.getPointsList()[userIndexMap.get(submission.getTeamId())] +=
          oldPoints;
      }

      let newPoints = calcPoints(solvesCount);
      let pointDiff = newPoints - oldPoints;

      taskSolves.forEach((user) => {
        let userIndex = userIndexMap.get(user);

        let points = snapshot.getPointsList();
        points[userIndex] += pointDiff;
      });

      scoreboard.addTicks(snapshot);
    });

    setScoreboard(scoreboard);
  }, [profiles, tasks, submissions]);

  return (
    <React.Fragment>
      <APIState.Provider
        value={{
          tasks: tasks,
          state: state,
          profiles: profiles,
          submissions: submissions,
          scoreboard: scoreboard,
          profileIndexMap: profileIndexMap,
        }}
      >
        {props.children}
      </APIState.Provider>
    </React.Fragment>
  );
};

const APIClient: React.FC<IAPIClient> = (props) => {
  return (
    <APIClientEarth>
      <InternalAPIClient>{props.children}</InternalAPIClient>
    </APIClientEarth>
  );
};

export { APIClient, APIState };
