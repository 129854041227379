import React from "react";
import "./App.css";
import { APIClient } from "State/API";
import { Dashboard } from "./components/Dashboard";

function App() {
  return (
    <APIClient>
      <div className="App">
        <Dashboard />
      </div>
    </APIClient>
  );
}

export default App;
