import {ResponsiveTreeMap} from '@nivo/treemap'
import React, {useContext, useEffect, useState} from 'react';
import {APIState} from "../State/API";
import {Task} from "../API/platform_pb";

interface TreeMapEntry {
    id: string,
    color?: string,
    children?: TreeMapEntry[],
    value?: number,
}

export const Tasks = () => {
    const [data, setData] = useState<TreeMapEntry>()
    const api = useContext(APIState)

    const calcPoints = (solves: number) => {
        let div = 1 + Math.pow(Math.max(0, solves - 1) / 11.92201, 1.206069)
        let points = Math.round(50 + ((500 - 50) / div))
        return points
    }


    useEffect(() => {
        if(!(api.tasks && api.state)){
            return
        }

        if (api.tasks.length === 0) {
            return;
        }

        const categoriesMap = new Map<string, Task[]>()
        const categories: TreeMapEntry[] = []
        api.tasks.forEach(task => {
            task.getCategoriesList().forEach(c => {
                if (!categoriesMap.has(c)) {
                    categoriesMap.set(c, [])
                }
                categoriesMap.get(c).push(task)
            })
            
        })

        categoriesMap.forEach((v, k) => {
            categories.push({
                id: k,
                children: v.map(t => {
                    return {
                        id: t.getName(),
                        value: calcPoints(api.state.getTaskStatesMap().get(t.getName())?.getSolvedByList().length) / t.getCategoriesList().length
                    }
                })
            })
        })

        setData({
            id: "tasks",
            children: categories
        })
    }, [api.state, api.tasks])

    return <div style={{
        backgroundColor: 'white',
        height: '100%',
        width: '100%',
    }}>
        <ResponsiveTreeMap
            data={data?data:{id: "tasks", value: 0}}
            label={"id"}
            margin={{top: 10, right: 10, bottom: 10, left: 10}}
            labelSkipSize={12}
            labelTextColor={{from: 'color', modifiers: [['darker', 1.2]]}}
            parentLabelTextColor={{from: 'color', modifiers: [['darker', 2]]}}
            borderColor={{from: 'color', modifiers: [['darker', 0.1]]}}
        />
    </div>


}